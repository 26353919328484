import School from 'models/school'
import { differenceInMonths } from 'date-fns'
import { ISchoolsData, ISchoolItem } from 'interfaces/school'
import Api from 'api'
const resource = '/schools'

export default class SchoolService {
  static async getSchools(page: number, size: number, search: string): Promise<ISchoolsData> {
    let url = `${resource}?page=${page}&page_size=${size}`
    if (search.trim().length > 0) url += '&search=' + search
    const { data } = await Api.get(url)
    const schools = data.schools.map((item: ISchoolItem) => {
      let color = 'white'
      if (item.lastVisitUpdatedAt) {
        const months = differenceInMonths(new Date(), new Date(item.lastVisitUpdatedAt))
        if (months >= 36) color = 'light'
        if (months >= 24 && months < 36) color = 'danger'
        if (months >= 12 && months < 24) color = 'warning'
        if (months < 12) color = 'success'
      }
      item['color'] = color
      item.educationLevel = item.educationLevel ? item.educationLevel : ''
      return item
    })
    return { schools, pagination: data.pagination }
  }

  static async query(query: string): Promise<Array<ISchoolItem>> {
    const { data } = await Api.post(`${resource}/query`, { query })
    return data.schools
  }

  static async queryCount(query: string): Promise<number> {
    const { data } = await Api.post(`${resource}/query/count`, { query })
    return data.totalSchools
  }

  static async getSchool(schoolId: number): Promise<School> {
    const { data } = await Api.get(`${resource}/${schoolId}`)
    return new School(data.school)
  }
}
