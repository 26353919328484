import React, { useState, useEffect, ReactElement, useCallback } from 'react'
import { Row, Col, Card, Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faTrash, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'components/dropzone/dropzone'
import SimpleCard from 'components/cards/simple_card'
import SchoolVisitImage from 'models/school_visit_image'
import { ISchoolVisitProperties } from 'interfaces/school_visit'
import ImagesService from 'services/image_service'
import ImageViewer from 'react-simple-image-viewer'
import SwAlert from 'components/alert/swal'
import { toast } from 'react-toastify'
interface PhotoImageProps {
  schoolVisitImages: Array<SchoolVisitImage>
  edit: boolean
  onUpdate: (data: Array<SchoolVisitImage>) => void
  schoolVisitId: number
}
const PhotoImage = ({ schoolVisitImages, edit, onUpdate, schoolVisitId }: PhotoImageProps): ReactElement => {
  const [images, setImages] = useState(new Array<SchoolVisitImage>(0))
  const [indexDescription, setIndexDescription] = useState(-1)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const photos = schoolVisitImages.filter((image: SchoolVisitImage) => image.image_type == 1)
    photos.sort((a, b) => a.id - b.id)
    setImages(photos)
  }, [schoolVisitImages])

  const rotateImage = (key: number) => {
    //todo rotate image
    const image = images[key]

    const url = ImagesService.getUrl(image.image_url)

    toast.info('Rotando imagen, espere')

    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          }),
      )
      .then(async base64 => {
        const rotated = await rotateBase64Image90deg(base64 as string, true)

        const imageUpdated = await ImagesService.update(image.id, {
          base64: rotated,
        })

        images[key].image_url = image.image_url = ''
        setImages([...images])
        images[key].image_url = imageUpdated.image_url
        setImages([...images])

        toast.success('Imagen rotada')
      })
      .catch(e => {
        console.log('error', e)
        toast.error('Error!')
      })
  }

  function rotateBase64Image90deg(base64Image: string, isClockwise: boolean) {
    // create an off-screen canvas
    const offScreenCanvas = document.createElement('canvas') as HTMLCanvasElement
    const offScreenCanvasCtx = offScreenCanvas.getContext('2d')

    return new Promise((res, rej) => {
      const img = new Image()
      img.src = base64Image

      img.onload = () => {
        // set its dimension to rotated size
        offScreenCanvas.height = img.width
        offScreenCanvas.width = img.height

        // rotate and draw source image into the off-screen canvas:
        if (!offScreenCanvasCtx) return

        if (isClockwise) {
          offScreenCanvasCtx.rotate((90 * Math.PI) / 180)
          offScreenCanvasCtx.translate(0, -offScreenCanvas.width)
        } else {
          offScreenCanvasCtx.rotate((-90 * Math.PI) / 180)
          offScreenCanvasCtx.translate(-offScreenCanvas.height, 0)
        }
        offScreenCanvasCtx.drawImage(img, 0, 0)

        // encode image to data-uri with base64
        res(offScreenCanvas.toDataURL('image/jpeg', 100))
      }
    })
  }

  const handleLoad = async (fileName: string, base64: string) => {
    const image = new SchoolVisitImage({
      image_type: 1,
      image_url: base64,
      description: '',
    })

    try {
      const imageNew = await ImagesService.create({
        ...image,
        lat: '',
        lng: '',
        isMicrolocation: '',
        isMacrolocation: '',
        index: schoolVisitImages.length,
        schoolVisitId,
      })

      setImages([...images, imageNew])
      onUpdate([...schoolVisitImages, imageNew])
      SwAlert.fire('Operación exitosa!', 'Imagen agregada', 'success')
    } catch (e) {
      console.log(e)
    }
  }

  const handleDelete = async (index: number) => {
    const image = images[index]

    await ImagesService.remove(image.id)

    SwAlert.fire('Operación exitosa!', 'Imagen eliminada', 'success')
    const tmp = [...images]
    tmp.splice(index, 1)

    setImages(tmp)

    const indexImages = schoolVisitImages.findIndex(el => el.id == image.id)
    const tmpSchoolVisitImages = [...schoolVisitImages]
    tmpSchoolVisitImages.splice(indexImages, 1)

    onUpdate(tmpSchoolVisitImages)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const openImageViewer = useCallback(index => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  return (
    <div>
      <div className='card'>
        <div className='card-header bg-white'>
          <h4 className='card-title'>REPORTE FOTOGRÁFICO</h4>
        </div>
      </div>
      <Row>
        {edit && (
          <Col sm={12}>
            <SimpleCard>
              <Dropzone onLoad={handleLoad} />
            </SimpleCard>
          </Col>
        )}
        <Col sm={12}>
          <SimpleCard title='Imágenes'>
            <Row>
              {images.map((image, key) => {
                return (
                  <Col key={`${key}image${image.image_url}`} sm={3}>
                    <Card body={false}>
                      <div className='tab-wid'>
                        <Card.Img
                          variant='top'
                          className='img-fluid'
                          src={ImagesService.getUrl(image.image_url)}
                          style={{ cursor: 'pointer' }}
                          onClick={() => openImageViewer(key)}
                        />
                      </div>
                      <Card.Footer>
                        <h4 className='card-title'>
                          Descripción
                          {edit && (
                            <div className='float-right'>
                              {indexDescription === -1 && (
                                <>
                                  <Button variant='outline-info' size='sm' onClick={() => rotateImage(key)}>
                                    <FontAwesomeIcon icon={faUndo} />
                                  </Button>
                                  <Button
                                    variant='outline-info'
                                    size='sm'
                                    onClick={() => setIndexDescription(key)}
                                    className={'ml-2'}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                  <Button
                                    variant='danger'
                                    size='sm'
                                    onClick={() => handleDelete(key)}
                                    className={'ml-2'}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        </h4>
                        {indexDescription === key ? (
                          <div>
                            <ImageForm
                              image={image}
                              onsubmit={async imageUpdate => {
                                images[key].description = imageUpdate.description
                                images[key].lat = imageUpdate.lat
                                images[key].lng = imageUpdate.lng

                                await ImagesService.update(images[key].id, {
                                  ...image,
                                  description: imageUpdate.description,
                                  lat: imageUpdate.lat,
                                  lng: imageUpdate.lng,
                                })

                                SwAlert.fire('Operación exitosa!', 'Imagen actualizada', 'success')

                                setIndexDescription(-1)
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <p className='card-text'>{image.description}</p>
                            <p className='card-text'>Lat: {image.lat}</p>
                            <p className='card-text'>Lng: {image.lng}</p>
                          </div>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </SimpleCard>
        </Col>
      </Row>
      {isViewerOpen && (
        <ImageViewer
          src={images.map(img => ImagesService.getUrl(img.image_url))}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  )
}

export default PhotoImage

function ImageForm({ image, onsubmit }: { image: SchoolVisitImage; onsubmit: (image: SchoolVisitImage) => void }) {
  const [description, setDescription] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')

  useEffect(() => {
    setDescription(image.description)
    setLat(image.lat)
    setLng(image.lng)
  }, [image])

  return (
    <div>
      <Form.Group className='mb-3'>
        <Form.Label>Descripción</Form.Label>
        <input
          type='text'
          className='form-control'
          defaultValue={description}
          onChange={e => {
            setDescription(e.target.value)
          }}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Latitud</Form.Label>
        <input
          type='text'
          className='form-control'
          defaultValue={lat}
          onChange={e => {
            setLat(e.target.value)
          }}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Longitud</Form.Label>
        <input
          type='text'
          className='form-control'
          defaultValue={lng}
          onChange={e => {
            setLng(e.target.value)
          }}
        />
      </Form.Group>

      <div>
        <Button
          onClick={() =>
            onsubmit(
              new SchoolVisitImage({
                description,
                lat,
                lng,
              }),
            )
          }
        >
          <i>
            <FontAwesomeIcon icon={faCheck} />
          </i>
        </Button>
      </div>
    </div>
  )
}
