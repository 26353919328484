import React, { useState, useEffect, ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import SimpleCard from 'components/cards/simple_card'
import { IExteriorWork } from 'interfaces/exterior_work'
import { ISchoolService } from 'interfaces/school_services'
import { IShift } from 'interfaces/shift'
import { ITerrainDescriptionProperties } from 'interfaces/terrain_description'
import { ISoilType } from 'interfaces/soil_type'
import { ISchoolVisitProperties } from 'interfaces/school_visit'
import { IVisitService } from 'interfaces/visit_service'
import PropertyTypeService from 'services/property_type_service'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { ErrorMessage } from '@hookform/error-message'
import Error from 'components/error'
import OptionsService from 'services/options_service'
import { Select2, Select2Option } from 'select2-react-component'
import { ICommunicationChannels } from 'interfaces/communication_channels'

const degreeSchema = Joi.array().items(
  Joi.object({
    id: Joi.number().integer().min(0).max(999999999).optional(),
    teachers: Joi.number().integer().min(0).max(10000).required().messages({
      'number.base': 'Debe ser un número',
      'number.min': 'No puede ser menor a 0',
      'number.max': 'No puede ser mayor a 10000',
      'number.integer': 'Debe ser un número entero',
    }),
    students: Joi.number().integer().min(0).max(10000).required().messages({
      'number.base': 'Debe ser un número',
      'number.min': `No puede ser menor a 0`,
      'number.max': `No puede ser mayor a 10000`,
      'number.integer': 'Debe ser un número entero',
    }),
    groups: Joi.number().integer().min(0).max(10000).required().messages({
      'number.base': 'Debe ser un número',
      'number.min': `No puede ser menor a 0`,
      'number.max': `No puede ser mayor a 10000`,
      'number.integer': 'Debe ser un número entero',
    }),
  }),
)

const schoolShiftSchema = Joi.array().items(
  Joi.object({
    id: Joi.number().integer().min(0).max(999999999).optional(),
    directorHasGroup: Joi.boolean(),
    director: Joi.number().integer().min(0).max(9999).messages({
      'number.base': 'Debe ser un número',
      'number.min': 'No puede ser menor a 0',
      'number.max': 'No puede ser mayor a 9999',
      'number.integer': 'Debe ser un número entero',
    }),
    administrative: Joi.number().integer().min(0).max(9999).messages({
      'number.base': 'Debe ser un número',
      'number.min': 'No puede ser menor a 0',
      'number.max': 'No puede ser mayor a 9999',
      'number.integer': 'Debe ser un número entero',
    }),
    totalTeachers: Joi.number().integer().min(0).max(9999).messages({
      'number.base': 'Debe ser un número',
      'number.min': 'No puede ser menor a 0',
      'number.max': 'No puede ser mayor a 9999',
      'number.integer': 'Debe ser un número entero',
    }),
    totalStudents: Joi.number().integer().min(0).max(9999).messages({
      'number.base': 'Debe ser un número',
      'number.min': 'No puede ser menor a 0',
      'number.max': 'No puede ser mayor a 9999',
      'number.integer': 'Debe ser un número entero',
    }),
    totalGroups: Joi.number().integer().min(0).max(9999).messages({
      'number.base': 'Debe ser un número',
      'number.min': 'No puede ser menor a 0',
      'number.max': 'No puede ser mayor a 9999',
      'number.integer': 'Debe ser un número entero',
    }),
    degrees: degreeSchema,
  }),
)

const terrainDescriptionSchema = Joi.object({
  id: Joi.number().integer().min(0).max(999999999).optional(),
  area: Joi.number().min(0).max(999999).required().messages({
    'any.required': 'No puede estar vacío',
    'number.base': 'Debe ser un número',
    'number.min': 'No puede ser menor a 0',
    'number.max': 'No puede ser mayor a 999999',
  }),
  donation_certificate: Joi.boolean().optional(),
  lat: Joi.number().empty('').optional().messages({
    'number.base': 'Debe ser un número',
  }),
  lng: Joi.number().empty('').optional().messages({
    'number.base': 'Debe ser un número',
  }),
  notes: Joi.string().empty('').max(1000).optional().messages({
    'string.max': 'No puede tener más de 1000 caracteres',
  }),
  observations: Joi.string().empty('').max(1000).optional().messages({
    'string.max': 'No puede tener más de 1000 caracteres',
  }),
  propertyType: Joi.object({
    id: Joi.number().integer().min(0).max(10).optional(),
    name: Joi.string().max(100).optional(),
  }),
  soil_mechanics: Joi.boolean().optional(),
})

const exteriorWorksSchema = Joi.array().items(
  Joi.object({
    id: Joi.number().min(0).optional(),
    notes: Joi.string().empty('').max(1000).optional(),
    quantity: Joi.number().min(0).max(99999).messages({
      'number.base': 'Debe ser un número',
      'number.min': 'No puede ser menor a 0',
      'number.max': 'No puede ser mayor a 99999',
    }),
  }),
)

const schoolServiceSchema = Joi.object({
  drainageNetwork: Joi.boolean().required(),
  drinkingWater: Joi.boolean().required(),
  electricPower: Joi.boolean().required(),
  internet: Joi.boolean().required(),
  others: Joi.string().empty('').max(9999).optional().messages({
    'string.max': 'No puede tener más de 9999 caracteres',
  }),
  telephoneLine: Joi.boolean().required(),
})

const soilTypeSchema = Joi.object({
  claySoil: Joi.boolean().required(),
  rockySoil: Joi.boolean().required(),
  sandySoil: Joi.boolean().required(),
})

const communicationChannelsSchema = Joi.object({
  id: Joi.number().min(0).optional(),
  noMonthsCommunication: Joi.number().min(0).max(156).optional().messages({
    'number.base': 'Debe ser un número',
    'number.min': 'No puede ser menor a 0',
    'number.max': 'No puede ser mayor a 156',
  }),
  pavement: Joi.string().empty('').max(1000).optional(),
  breach: Joi.string().empty('').max(1000).optional(),
  dirtRoad: Joi.string().empty('').max(1000).optional(),
  bridlePath: Joi.string().empty('').max(1000).optional(),
})

const stadisticControlSchema = Joi.object({
  exteriorWorks: exteriorWorksSchema,
  schoolService: schoolServiceSchema,
  schoolShifts: schoolShiftSchema,
  soilType: soilTypeSchema,
  communicationChannels: communicationChannelsSchema,
  terrainDescription: terrainDescriptionSchema,
  existingTechnologies: Joi.string().empty('').max(9999).optional().messages({
    'string.max': 'No puede tener más de 9999 caracteres',
  }),
})

interface EstadisticControlValues {
  schoolShifts: Array<IShift>
  terrainDescription: ITerrainDescriptionProperties
  exteriorWorks: Array<IExteriorWork>
  schoolService: ISchoolService
  soilType: ISoilType
  existingTechnologies: string
  communicationChannels: ICommunicationChannels
}

interface EstadisticControlProps extends EstadisticControlValues {
  edit: boolean
  onUpdate: (data: ISchoolVisitProperties) => void
}

const EstadisticControl = ({
  existingTechnologies,
  schoolShifts,
  terrainDescription,
  exteriorWorks,
  schoolService,
  soilType,
  communicationChannels,
  edit,
  onUpdate,
}: EstadisticControlProps): ReactElement => {
  const [storedTechnologies, setStoredTechnologies] = useState<Array<Select2Option>>([])
  const [technologiesOption, setTechnologiesOption] = useState<Array<Select2Option>>([])
  const [technologies, setTechnologies] = useState<Array<string>>(
    existingTechnologies ? existingTechnologies.split(/[,|Y|y|\n]+/) : [],
  )
  const [searchTechnologie, setSearchTechnologie] = useState<string>('')
  const shiftTypes = ['MATUTINO', 'VESPERTINO']
  const [propertyTypes, setPropertyTypes] = useState([{ value: 1, label: 'kahska' }])
  const [propertyType, setPropertyType] = useState<number | undefined>(undefined)
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(stadisticControlSchema),
  })

  useEffect(() => {
    const getPropertyTypes = async () => {
      try {
        const { propertyTypes } = await PropertyTypeService.getAll()
        setPropertyTypes(propertyTypes)
      } catch (e) {
        console.log(e)
      }
    }
    getPropertyTypes()
    if (terrainDescription.propertyType) setPropertyType(terrainDescription.propertyType.id)

    getTechnologies()
  }, [])

  useEffect(() => {
    if (technologies) {
      const technos = technologies.join(' , ')
      setValue('existingTechnologies', technos)
    }
  }, [technologies])

  const getTechnologies = async () => {
    const technologies = await OptionsService.technologies()
    const technologiesSelect = technologies.map(el => {
      return { value: el, label: el }
    })
    setTechnologiesOption(technologiesSelect)
    setStoredTechnologies(technologiesSelect)
  }

  const onFormSubmit = (values: EstadisticControlValues) => {
    const newPropertyType = propertyTypes.find(item => item.value === propertyType)
    if (newPropertyType)
      values.terrainDescription.propertyType = { id: newPropertyType.value, name: newPropertyType.label }
    else values.terrainDescription.propertyType = null
    onUpdate(values)
  }

  const Services = () => {
    return (
      <SimpleCard title='SERVICIOS'>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='drainage_network'
                {...register(`schoolService.drainageNetwork`)}
                label='Red de drenaje a colector municipal'
                defaultChecked={schoolService.drainageNetwork}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='telephone_line'
                {...register(`schoolService.telephoneLine`)}
                label='Línea Telefonica'
                defaultChecked={schoolService.telephoneLine}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='drinking_water'
                {...register(`schoolService.drinkingWater`)}
                label='Agua Potable'
                defaultChecked={schoolService.drinkingWater}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='electric_power'
                {...register(`schoolService.electricPower`)}
                label='Energía Electrica'
                defaultChecked={schoolService.electricPower}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='internet'
                {...register(`schoolService.internet`)}
                label='Internet'
                defaultChecked={schoolService.internet}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Otros:</Form.Label>
              <Form.Control
                as='textarea'
                rows={1}
                defaultValue={schoolService.others}
                {...register(`schoolService.others`)}
              />
              <ErrorMessage
                errors={errors}
                name='schoolService.others'
                render={({ message }) => <Error message={message} />}
              />
            </Form.Group>
          </Col>
        </Row>
      </SimpleCard>
    )
  }

  const SoilTypes = () => {
    return (
      <SimpleCard title='TIPOS DE SUELOS'>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='rockySoil'
                {...register('soilType.rockySoil')}
                label='Suelos Rocosos'
                defaultChecked={soilType.rockySoil}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='claySoil'
                {...register('soilType.claySoil')}
                label='Suelos Arcillosos'
                defaultChecked={soilType.claySoil}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Check
                type='switch'
                id='sandySoil'
                {...register('soilType.sandySoil')}
                label='Suelos Arenosos'
                defaultChecked={soilType.sandySoil}
              />
            </Form.Group>
          </Col>
        </Row>
      </SimpleCard>
    )
  }

  const TerrainDescription = () => {
    return (
      <SimpleCard title='DESCRIPCIÓN DEL TERRENO'>
        <React.Fragment>
          <Row>
            <Col sm={4}>
              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Area:</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type='number'
                        step='.001'
                        defaultValue={terrainDescription.area}
                        {...register('terrainDescription.area')}
                      />
                      <InputGroup.Prepend>
                        <InputGroup.Text>M2</InputGroup.Text>
                      </InputGroup.Prepend>
                      <ErrorMessage
                        errors={errors}
                        name='terrainDescription.area'
                        render={({ message }) => <Error message={message} />}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <p>Georeferenciación:</p>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Latitud:</Form.Label>
                    <Form.Control
                      type='number'
                      step='0.0000000001'
                      defaultValue={terrainDescription.lat}
                      {...register('terrainDescription.lat')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='terrainDescription.lat'
                      render={({ message }) => <Error message={message} />}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Longitud:</Form.Label>
                    <Form.Control
                      type='number'
                      step='0.0000000001'
                      defaultValue={terrainDescription.lng}
                      {...register('terrainDescription.lng')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='terrainDescription.lng'
                      render={({ message }) => <Error message={message} />}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col sm={8}>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Procedencia:</Form.Label>
                    <Select2 data={propertyTypes} value={propertyType} update={value => setPropertyType(+value)} />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Check
                      type='switch'
                      id='donationCertificate'
                      {...register('terrainDescription.donation_certificate')}
                      label='Acta de Donación'
                      defaultChecked={terrainDescription.donation_certificate}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Check
                      type='switch'
                      id='soilMechanics'
                      {...register('terrainDescription.soil_mechanics')}
                      label='Requiere mecánica de suelos'
                      defaultChecked={terrainDescription.soil_mechanics}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Nota:</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={1}
                      defaultValue={terrainDescription.notes}
                      {...register('terrainDescription.notes')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='terrainDescription.notes'
                      render={({ message }) => <Error message={message} />}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Observaciones:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={1}
                  defaultValue={terrainDescription.observations}
                  {...register('terrainDescription.observations')}
                />
                <ErrorMessage
                  errors={errors}
                  name='terrainDescription.observations'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
          </Row>
        </React.Fragment>
      </SimpleCard>
    )
  }

  const ExteriorWorks = () => {
    return (
      <SimpleCard title='OBRA EXTERIOR EXISTENTE'>
        <div>
          <Row>
            <Col sm={8}></Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Observaciones:</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          {exteriorWorks.map((exteriorWork, index) => {
            return (
              <Row key={index}>
                <Col sm={8}>
                  <Form.Control
                    type='hidden'
                    defaultValue={exteriorWork.id}
                    {...register(`exteriorWorks.${index}.id`)}
                  />
                  <Form.Group className='row'>
                    <Form.Label className='col-sm-6'>{exteriorWork.work.name}</Form.Label>
                    <Col sm={6}>
                      <InputGroup hasValidation>
                        <Form.Control
                          type='number'
                          step='0.001'
                          defaultValue={exteriorWork.quantity}
                          {...register(`exteriorWorks.${index}.quantity`)}
                        />
                        <InputGroup.Prepend>
                          <InputGroup.Text>{exteriorWork.work.base_unit}</InputGroup.Text>
                        </InputGroup.Prepend>
                      </InputGroup>
                      <ErrorMessage
                        errors={errors}
                        name={`exteriorWorks.${index}.quantity`}
                        render={({ message }) => <Error message={message} />}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group>
                    <Select2
                      data={[
                        {
                          label: 'SI',
                          value: 'SI',
                        },
                        {
                          label: 'NO',
                          value: 'NO',
                        },
                      ]}
                      value={getValues(`exteriorWorks.${index}.notes`) || exteriorWorks[index].notes}
                      update={val => {
                        setValue(`exteriorWorks.${index}.notes`, val)
                      }}
                    ></Select2>
                    <ErrorMessage
                      errors={errors}
                      name={`exteriorWorks.${index}.notes`}
                      render={({ message }) => <Error message={message} />}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )
          })}
        </div>
      </SimpleCard>
    )
  }

  const CommunicationChannels = () => {
    return (
      <SimpleCard title='VIAS DE COMUNICACION'>
        <React.Fragment>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={3}>Pavimento</Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.pavement?.split('-')[0]}
                            onChange={e => {
                              let value = getValues('communicationChannels.pavement')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[0] = number
                              setValue('communicationChannels.pavement', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>KM</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.pavement?.split('-')[1]}
                            onChange={e => {
                              let value = getValues('communicationChannels.pavement')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[1] = number
                              setValue('communicationChannels.pavement', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>HR</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.pavement?.split('-')[2]}
                            onChange={e => {
                              let value = getValues('communicationChannels.pavement')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[2] = number
                              setValue('communicationChannels.pavement', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>MIN</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={3}>Brecha</Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.breach?.split('-')[0]}
                            onChange={e => {
                              let value = getValues('communicationChannels.breach')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[0] = number
                              setValue('communicationChannels.breach', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>KM</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.breach?.split('-')[1]}
                            onChange={e => {
                              let value = getValues('communicationChannels.breach')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[1] = number
                              setValue('communicationChannels.breach', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>HR</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.breach?.split('-')[2]}
                            onChange={e => {
                              let value = getValues('communicationChannels.breach')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[2] = number
                              setValue('communicationChannels.breach', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>MIN</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={3}>Terraceria</Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.dirtRoad?.split('-')[0]}
                            onChange={e => {
                              let value = getValues('communicationChannels.dirtRoad')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[0] = number
                              setValue('communicationChannels.dirtRoad', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>KM</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.dirtRoad?.split('-')[1]}
                            onChange={e => {
                              let value = getValues('communicationChannels.dirtRoad')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[1] = number
                              setValue('communicationChannels.dirtRoad', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>HR</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.dirtRoad?.split('-')[2]}
                            onChange={e => {
                              let value = getValues('communicationChannels.dirtRoad')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[2] = number
                              setValue('communicationChannels.dirtRoad', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>MIN</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={3}>Herraduria</Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.bridlePath?.split('-')[0]}
                            onChange={e => {
                              let value = getValues('communicationChannels.bridlePath')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[0] = number
                              setValue('communicationChannels.bridlePath', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>KM</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.bridlePath?.split('-')[1]}
                            onChange={e => {
                              let value = getValues('communicationChannels.bridlePath')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[1] = number
                              setValue('communicationChannels.bridlePath', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>HR</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <InputGroup hasValidation>
                          <Form.Control
                            type='number'
                            step='1'
                            defaultValue={communicationChannels.bridlePath?.split('-')[2]}
                            onChange={e => {
                              let value = getValues('communicationChannels.bridlePath')
                              if (value === undefined) value = '--'
                              const number = parseInt(e.target.value)
                              const parts = value.split('-')
                              parts[2] = number
                              setValue('communicationChannels.bridlePath', parts.join('-'))
                            }}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text>MIN</InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <Form.Group>
                    <Form.Label>Meses sin acceso a la comunidad:</Form.Label>
                    <Form.Control
                      type='number'
                      step='1'
                      defaultValue={communicationChannels.noMonthsCommunication || '0'}
                      {...register('communicationChannels.noMonthsCommunication')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name='communicationChannels.noMonthsCommunication'
                      render={({ message }) => <Error message={message} />}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </React.Fragment>
      </SimpleCard>
    )
  }

  const SaveButton = () => {
    return (
      <Button variant='success' className='btn' type='submit'>
        <i className='mr-3'>
          <FontAwesomeIcon icon={faSave} />
        </i>
        Guardar cambios
      </Button>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='card'>
        <div className='card-header bg-white'>
          <h4 className='card-title'>
            CONTROL ESTADÍSTICO
            <div className='float-right'>{edit && <SaveButton />}</div>
          </h4>
        </div>
      </div>
      <Row>
        <Col sm={8}>
          <div className='card'>
            <div className='card-body'>
              <Form.Group>
                <Form.Label>Tecnologías existentes</Form.Label>
                <div>
                  {technologies.map((technologie, index) => {
                    return (
                      <Button
                        key={technologie}
                        variant='info'
                        className='btn m-1'
                        onClick={() => {
                          technologies.splice(index, 1)
                          setTechnologies([...technologies])
                        }}
                      >
                        {technologie}
                        <i className='ml-3'>
                          <FontAwesomeIcon icon={faTimes} />
                        </i>
                      </Button>
                    )
                  })}
                </div>
                <Select2
                  minCountForSearch={0}
                  search={text => {
                    const tecOps = storedTechnologies.filter(tech =>
                      tech.label.toUpperCase().includes(text.toUpperCase()),
                    )
                    setTechnologiesOption(tecOps)
                    setSearchTechnologie(text.trim())
                  }}
                  keydown={keyEvent => {
                    if (keyEvent.key.toLocaleLowerCase() === 'enter') {
                      const stored = technologies.find(
                        technologie => technologie.trim() == searchTechnologie.toString().trim(),
                      )
                      if (!stored) setTechnologies([...technologies, searchTechnologie])
                    }
                  }}
                  customSearchEnabled={true}
                  data={technologiesOption}
                  value={[]}
                  update={val => {
                    const stored = technologies.find(technologie => technologie.trim() == val.toString().trim())
                    if (!stored) setTechnologies([...technologies, val.toString()])
                  }}
                ></Select2>
                <ErrorMessage
                  errors={errors}
                  name={`existingTechnologies`}
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </div>
          </div>
        </Col>
        <Col sm={8}>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>TURNOS ESCOLARES</h4>
              {schoolShifts
                .sort((a, b) => a.shiftType - b.shiftType)
                .map((shift, key) => {
                  return (
                    <div key={shift.id}>
                      <Row>
                        <Form.Control type='hidden' defaultValue={shift.id} {...register(`schoolShifts.${key}.id`)} />
                        <Col sm={3} className='text-center'>
                          <p className='text-muted'>Turno</p>
                          <h4 className='card-title'>{shiftTypes[shift.shiftType]}</h4>
                        </Col>
                        <Col sm={3}>
                          <Form.Group>
                            <Form.Check
                              id={`schoolShifts.${key}.directorHasGroup`}
                              type='switch'
                              {...register(`schoolShifts.${key}.directorHasGroup`)}
                              label='El director tiene grupo'
                              defaultChecked={shift.directorHasGroup}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className={errors.director ? 'has-error' : ''}>
                            <Form.Label>Director</Form.Label>
                            <Form.Control
                              type='number'
                              defaultValue={shift.director}
                              {...register(`schoolShifts.${key}.director`)}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`schoolShifts.${key}.director`}
                              render={({ message }) => <Error message={message} />}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className={errors.administrative ? 'has-error' : ''}>
                            <Form.Label>Administrativo</Form.Label>
                            <Form.Control
                              type='number'
                              defaultValue={shift.administrative}
                              {...register(`schoolShifts.${key}.administrative`)}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`schoolShifts.${key}.administrative`}
                              render={({ message }) => <Error message={message} />}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Form.Group>
                            <Form.Label>Grado</Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Grupos</Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Alumnos</Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Maestros</Form.Label>
                          </Form.Group>
                        </Col>
                        <Col sm={8}>
                          <Row>
                            {shift.degrees
                              .sort((a, b) => (a.degree ? a.degree : 0) - (b.degree ? b.degree : 0))
                              .map((degree, index) => {
                                let title = `${degree.degree}º`
                                const initIndex = index
                                const subDegrees = shift.degrees.slice(initIndex, shift.degrees.length)
                                let indexI = 1
                                let subDegree = subDegrees[indexI]

                                const childDegrees = []

                                while (subDegree !== undefined) {
                                  if (!subDegree.mergeLeft) {
                                    break
                                  }
                                  childDegrees.push(subDegree)
                                  indexI++
                                  subDegree = subDegrees[indexI]
                                }

                                if (childDegrees.length) {
                                  title = `${degree.degree}º, ${childDegrees.map(d => `${d.degree}º`).join(', ')} `
                                }

                                return (
                                  <Col key={index} className={`text-center ${degree.mergeLeft ? 'd-none' : ''}`}>
                                    <Form.Control
                                      type='hidden'
                                      defaultValue={degree.id}
                                      {...register(`schoolShifts.${key}.degrees.${index}.id`)}
                                    />
                                    <Form.Group>
                                      <Form.Label>{title}</Form.Label>
                                    </Form.Group>
                                    <Form.Group className={errors.groups ? 'has-error' : ''}>
                                      <Form.Control
                                        type='number'
                                        size='sm'
                                        defaultValue={degree.groups}
                                        {...register(`schoolShifts.${key}.degrees.${index}.groups`)}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name={`schoolShifts.${key}.degrees.${index}.groups`}
                                        render={({ message }) => <Error message={message} />}
                                      />
                                    </Form.Group>
                                    <Form.Group className={errors.students ? 'has-error' : ''}>
                                      <Form.Control
                                        type='number'
                                        size='sm'
                                        defaultValue={degree.students}
                                        {...register(`schoolShifts.${key}.degrees.${index}.students`)}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name={`schoolShifts.${key}.degrees.${index}.students`}
                                        render={({ message }) => <Error message={message} />}
                                      />
                                    </Form.Group>
                                    <Form.Group className={errors.teachers ? 'has-error' : ''}>
                                      <Form.Control
                                        type='number'
                                        size='sm'
                                        defaultValue={degree.teachers}
                                        {...register(`schoolShifts.${key}.degrees.${index}.teachers`)}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name={`schoolShifts.${key}.degrees.${index}.teachers`}
                                        render={({ message }) => <Error message={message} />}
                                      />
                                    </Form.Group>
                                  </Col>
                                )
                              })}
                          </Row>
                        </Col>
                        <Col sm={2}>
                          <Form.Group>
                            <Form.Label>Total</Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type='number'
                              size='sm'
                              defaultValue={shift.totalGroups}
                              {...register(`schoolShifts.${key}.totalGroups`)}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`schoolShifts.${key}.totalGroups`}
                              render={({ message }) => <Error message={message} />}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type='number'
                              size='sm'
                              defaultValue={shift.totalStudents}
                              {...register(`schoolShifts.${key}.totalStudents`)}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`schoolShifts.${key}.totalStudents`}
                              render={({ message }) => <Error message={message} />}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type='number'
                              size='sm'
                              defaultValue={shift.totalTeachers}
                              {...register(`schoolShifts.${key}.totalTeachers`)}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`schoolShifts.${key}.totalTeachers`}
                              render={({ message }) => <Error message={message} />}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  )
                })}
            </div>
          </div>
        </Col>
        <Col sm={4}>
          <SoilTypes />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <TerrainDescription />
        </Col>
        <Col sm={4}>
          <Services />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <ExteriorWorks />
        </Col>
        <Col sm={4}></Col>
      </Row>
      <Row>
        <Col sm={8}>
          <CommunicationChannels />
        </Col>
        <Col sm={4}></Col>
      </Row>
    </Form>
  )
}

export default EstadisticControl
