import React, { useState, useEffect, ReactElement, useContext } from 'react'
import SchoolVisitImage from 'models/school_visit_image'
import SchoolVisit from 'models/school_visit'
import { Page, Text, View, Document, StyleSheet, Font, Image, Canvas } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import { IDegree, IShift } from 'interfaces/shift'
import regular from '../../assets/fonts/Roboto-Regular.ttf'
import bold from '../../assets/fonts/Roboto-Bold.ttf'
import { IExteriorWork } from 'interfaces/exterior_work'
import Infrastructure from '../../models/infrastructure'
import User from '../../models/user'
import { Button, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useParams } from 'react-router-dom'
import SchoolVisitService from 'services/school_visit_service'
import UserService from 'services/user_service'
import { AuthContext, IAuthContext } from 'context/useAuth'
import ImagesService from 'services/image_service'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: regular,
    },
    {
      src: bold,
      fontWeight: 'bold',
    },
  ],
})

const border = '1pt dotted #000'
const height = 13.5
const heightPage2 = 28

const styles = StyleSheet.create({
  basicCellPage1: {
    padding: 1,
    height: height,
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  basicCellPage2: {
    padding: 1,
    height: heightPage2,
    textAlign: 'center',
    justifyContent: 'center',
  },
  cell: {
    padding: 1,
    height: height,
    textAlign: 'center',
    justifyContent: 'flex-end',
    borderTop: '1pt dotted #000',
    borderLeft: '1pt dotted #000',
  },
})

type DocumentParams = {
  school_visit_id: string
}

export function DocumentVisit(): ReactElement {
  const history = useHistory()
  const { school_visit_id } = useParams<DocumentParams>()
  const [schoolVisit, setSchoolVisit] = useState<null | SchoolVisit>(null)

  useEffect(() => {
    handleGetSchoolVisit(+school_visit_id)
  }, [school_visit_id])

  const handleGetSchoolVisit = async (schoolVisitId: number) => {
    try {
      const data = await SchoolVisitService.getSchoolVisit(schoolVisitId)
      const ew = data.exteriorWorks

      const plaza = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('plaza'))
      const fosa = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('fosa'))
      const luminarias = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('luminarias'))
      const pozo = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('pozo'))
      const canchas = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('canchas'))
      const tanque = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('tanque'))
      const andadores = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('andadores'))
      const tinaco = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('tinaco'))
      const muros = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('muros'))
      const cercado = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('cercado'))
      const cisterna = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('cisterna'))
      const monumento = data.exteriorWorks.find(el => el.work.name?.toLowerCase().includes('monumento'))

      const ewOrdered = []

      if (plaza) ewOrdered.push(plaza)
      if (fosa) ewOrdered.push(fosa)
      if (luminarias) ewOrdered.push(luminarias)
      if (pozo) ewOrdered.push(pozo)
      if (canchas) ewOrdered.push(canchas)
      if (tanque) ewOrdered.push(tanque)
      if (andadores) ewOrdered.push(andadores)
      if (tinaco) ewOrdered.push(tinaco)
      if (muros) ewOrdered.push(muros)
      if (cercado) ewOrdered.push(cercado)
      if (cisterna) ewOrdered.push(cisterna)
      if (monumento) ewOrdered.push(monumento)

      data.exteriorWorks = ewOrdered
      setSchoolVisit(data)
    } catch (e) {
      console.log(e)
    }
  }

  function photographicPages() {
    if (schoolVisit) {
      const images = schoolVisit.schoolVisitImages.filter(image => image.image_type)
      images.sort((a, b) => a.id - b.id)
      const imagesArray = []

      for (let c = 0; c < Math.ceil(images.length / 4); c++) imagesArray.push(images.slice(c * 4, c * 4 + 4))

      return imagesArray.map((images, index) => (
        <PhotographicReportPage
          key={index}
          images={images}
          schoolVisit={schoolVisit}
          pageNumber={index + 1}
          totalPages={imagesArray.length}
          isFirst={index == 0}
        />
      ))
    }
  }

  return schoolVisit ? (
    <div>
      <Button variant='secondary' className='btn mb-3' onClick={() => history.goBack()}>
        <i className='mr-3'>
          <FontAwesomeIcon icon={faArrowLeft} />
        </i>
        Atras
      </Button>
      <div style={{ width: '100%', height: '80vh', backgroundColor: 'black' }}>
        <PDFViewer style={{ width: '100%', height: '100%' }}>
          <Document>
            <StadisticControlPage schoolVisit={schoolVisit} />
            <InfrastructuresPage schoolVisit={schoolVisit} />
            {photographicPages()}
          </Document>
        </PDFViewer>
      </div>
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
      <Spinner animation='border'></Spinner>
    </div>
  )
}

function StadisticControlPage({ schoolVisit }: { schoolVisit: SchoolVisit }) {
  return (
    <Page size='A4' style={{ padding: 10, fontSize: 6, fontFamily: 'Roboto' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <View>
          <Text style={{ fontSize: 12 }}>CONTROL ESTADÍSTICO</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 8, fontWeight: 'bold', paddingRight: 5 }}>C.T.</Text>
            <Text
              style={{
                fontSize: 7,
                fontWeight: 'bold',
                borderBottom: 1,
                paddingRight: 5,
                paddingLeft: 5,
                marginRight: 5,
              }}
            >
              {schoolVisit.school.clave}
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 8, fontWeight: 'bold', paddingRight: 5 }}>Escuela:</Text>
            <Text style={{ fontSize: 7, fontWeight: 'bold', borderBottom: 1, paddingRight: 5, paddingLeft: 5 }}>
              {schoolVisit.school.description}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', marginTop: 4 }}>
        <View style={{ flex: 2.7, flexDirection: 'column', border: 1, marginRight: 10 }}>
          <DegreeTable
            shift={schoolVisit.schoolShifts[schoolVisit.schoolShifts.findIndex(shift => shift.shiftType == 0)]}
          />
          <DegreeTable
            shift={schoolVisit.schoolShifts[schoolVisit.schoolShifts.findIndex(shift => shift.shiftType == 1)]}
          />
        </View>
        <View style={{ flex: 1, flexDirection: 'column', border: 1 }}>
          <View
            style={{
              padding: 5,
              height: height * 2 + 1,
              textAlign: 'center',
              justifyContent: 'center',
              borderBottom: border,
            }}
          >
            <Text>TECNOLOGÍAS EXISTENTES AUTORIZADAS POR EL IEEPO</Text>
          </View>
          <View
            style={{
              height: height * 4 + 1,
              borderBottom: 1,
            }}
          >
            {schoolVisit.existingTechnologies.split(',').map((tech, index) => (
              <View style={styles.basicCellPage1} key={index}>
                <Text>{tech}</Text>
              </View>
            ))}
          </View>
          <View
            style={{
              height: height * 4,
            }}
          >
            <View style={{ ...styles.basicCellPage1, borderBottom: border }}>
              <Text>Tipos de suelo</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <View style={{ ...styles.basicCellPage1, borderBottom: border }}>
                  <Text>I</Text>
                </View>
                <View style={{ ...styles.basicCellPage1, borderBottom: border }}>
                  <Text>II</Text>
                </View>
                <View style={{ ...styles.basicCellPage1 }}>
                  <Text>III</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 2, borderLeft: border, borderRight: border }}>
                <View style={{ ...styles.basicCellPage1, borderBottom: border }}>
                  <Text>SUELOS ROCOSOS</Text>
                </View>
                <View style={{ ...styles.basicCellPage1, borderBottom: border }}>
                  <Text>SUELOS ARENOSOS</Text>
                </View>
                <View style={{ ...styles.basicCellPage1 }}>
                  <Text>SUELOS ARCILLOSOS</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <View style={{ ...styles.basicCellPage1, borderBottom: border }}>
                  <Text>{schoolVisit.soilType.rockySoil ? 'X' : ''}</Text>
                </View>
                <View style={{ ...styles.basicCellPage1, borderBottom: border }}>
                  <Text>{schoolVisit.soilType.sandySoil ? 'X' : ''}</Text>
                </View>
                <View style={{ ...styles.basicCellPage1 }}>
                  <Text>{schoolVisit.soilType.claySoil ? 'X' : ''}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'column', marginTop: 4, border: 1 }}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column', flex: 4, borderRight: border }}>
            <View
              style={{
                padding: '1pt 0pt 0pt 1pt',
                height: height,
                fontSize: 9,
                borderBottom: border,
              }}
            >
              <Text>DESCRIPCIÓN DEL TERRENO</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flexDirection: 'column', flex: 1.1 }}>
                <View
                  style={{
                    padding: 1,
                    height: height,
                    justifyContent: 'flex-end',
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>SUPERFICIE DEL PREDIO:</Text>
                </View>
                <View
                  style={{
                    padding: 1,
                    height: height * 2,
                    justifyContent: 'center',
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>GEOREFERENCIACIÓN:</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <View
                  style={{
                    borderBottom: border,
                    borderRight: border,
                    flexDirection: 'row',
                    height: height,
                  }}
                >
                  <View
                    style={{
                      ...styles.basicCellPage1,
                      borderRight: border,
                      flex: 1,
                    }}
                  >
                    <Text>A=</Text>
                  </View>
                  <View
                    style={{
                      ...styles.basicCellPage1,
                      fontWeight: 'bold',
                      flex: 4,
                    }}
                  >
                    <Text>{schoolVisit.terrainDescription.area}</Text>
                  </View>
                </View>
                <View
                  style={{
                    padding: 1,
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                    height: height,
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>LATITUD: </Text>
                </View>
                <View
                  style={{
                    padding: 1,
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                    height: height,
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>LONGITUD: </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 1.5 }}>
                <View
                  style={{
                    ...styles.basicCellPage1,
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>REQUIERE MECÁNICA DE SUELOS </Text>
                </View>
                <View
                  style={{
                    ...styles.basicCellPage1,
                    fontWeight: 'bold',
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>{schoolVisit.terrainDescription.lat}</Text>
                </View>
                <View
                  style={{
                    ...styles.basicCellPage1,
                    fontWeight: 'bold',
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>{schoolVisit.terrainDescription.lng}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 0.4 }}>
                <View
                  style={{
                    ...styles.basicCellPage1,
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>SI</Text>
                </View>
                <View
                  style={{
                    padding: 1,
                    height: height * 2,
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: 8,
                    borderBottom: border,
                    borderRight: border,
                  }}
                >
                  <Text>{schoolVisit.terrainDescription.donation_certificate ? 'X' : ''}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 0.4 }}>
                <View
                  style={{
                    ...styles.basicCellPage1,
                    borderBottom: border,
                  }}
                >
                  <Text>NO</Text>
                </View>
                <View
                  style={{
                    padding: 1,
                    height: height * 2,
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: 8,
                    borderBottom: border,
                  }}
                >
                  <Text>{schoolVisit.terrainDescription.donation_certificate ? '' : 'X'}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <View style={{ padding: 1, height: height, justifyContent: 'flex-end' }}>
              <Text>NOTA:</Text>
            </View>
            <View style={{ padding: 1, height: height * 3, borderBottom: border }}>
              <Text>{schoolVisit.terrainDescription.notes}</Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column', flex: 1.599 }}>
            <View
              style={{
                padding: 1,
                height: height,
                justifyContent: 'flex-end',
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>PROPIEDAD DEL TERRENO: </Text>
            </View>
            <View
              style={{
                padding: 1,
                height: height,
                justifyContent: 'flex-end',
                borderRight: border,
              }}
            >
              <Text>PROCEDENCIA: </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>FEDERAL</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
              }}
            >
              <Text>{schoolVisit.terrainDescription.propertyType?.name == 'FEDERAL' ? 'X' : ''}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>MUNICIPAL</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
              }}
            >
              <Text>{schoolVisit.terrainDescription.propertyType?.name == 'MUNICIPAL' ? 'X' : ''}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>COMUNAL</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
              }}
            >
              <Text>{schoolVisit.terrainDescription.propertyType?.name == 'COMUNAL' ? 'X' : ''}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>EJIDAL</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
              }}
            >
              <Text>{schoolVisit.terrainDescription.propertyType?.name == 'EJIDAL' ? 'X' : ''}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>PRIVADA</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
              }}
            >
              <Text>{schoolVisit.terrainDescription.propertyType?.name == 'PRIVADA' ? 'X' : ''}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View
              style={{
                paddingTop: 1,
                paddingBottom: 1,
                height: height,
                textAlign: 'center',
                justifyContent: 'flex-end',
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>ACTA DE DONACIÓN</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
              }}
            >
              <Text>{schoolVisit.terrainDescription.donation_certificate ? 'X' : ''}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
              }}
            >
              <Text>OBSERVACIONES </Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
              }}
            >
              <Text>{schoolVisit.terrainDescription.observations}</Text>
            </View>
          </View>
        </View>
      </View>
      <ExteriorWorksTable exteriorWorks={schoolVisit.exteriorWorks} />
      <View style={{ flexDirection: 'row', marginTop: 4, border: 1 }}>
        <View style={{ flexDirection: 'column', flex: 1, marginRight: 5 }}>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                paddingTop: 1,
                paddingLeft: 1,
                height: height,
                justifyContent: 'flex-end',
                fontSize: 9,
                borderBottom: border,
                borderRight: border,
                flex: 4,
              }}
            >
              <Text>SERVICIOS</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>SI</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>NO</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                textAlign: 'left',
                borderBottom: border,
                borderRight: border,
                flex: 4,
              }}
            >
              <Text>RED DE DRENAJE A COLECTOR MUNICIPAL </Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.drainageNetwork ? 'X' : ''}</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.drainageNetwork ? '' : 'X'}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                textAlign: 'left',
                borderRight: border,
                flex: 4,
              }}
            >
              <Text>AGUA POTABLE</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.drinkingWater ? 'X' : ''}</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.drinkingWater ? '' : 'X'}</Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: 'column', flex: 1, marginLeft: 5, marginRight: 5, borderLeft: border }}>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 4,
              }}
            >
              <Text></Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>SI</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>NO</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                textAlign: 'left',
                borderBottom: border,
                borderRight: border,
                flex: 4,
              }}
            >
              <Text>ENERGIA ELÉCTRICA </Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.electricPower ? 'X' : ''}</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.electricPower ? '' : 'X'}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                textAlign: 'left',
                borderRight: border,
                flex: 4,
              }}
            >
              <Text>LÍNEA TELÉFONICA </Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.telephoneLine ? 'X' : ''}</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.telephoneLine ? '' : 'X'}</Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: 'column', flex: 1, marginLeft: 5, borderLeft: border }}>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 4,
              }}
            >
              <Text></Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>SI</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>NO</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                textAlign: 'left',
                borderBottom: border,
                borderRight: border,
                flex: 4,
              }}
            >
              <Text>INTERNET</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.internet ? 'X' : ''}</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderBottom: border,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.internet ? '' : 'X'}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                ...styles.basicCellPage1,
                textAlign: 'left',
                borderRight: border,
                flex: 4,
              }}
            >
              <Text>OTROS {schoolVisit.schoolService.others ? `(${schoolVisit.schoolService.others})` : ''}</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text>{schoolVisit.schoolService.others ? 'X' : ''}</Text>
            </View>
            <View
              style={{
                ...styles.basicCellPage1,
                borderRight: border,
                flex: 1,
              }}
            >
              <Text></Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', marginTop: 4, border: 1, height: '37vh' }}>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <View
            style={{
              paddingTop: 5,
              paddingLeft: 5,
              fontSize: 9,
            }}
          >
            <Text>MACROLOCALIZACIÓN</Text>
          </View>
          <View
            style={{
              width: '100%',
              height: '100%',
              padding: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {schoolVisit.schoolVisitImages.find(img => img.isMacrolocation) ? (
              <Image
                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                src={ImagesService.getUrl(schoolVisit.schoolVisitImages.find(img => img.isMacrolocation)?.image_url)}
              />
            ) : (
              <View></View>
            )}
          </View>
        </View>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <View
            style={{
              paddingTop: 5,
              paddingLeft: 5,
              fontSize: 9,
            }}
          >
            <Text>MICROLOCALIZACIÓN</Text>
          </View>
          <View
            style={{
              width: '100%',
              height: '100%',
              padding: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {schoolVisit.schoolVisitImages.find(img => img.isMicrolocation) ? (
              <Image
                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                src={ImagesService.getUrl(schoolVisit.schoolVisitImages.find(img => img.isMicrolocation)?.image_url)}
              />
            ) : (
              <View></View>
            )}
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', borderBottom: 1, borderLeft: 1, borderRight: 1 }}>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <View
            style={{
              textAlign: 'center',
              padding: 1,
              borderRight: border,
              borderBottom: border,
              fontSize: 7,
              height: 20,
            }}
          >
            <Text>ESPECIFIQUE MESES SIN ACCESO A LA COMUNIDAD</Text>
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              padding: 1,
              borderRight: border,
              height: 34,
              fontSize: 7,
            }}
          >
            <Text>{schoolVisit.communicationChannels.noMonthsCommunication}</Text>
          </View>
        </View>
        <View style={{ flex: 5, flexDirection: 'column' }}>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              padding: 1,
              borderBottom: border,
              height: 20,
              fontSize: 9,
            }}
          >
            <Text>VÍAS DE COMUNICACIÓN</Text>
          </View>
          <View
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              padding: 1,
              height: 34,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1, flexDirection: 'column', height: 32, marginRight: 2 }}>
                <View style={{ flexDirection: 'row', height: 12 }}>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Image style={{ height: 8 }} src='/pavement.png' />
                  </View>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Text>Pavimento</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.pavement?.split('-')[0]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>KM</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.pavement?.split('-')[1]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>HR</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.pavement?.split('-')[2]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>MIN</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', height: 12 }}>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Image style={{ height: 8 }} src='/breach.png' />
                  </View>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Text>BRECHA</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.breach?.split('-')[0]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>KM</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.breach?.split('-')[1]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>HR</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.breach?.split('-')[2]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>MIN</Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'column', height: 32, marginLeft: 2 }}>
                <View style={{ flexDirection: 'row', height: 12 }}>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Image style={{ height: 8 }} src='/dirtyRoad.png' />
                  </View>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Text>TERRACERIA</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.dirtRoad?.split('-')[0]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>KM</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.dirtRoad?.split('-')[1]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>HR</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.dirtRoad?.split('-')[2]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>MIN</Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', height: 12 }}>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Image style={{ height: 8 }} src='/bridlePath.png' />
                  </View>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Text>HERRADURA</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.bridlePath?.split('-')[0]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>KM</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.bridlePath?.split('-')[1]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>HR</Text>
                  </View>
                  <View style={{ flex: 1, borderBottom: border, justifyContent: 'flex-end' }}>
                    <Text>{schoolVisit.communicationChannels.bridlePath?.split('-')[2]}</Text>
                  </View>
                  <View style={{ flex: 0.4, justifyContent: 'flex-end' }}>
                    <Text>MIN</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={{ flex: 1, marginTop: 25 }}>
        <Signature userId={schoolVisit.user.id} />
      </View>
    </Page>
  )
}

function DegreeTable({ shift }: { shift: IShift }) {
  shift.degrees = shift.degrees.sort((a, b) => (a.degree || 0) - (b.degree || 0))

  return (
    <View
      style={{
        marginRight: 10,
        flex: 3,
        flexDirection: 'column',
        width: '100%',
        marginTop: 5,
      }}
    >
      <View style={{ flexDirection: 'column', flex: 1, borderBottom: shift.shiftType == 0 ? border : '' }}>
        <Text style={{ marginLeft: 95, marginBottom: 2 }}>
          {shift.shiftType == 0 ? 'TURNO MATUTINO' : 'TURNO VESPERTINO'}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ padding: 2, height: height }}></View>
            <View
              style={{
                padding: 2,
                height: height,
                borderTop: '1pt dotted #000',
                textAlign: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Text> No. DE GRUPOS </Text>
            </View>
            <View
              style={{
                padding: 2,
                height: height,
                borderTop: '1pt dotted #000',
                textAlign: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Text> No. DE ALUMNOS </Text>
            </View>
            <View
              style={{
                padding: 2,
                height: height,
                borderTop: '1pt dotted #000',
                textAlign: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Text> No. DE MAESTROS </Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row', flex: 6 }}>
            {shift.degrees.map((degree, index) => (
              <DegreeRow degrees={shift.degrees} degree={degree} key={index} />
            ))}
          </View>

          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View style={styles.cell}>
              <Text>TOTAL</Text>
            </View>
            <View style={styles.cell}>
              <Text>{shift.totalGroups}</Text>
            </View>
            <View style={styles.cell}>
              <Text>{shift.totalStudents}</Text>
            </View>
            <View style={styles.cell}>
              <Text>{shift.totalTeachers}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 4 }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flexDirection: 'column', flex: 1.5 }}>
                <View style={{ ...styles.cell }}>
                  <Text>ADMINISTRATIVO</Text>
                </View>
                <View style={styles.cell}>
                  <Text>{shift.administrative}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <View style={{ ...styles.cell, paddingLeft: 5, paddingRight: 5 }}>
                  <Text>DIRECTOR</Text>
                </View>
                <View style={styles.cell}>
                  <Text>{shift.director}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                padding: 2,
                height: height * 2,
                borderTop: '1pt dotted #000',
                borderLeft: '1pt dotted #000',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text>{shift.directorHasGroup ? 'Director con grupo' : 'Director sin grupo'}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

function DegreeRow({ degree, degrees }: { degree: IDegree; degrees: Array<IDegree> }) {
  const [title, setTitle] = useState('')

  useEffect(() => {
    let title = `${degree.degree}º`
    const initIndex = degrees.findIndex(d => d.id == degree.id)
    const subDegrees = degrees.slice(initIndex, degrees.length)
    let indexI = 1
    let subDegree = subDegrees[indexI]

    const childDegrees = []

    while (subDegree !== undefined) {
      if (!subDegree.mergeLeft) {
        break
      }
      childDegrees.push(subDegree)
      indexI++
      subDegree = subDegrees[indexI]
    }

    if (childDegrees.length) {
      title = `${degree.degree}º, ${childDegrees.map(d => `${d.degree}º`).join(', ')} `
    }

    setTitle(title)
  }, [])

  return degree.mergeLeft ? (
    <View style={{ flexDirection: 'column', flex: 0 }}></View>
  ) : (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      <View style={styles.cell}>
        <Text>{title}</Text>
      </View>
      <View style={styles.cell}>
        <Text>{degree.groups}</Text>
      </View>
      <View style={styles.cell}>
        <Text>{degree.students}</Text>
      </View>
      <View style={styles.cell}>
        <Text>{degree.teachers}</Text>
      </View>
    </View>
  )
}

function ExteriorWorksTable({ exteriorWorks }: { exteriorWorks: Array<IExteriorWork> }) {
  return (
    <View style={{ flexDirection: 'column', marginTop: 4, border: 1 }}>
      <View
        style={{
          padding: 1,
          height: height,
          justifyContent: 'flex-end',
          fontSize: 9,
        }}
      >
        <Text>OBRA EXTERIOR EXISTENTE</Text>
      </View>

      <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {exteriorWorks.map((exteriorWork, index) => (
          <View style={{ flexDirection: 'row', width: '49%' }} key={index}>
            <View
              style={{
                ...styles.basicCellPage1,
                textAlign: 'left',
                flex: 2,
                borderTop: border,
                borderLeft: index % 2 ? border : '',
                borderRight: border,
              }}
            >
              <Text>{exteriorWork.work.name}</Text>
            </View>
            <View style={{ ...styles.basicCellPage1, flex: 0.5, borderTop: border, borderRight: border }}>
              <Text>{exteriorWork.notes.trim() ? exteriorWork.notes : ''}</Text>
            </View>
            <View style={{ ...styles.basicCellPage1, flex: 2, borderTop: border, borderRight: border }}>
              <Text>{exteriorWork.quantity !== undefined ? exteriorWork.quantity.toFixed(2) : ''}</Text>
            </View>
            <View
              style={{ ...styles.basicCellPage1, flex: 0.5, borderTop: border, borderRight: index % 2 ? '' : border }}
            >
              <Text>{exteriorWork.work.base_unit}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

const numberOfInfrastructures = 16

function InfrastructuresPage({ schoolVisit }: { schoolVisit: SchoolVisit }) {
  return (
    <Page size='A4' orientation='landscape' style={{ padding: 10, fontSize: 6, fontFamily: 'Roboto' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <View>
          <Text style={{ fontSize: 12 }}>INFRAESTRUCTURA EXISTENTE</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 8, fontWeight: 'bold', paddingRight: 5 }}>C.T.</Text>
            <Text
              style={{
                fontSize: 7,
                fontWeight: 'bold',
                borderBottom: 1,
                paddingRight: 5,
                paddingLeft: 5,
                marginRight: 5,
              }}
            >
              {schoolVisit.school.clave}
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 8, fontWeight: 'bold', paddingRight: 5 }}>Escuela:</Text>
            <Text style={{ fontSize: 7, fontWeight: 'bold', borderBottom: 1, paddingRight: 5, paddingLeft: 5 }}>
              {schoolVisit.school.description}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'column',
          border: 1,
          width: '100%',
          marginTop: 10,
          height: heightPage2 * numberOfInfrastructures + numberOfInfrastructures,
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 0.5 }}>
            <Text>EDIF.</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 0.5 }}>
            <Text>N° DE ESPACIOS</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 3 }}>
            <Text>ESPACIOS EDUCATIVOS</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 1 }}>
            <Text>ESTRUCTURA</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 0.5 }}>
            <Text>No. E.E.</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 3 }}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <View style={{ ...styles.basicCellPage2, borderBottom: border, height: heightPage2 / 2 }}>
                <Text>TIPOS Y ACABADOS</Text>
              </View>
              <View style={{ flexDirection: 'row', flex: 1 }}>
                <View
                  style={{
                    ...styles.basicCellPage2,
                    height: heightPage2 / 2,
                    borderRight: border,
                    flex: 1,
                  }}
                >
                  <Text>MUROS</Text>
                </View>
                <View
                  style={{
                    ...styles.basicCellPage2,
                    height: heightPage2 / 2,
                    borderRight: border,
                    flex: 1,
                  }}
                >
                  <Text>TECHUMBRE</Text>
                </View>
                <View style={{ ...styles.basicCellPage2, height: heightPage2 / 2, flex: 1 }}>
                  <Text>CANCELARÍA</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 1 }}>
            <Text>CONSTRUÍDO POR:</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 1 }}>
            <Text>AÑO DE {'\n'} CONSTRUCCIÓN</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, borderBottom: border, flex: 2 }}>
            <Text>OBSERVACIONES</Text>
          </View>
        </View>
        <InfrastructuresRows
          infrastructures={schoolVisit.infrastructures.sort((a, b) => {
            if (a.building < b.building) return -1
            if (a.building > b.building) return 1
            return 0
          })}
        />
      </View>
      <View style={{ flex: 1, marginTop: 25 }}>
        <Signature userId={schoolVisit.user.id} />
      </View>
    </Page>
  )
}

function InfrastructuresRows({ infrastructures }: { infrastructures: Array<Infrastructure> }) {
  const [infrastructuresFilled, setInfrastructuresFilled] = useState(infrastructures)

  useEffect(() => {
    while (infrastructures.length < numberOfInfrastructures) {
      infrastructuresFilled.push(new Infrastructure({}))
    }
    setInfrastructuresFilled([...infrastructuresFilled])
  }, [])

  return (
    <View>
      {infrastructuresFilled.map((infrastructure, index) => (
        <InfrastructureRow infrastructure={infrastructure} key={index} />
      ))}
    </View>
  )
}

function InfrastructureRow({ infrastructure }: { infrastructure: Infrastructure }) {
  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 0.5 }}>
        <Text>{infrastructure.building}</Text>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 0.5 }}>
        <Text>{infrastructure.spaces_number ? infrastructure.spaces_number : ''}</Text>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 3 }}>
        <Text>{infrastructure.education_spaces}</Text>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 1 }}>
        <Text>{infrastructure.structure}</Text>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 0.5 }}>
        <Text>{infrastructure.no_e_e ? infrastructure.no_e_e : ''}</Text>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 3 }}>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View
            style={{
              ...styles.basicCellPage2,
              borderRight: border,
              flex: 1,
            }}
          >
            <Text>{infrastructure.muros}</Text>
          </View>
          <View
            style={{
              ...styles.basicCellPage2,
              borderRight: border,
              flex: 1,
            }}
          >
            <Text>{infrastructure.techumbre}</Text>
          </View>
          <View style={{ ...styles.basicCellPage2, flex: 1 }}>
            <Text>{infrastructure.canceleria}</Text>
          </View>
        </View>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 1 }}>
        <Text>{infrastructure.createdBy}</Text>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, borderRight: border, flex: 1 }}>
        <Text>{infrastructure.createdYear}</Text>
      </View>
      <View style={{ ...styles.basicCellPage2, borderBottom: border, flex: 2 }}>
        <Text>{infrastructure.observations}</Text>
      </View>
    </View>
  )
}

function PhotographicReportPage({
  images,
  schoolVisit,
  pageNumber,
  totalPages,
  isFirst,
}: {
  images: Array<SchoolVisitImage>
  schoolVisit: SchoolVisit
  pageNumber: number
  totalPages: number
  isFirst?: boolean
}) {
  return (
    <Page size='A4' orientation='landscape' style={{ padding: 10, fontSize: 6, fontFamily: 'Roboto' }}>
      <View style={{ height: '100%' }}>
        <View style={{ flex: 4, flexDirection: 'column', alignContent: 'space-between' }}>
          <View
            style={{
              flex: 2,
              flexDirection: 'row',
              alignItems: 'baseline',
            }}
          >
            <Image style={{ height: 80 }} src='/oaxaca.png' />
            <Text style={{ fontSize: 13, fontWeight: 'bold' }}> DIRECCIÓN GENERAL-ASESORÍA TÉCNICA </Text>
            <Image style={{ height: 80 }} src='/ieepo.png' />
            <View style={{ flexDirection: 'column' }}>
              <View style={{ border: 1, borderRadius: 2, padding: '2 10 2 10', textAlign: 'center' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}> REPORTE FOTOGRÁFICO</Text>
              </View>
              <View style={{ height: 20 }}></View>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: 9,
            }}
          >
            <View style={{ flexDirection: 'column', flex: 2, marginLeft: 25 }}>
              <View
                style={{
                  flexDirection: 'row',
                  borderBottom: border,
                  height: 18,
                }}
              >
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>ESCUELA:</Text>
                <Text style={{ alignSelf: 'flex-end', marginLeft: 10 }}>{schoolVisit.school.description}</Text>
              </View>
              <View style={{ flexDirection: 'row', borderBottom: border, height: 18 }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>SERVICIO:</Text>
                <Text style={{ alignSelf: 'flex-end', marginLeft: 10 }}>INFRAESTRUCTURA EXISTENTE</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', flex: 1, marginLeft: 25, marginRight: 25 }}>
              <View style={{ flexDirection: 'row', borderBottom: border, height: 18 }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>Clave:</Text>
                <Text style={{ alignSelf: 'flex-end', marginLeft: 10 }}>{schoolVisit.school.clave}</Text>
              </View>
              <View style={{ flexDirection: 'row', borderBottom: border, height: 18 }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>Hoja:</Text>
                <Text style={{ alignSelf: 'flex-end', flex: 1, marginLeft: 10 }}>{pageNumber}</Text>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>DE:</Text>
                <Text style={{ alignSelf: 'flex-end', flex: 1, marginLeft: 10 }}>{totalPages}</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', flex: 1.5, marginLeft: 25 }}>
              <View style={{ flexDirection: 'row', borderBottom: border, height: 18 }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>UBICACIÓN:</Text>
                <Text style={{ alignSelf: 'flex-end', marginLeft: 10 }}>{schoolVisit.school.locality.description}</Text>
              </View>
              <View style={{ flexDirection: 'row', borderBottom: border, height: 18 }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-end' }}>REGION:</Text>
                <Text style={{ alignSelf: 'flex-end', marginLeft: 10 }}>{schoolVisit.school.region.description}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ border: 1, flex: 10 }}>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              flex: 1,
            }}
          >
            {images.map((image, index) => (
              <View
                style={{
                  width: '50%',
                  height: '50%',
                  padding: 10,
                  alignItems: 'center',
                }}
                key={index}
              >
                <Image
                  style={{ height: '90%', width: '90%', objectFit: 'contain' }}
                  src={ImagesService.getUrl(image.image_url)}
                />
                <Text style={{ fontSize: 10 }}>{image.description}</Text>
                {index == 0 && isFirst ? (
                  <>
                    <Text style={{ fontSize: 8, fontWeight: 400 }}>
                      lat: {image.lat} lng: {image.lng}
                    </Text>
                  </>
                ) : (
                  <></>
                )}
              </View>
            ))}
          </View>
        </View>
        <View style={{ flex: 1, marginTop: 25, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Signature userId={schoolVisit.user.id} />
          <View>
            <Image style={{ height: 65 }} src='/ieepo-oaxaca.png' />
          </View>
        </View>
      </View>
    </Page>
  )
}

//todo make signature
function Signature({ userId }: { userId: number }) {
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    configUser()
  }, [])

  async function configUser() {
    const auth = localStorage.getItem('currentUser')
    if (auth) {
      let user = new User(JSON.parse(auth))
      if (user.role.name == 'Administrador') user = await UserService.getUser(userId)

      setUser(user)
    }
  }

  return (
    <View style={{ flexDirection: 'row', height: 30, width: 280 }}>
      <View style={{ flex: 0.5, paddingTop: 5 }}>
        <Text>ELABORÓ:</Text>
      </View>
      <View style={{ flex: 4 }}>
        <View
          style={{
            borderBottom: border,
            height: 15,
            fontSize: 9,
            textAlign: 'center',
            alignContent: 'flex-end',
          }}
        >
          {user ? (
            <Text style={{ textAlign: 'center', marginTop: 2 }}>
              {user?.major} {user?.firstName} {user?.lastName} {user?.motherLastName}
            </Text>
          ) : (
            <Text></Text>
          )}
        </View>
        <View style={{ textAlign: 'center', paddingTop: 1 }}>
          <Text>NOMBRE Y FIRMA</Text>
        </View>
      </View>
    </View>
  )
}
