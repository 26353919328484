import React, { useState, ReactNode, ReactElement, Dispatch, SetStateAction } from 'react'
import SchoolService from 'services/school_service'
import { ISchoolItem } from 'interfaces/school'
import { CommonSelect, Query } from 'views/reports'

export interface IReportContext {
  statuses: Array<CommonSelect>
  niveles: Array<CommonSelect>
  municipalities: Array<CommonSelect>
  setStatuses: Dispatch<SetStateAction<Array<CommonSelect>>>
  setNiveles: Dispatch<SetStateAction<Array<CommonSelect>>>
  setMunicipalities: Dispatch<SetStateAction<Array<CommonSelect>>>
  schools: Array<ISchoolItem>
  setSchools: Dispatch<SetStateAction<Array<ISchoolItem>>>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  limit: number
  setLimit: Dispatch<SetStateAction<number>>
  total: number
  setTotal: Dispatch<SetStateAction<number>>
  queries: Array<Query>
  setQueries: Dispatch<SetStateAction<Array<Query>>>
}

type ReportProps = {
  children: ReactNode
}

export const ReportContext = React.createContext<IReportContext | null>(null)

const ReportProvider = ({ children }: ReportProps): ReactElement => {
  const [statuses, setStatuses] = useState<Array<CommonSelect>>([])
  const [niveles, setNiveles] = useState<Array<CommonSelect>>([])
  const [municipalities, setMunicipalities] = useState<Array<CommonSelect>>([])
  const [schools, setSchools] = useState(new Array<ISchoolItem>(0))
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(-1)
  const [queries, setQueries] = useState(new Array<Query>(0))

  return (
    <ReportContext.Provider
      value={{
        statuses,
        niveles,
        municipalities,
        setStatuses,
        setNiveles,
        setMunicipalities,
        schools,
        setSchools,
        page,
        setPage,
        limit,
        setLimit,
        total,
        setTotal,
        queries,
        setQueries,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

export default ReportProvider
