import React, { useEffect, useState, ReactElement } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import 'select2-component/dist/select2.min.css'
import Service from 'models/service'

type ServiceProps = {
  onSubmit: (service: Service) => void
  onShow: boolean
  onClose: () => void
  service: Service
}

const ServiceForm = ({ onSubmit, onShow, onClose, service }: ServiceProps): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm()

  type FormValues = {
    option: string
  }

  useEffect(() => {
    setValue('option', service.name)
  }, [service])

  const onFormSubmit = (values: FormValues) => {
    service.name = values.option
    onSubmit(service)
  }

  return (
    <Modal size='lg' show={onShow} onHide={onClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{service.id ? 'Editar servicio' : 'Crear servicio'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control {...register('option')} isInvalid={!!errors.option} />
                <Form.Control.Feedback type='invalid'>{errors.option?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            Cerrar
          </Button>
          <Button as='input' type='submit' value='Guardar' />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

ServiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ServiceForm
