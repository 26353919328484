import React, { useEffect, useState, ReactElement } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import 'select2-component/dist/select2.min.css'
import StringOption from 'models/string_option'

type OptionProps = {
  onSubmit: (option: StringOption) => void
  onShow: boolean
  onClose: () => void
  type: string
  title: string
}

const OptionForm = ({ onSubmit, onShow, onClose, type, title }: OptionProps): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm()

  useEffect(() => {
    if (!onShow) setValue('option', '')
  }, [onShow])

  type FormValues = {
    option: string
  }

  const onFormSubmit = (values: FormValues) => {
    const option = new StringOption({ option: values.option, type: type })
    onSubmit(option)
  }

  return (
    <Modal size='lg' show={onShow} onHide={onClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control {...register('option')} isInvalid={!!errors.option} />
                <Form.Control.Feedback type='invalid'>{errors.option?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            Cerrar
          </Button>
          <Button as='input' type='submit' value='Guardar' />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

OptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default OptionForm
