import { ISchoolProperties } from 'interfaces/school'
import Locality from 'models/locality'
import Municipality from 'models/municipality'
import District from 'models/district'
import Region from 'models/region'
import EducationLevel from 'models/education_level'

export default class School {
  id
  clave
  shift
  description
  locality
  municipality
  district
  region
  educationLevel
  students
  teachers
  managers
  directives
  constructor(properties: ISchoolProperties = {}) {
    this.id = properties.id || 0
    this.clave = properties.clave || ''
    this.shift = properties.shift || ''
    this.description = properties.description || ''
    this.locality = properties.locality || new Locality()
    this.municipality = properties.municipality || new Municipality()
    this.district = properties.district || new District()
    this.region = properties.region || new Region()
    this.educationLevel = properties.educationLevel || new EducationLevel()
    this.students = properties.students || 0
    this.teachers = properties.teachers || 0
    this.managers = properties.managers || 0
    this.directives = properties.directives || 0
  }
}

export const SchoolHeaders = ['Clave', 'Nombre', 'Localidad', 'Municipio', 'Distrito', 'Región', 'Nivel', 'Acciones']
