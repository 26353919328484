import React, { useEffect, useContext, ReactElement, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faEye } from '@fortawesome/free-solid-svg-icons'
import { SchoolHeaders } from 'models/school'

import CustomTable from 'components/table/custom_table'
import Pagination from 'components/pagination/pagination'

import SimpleCard from 'components/cards/simple_card'
import SchoolSearch from 'views/users/search'

import { SchoolContext, ISchoolContext } from 'context/school_context'

const Schools = (): ReactElement => {
  const history = useHistory()
  const { search, page, perPage, totalSchools, schools, updateSchools } = useContext(SchoolContext) as ISchoolContext

  useEffect(() => {
    updateSchools(search, page, perPage)
  }, [])

  const handlePageChange = async (newPage: number) => {
    //setLoading(true)
    await updateSchools(search, newPage, perPage)
    //setLoading(false)
  }

  const handlePerPage = async (newPerPage: number) => {
    //setLoading(true)
    await updateSchools(search, page, newPerPage)
    //setLoading(false)
  }

  const handleSearch = async (tag: string) => {
    await updateSchools(tag, page, perPage)
  }

  const dataBody = () => {
    return (
      <Fragment>
        {schools.map((school, key) => {
          return (
            <tr key={key} className={`table-${school.color ? school.color : 'light'}`}>
              <td>{school.clave}</td>
              <td>{school.description}</td>
              <td>{school.locality}</td>
              <td>{school.municipality}</td>
              <td>{school.district}</td>
              <td>{school.region}</td>
              <td>{school.educationLevel}</td>
              <td>
                <div className='action d-inline-flex'>
                  <OverlayTrigger overlay={<Tooltip id='edit-visit'>Ver detalle</Tooltip>}>
                    <span className='mr-3'>
                      <Button variant='outline-info' size='sm' onClick={() => history.push(`/schools/${school.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                  {school.lastVisitId && (
                    <OverlayTrigger overlay={<Tooltip id='show-visit'>Ver última visita</Tooltip>}>
                      <span>
                        <Button
                          variant='outline-info'
                          size='sm'
                          onClick={() => history.push(`/school_visits/${school.lastVisitId}`)}
                        >
                          <FontAwesomeIcon icon={faList} />
                        </Button>
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }
  return (
    <SimpleCard title='Lista de Escuelas'>
      <Fragment>
        <Row>
          <Col md={{ span: 6, offset: 6 }}>
            <SchoolSearch onSubmit={handleSearch} defaultSearch={search} />
          </Col>
        </Row>
        <CustomTable headers={SchoolHeaders} dataBody={dataBody()} />
        <Pagination
          totalRecords={totalSchools}
          pageLimit={perPage}
          currentPage={page}
          changePage={handlePageChange}
          changePageLimit={handlePerPage}
        />
      </Fragment>
    </SimpleCard>
  )
}

export default Schools
