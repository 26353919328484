import React, { useState, useEffect, ReactElement, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCircle, faEye, faEdit } from '@fortawesome/free-solid-svg-icons'
import CustomTable from 'components/table/custom_table'
import Map from 'components/map/map'
import SimpleCard from 'components/cards/simple_card'
import School from 'models/school'
import SchoolService from 'services/school_service'
import SchoolVisitService from 'services/school_visit_service'
import { ISchoolVisitItem } from 'interfaces/school_visit'
import { SchoolVisitHeaders, SchoolVisitStatus } from 'models/school_visit'

type paramsProps = {
  school_id: string
}

const SchoolDetail = (): ReactElement => {
  const { school_id } = useParams<paramsProps>()
  const history = useHistory()
  const [school, setSchool] = useState<School | null>(null)
  const [visits, setVisits] = useState(new Array<ISchoolVisitItem>(0))

  useEffect(() => {
    getSchool(+school_id)
  }, [school_id])

  const dataBody = () => {
    return (
      <Fragment>
        {visits.map((school, key) => {
          return (
            <tr key={key}>
              <td>{school.clave}</td>
              <td>{school.description}</td>
              <td>{school.locality}</td>
              <td>{school.municipality}</td>
              <td>{school.district}</td>
              <td>{school.region}</td>
              <td>{school.educationLevel}</td>
              <td>{`${school.userFirstName?.toUpperCase()} ${school.userLastName?.toUpperCase()} ${school.userMotherLastName?.toUpperCase()}`}</td>
              <td>
                {school.status !== undefined && (
                  <span className={`text-${SchoolVisitStatus[school.status].color} d-inline-flex`}>
                    <i className='mr-2'>
                      <FontAwesomeIcon icon={faCircle} size='sm' />
                    </i>
                    {SchoolVisitStatus[school.status].label}
                  </span>
                )}
              </td>
              <td>
                <div className='text-center'>
                  {school.status === 2 && (
                    <div className='my-1'>
                      <OverlayTrigger overlay={<Tooltip id='edit-visit'>Detalle</Tooltip>}>
                        <Button
                          variant='outline-info'
                          size='sm'
                          onClick={() => history.push(`/school_visits/${school.id}`)}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                  {school.status !== 2 && (
                    <div className='my-1'>
                      <OverlayTrigger overlay={<Tooltip id='edit-visit'>Editar</Tooltip>}>
                        <Button
                          variant='outline-info'
                          size='sm'
                          onClick={() => history.push(`/school_visits/${school.id}`)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  const getSchool = async (schoolId: number) => {
    try {
      console.log('getSchool')
      const school = await SchoolService.getSchool(schoolId)
      setSchool(school)

      console.log('getVisits')
      const visits = await SchoolVisitService.getSchoolVisits(schoolId)
      setVisits(visits)
    } catch (e) {
      console.log('show school:', e)
    }
  }
  return (
    <div>
      {school && (
        <Fragment>
          <div className='card'>
            <div className='card-header bg-white'>
              <h4 className='card-title'>
                DATOS DE ESCUELA
                <div className='float-right'>
                  <Button variant='info' className='btn-icon' onClick={() => history.goBack()}>
                    <span className='btn-icon-label'>
                      <i className='mr-2'>
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </i>
                    </span>
                    Regresar
                  </Button>
                </div>
              </h4>
            </div>
          </div>
          <form className='needs-validation' noValidate={false}>
            <div className='row'>
              <div className='col-md-8'>
                <SimpleCard>
                  <Fragment>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label>Nombre</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Nombre'
                            defaultValue={school.description}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Clave</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='First name'
                            defaultValue={school.clave}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Turno</label>
                          <input
                            type='text'
                            defaultValue={school.shift}
                            className='form-control'
                            placeholder='Turno'
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Nivel</label>
                          <input
                            type='text'
                            defaultValue={school.educationLevel.description}
                            className='form-control'
                            placeholder='Nivel'
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Localidad</label>
                          <input
                            type='text'
                            defaultValue={school.locality.description}
                            className='form-control'
                            placeholder='Localidad'
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Municipio</label>
                          <input
                            type='text'
                            defaultValue={school.municipality.description}
                            className='form-control'
                            placeholder='Municipio'
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Distrito</label>
                          <input
                            type='text'
                            defaultValue={school.district.description}
                            className='form-control'
                            placeholder='Distrito'
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Región</label>
                          <input
                            type='text'
                            defaultValue={school.region.description}
                            className='form-control'
                            placeholder='Región'
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                </SimpleCard>
              </div>
              <div className='col-md-4'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Estudiantes</label>
                          <input
                            type='text'
                            defaultValue={school.students}
                            className='form-control'
                            placeholder='Estudiantes'
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Profesores</label>
                          <input
                            type='text'
                            defaultValue={school.teachers}
                            className='form-control'
                            placeholder='Profesores'
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Directivos</label>
                          <input
                            type='text'
                            defaultValue={school.directives}
                            className='form-control'
                            placeholder='Directivos'
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Administrativos</label>
                          <input
                            type='text'
                            defaultValue={school.managers}
                            className='form-control'
                            placeholder='Administrativos'
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <SimpleCard title='Ubicación de la Escuela'>
            <Map />
          </SimpleCard>
          <SimpleCard title='Visitas de la escuela'>
            <CustomTable headers={SchoolVisitHeaders} dataBody={dataBody()} />
          </SimpleCard>
        </Fragment>
      )}
    </div>
  )
}

export default SchoolDetail
