import { IInfrastructure } from 'interfaces/infrastructure'

export default class Infrastructure {
  id
  building
  spaces_number
  education_spaces
  structure
  no_e_e
  muros
  techumbre
  canceleria
  createdBy
  createdYear
  observations
  necesities
  constructor(properties: IInfrastructure = {}) {
    this.id = properties.id || 0
    this.building = properties.building || ''
    this.spaces_number = properties.spaces_number || 0
    this.education_spaces = properties.education_spaces || ''
    this.structure = properties.structure || ''
    this.no_e_e = properties.no_e_e || 0
    this.muros = properties.muros || ''
    this.techumbre = properties.techumbre || ''
    this.canceleria = properties.canceleria || ''
    this.createdBy = properties.createdBy || ''
    this.createdYear = properties.createdYear || ''
    this.observations = properties.observations || ''
    this.necesities = properties.necesities || ''
  }
}
