import React, { useEffect, useState, ReactElement, Fragment } from 'react'
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
//import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserEdit, faUserPlus, faUserTimes, faCircle } from '@fortawesome/free-solid-svg-icons'
import SwAlert from 'components/alert/swal'
import CustomTable from 'components/table/custom_table'
import Pagination from 'components/pagination/pagination'

import User, { UserHeaders } from 'models/user'
import UserService from 'services/user_service'
import UserForm from './form'
import UserSearch from './search'

const Users = (): ReactElement => {
  const [users, setUsers] = useState(new Array<User>(0))
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [user, setUser] = useState(new User())
  const [loading, setLoading] = useState(false)

  //pagination
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalUsers, setTotalUsers] = useState(0)

  useEffect(() => {
    getUsers(page, perPage, search)
  }, [page, perPage, search])

  const handlePageChange = (newPage: number) => setPage(newPage)

  const handlePerPage = (newPerPage: number) => setPerPage(newPerPage)

  const getUsers = async (page: number, perPage: number, search: string) => {
    setLoading(true)
    try {
      const data = await UserService.getUsers(page, perPage, search)
      const usersData = data.users.map((item: User) => new User(item))
      setUsers(usersData)
      setPerPage(+perPage)
      setPage(+data.pagination.current_page)
      setTotalUsers(+data.pagination.total_elements)
    } catch (e) {
      console.log('Error getting Users:: ', e)
      //if (e.error) notify('error', e.error)
    }
    setLoading(false)
  }

  const handleEdit = async (id: number) => {
    try {
      const data = await UserService.getUser(id)
      setUser(data)
      setShowModal(true)
    } catch (e) {
      console.log('Error getting user: ', e)
      //if (e.error) notify('error', e.error)
    }
  }

  const handleDelete = async (id: number) => {
    SwAlert.fire({
      title: 'Eliminar usuario',
      text: '¿Está seguro de eliminar este usuario?',
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const message = await UserService.deleteUser(id)
          SwAlert.fire('Operación exitosa!', message, 'success')
          getUsers(page, perPage, search)
        } catch (e) {
          if (e.error) SwAlert.fire('Ocurrio un error!', e.error, 'error')
        }
      },
      allowOutsideClick: false,
    })
  }

  const handleSearch = (tag: string) => setSearch(tag)

  const save = async (user: User) => {
    try {
      const message = await UserService.saveUser(user)
      closeModal()
      SwAlert.fire('Operación exitosa!', message, 'success')
      getUsers(page, perPage, search)
    } catch (e) {
      console.log('Error save user: ', e)
      if (e.error) SwAlert.fire('Ocurrio un error!', e.error, 'error')
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setUser(new User())
  }

  const body = () => {
    return (
      <Fragment>
        {users.map((user, key) => {
          return (
            <tr key={key}>
              <td>{user.email}</td>
              <td>{user.fullName()}</td>
              <td>{user.role.name}</td>
              <td>
                <span className={`text-${user.blocked ? 'danger' : 'success'} d-inline-flex`}>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faCircle} size='sm' />
                  </i>
                  {user.blocked ? 'Bloqueado' : 'Activo'}
                </span>
              </td>
              <td>
                <div className='action'>
                  <OverlayTrigger overlay={<Tooltip id='edit-user'>Editar</Tooltip>}>
                    <span className='d-inline-block'>
                      <Button variant='outline-success' size='sm' className='mr-3' onClick={() => handleEdit(user.id)}>
                        <FontAwesomeIcon icon={faUserEdit} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id='delete-user'>Eliminar</Tooltip>}>
                    <span className='d-inline-block'>
                      <Button variant='outline-danger' size='sm' onClick={() => handleDelete(user.id)}>
                        <FontAwesomeIcon icon={faUserTimes} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <Row>
          <Col sm={6} className='table-toolbar-left'>
            <Button variant='primary' className='btn-icon' onClick={() => setShowModal(true)}>
              <span className='btn-icon-label'>
                <i className='mr-2'>
                  <FontAwesomeIcon icon={faUserPlus} />
                </i>
              </span>
              Agregar Usuario
            </Button>
          </Col>
          <Col sm={6} className='table-toolbar-right'>
            <UserSearch onSubmit={handleSearch} defaultSearch={search} />
          </Col>
        </Row>
        {loading && (
          <div className='text-center'>
            {/*<Loader type='TailSpin' color='#00BFFF' />*/}
            Loader
          </div>
        )}
        <CustomTable headers={UserHeaders} dataBody={body()} />
        <Pagination
          totalRecords={totalUsers}
          pageLimit={perPage}
          currentPage={page}
          changePage={handlePageChange}
          changePageLimit={handlePerPage}
        />

        <UserForm user={user} onSubmit={save} onShow={showModal} onClose={closeModal} />
      </div>
    </div>
  )
}
export default Users
