import React, { ReactElement, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import InfrastructureItem from 'models/infrastructure'
const Joi = require('joi')
import { ErrorMessage } from '@hookform/error-message'
import OptionsService from 'services/options_service'
import { Typeahead } from 'react-bootstrap-typeahead'
import Error from 'components/error'
import { Select2, Select2Option } from 'select2-react-component'

const infrastructureSchema = Joi.object({
  id: Joi.number().integer().min(0).optional(),
  building: Joi.string().empty('').max(1000).optional().messages({
    'string.max': 'No puede tener más de 1000 caracteres',
  }),
  spaces_number: Joi.number().integer().empty('').min(0).max(9999).optional().messages({
    'number.base': 'Debe ser un número',
    'number.min': 'No puede ser menor a 0',
    'number.max': 'No puede ser mayor a 9999',
    'number.integer': 'Debe ser un número entero',
  }),
  education_spaces: Joi.string().empty('').max(9999).optional().messages({
    'string.max': 'No puede tener más de 9999 caracteres',
  }),
  structure: Joi.string().empty('').max(1000).optional().messages({
    'string.max': 'No puede tener más de 1000 caracteres',
  }),
  no_e_e: Joi.number().integer().min(0).max(99999).optional().messages({
    'number.base': 'Debe ser un número',
    'number.min': 'No puede ser menor a 0',
    'number.max': 'No puede ser mayor a 99999',
    'number.integer': 'Debe ser un número entero',
  }),
  muros: Joi.string().empty('').max(128).optional().messages({
    'string.max': 'No puede tener más de 128 caracteres',
  }),
  techumbre: Joi.string().empty('').max(128).optional().messages({
    'string.max': 'No puede tener más de 128 caracteres',
  }),
  canceleria: Joi.string().empty('').max(128).optional().messages({
    'string.max': 'No puede tener más de 128 caracteres',
  }),
  createdBy: Joi.string().empty('').max(128).optional().messages({
    'string.max': 'No puede tener más de 128 caracteres',
  }),
  createdYear: Joi.number().empty('').integer().min(1800).max(2050).optional().messages({
    'number.base': 'Debe ser un año válido',
    'number.min': 'No puede ser menor a 1800',
    'number.max': 'No puede ser mayor a 2050',
    'number.integer': 'Debe ser un número entero',
  }),
  observations: Joi.string().empty('').max(1000).optional().messages({
    'string.max': 'No puede tener más de 1000 caracteres',
  }),
  necesities: Joi.string().empty('').max(1000).optional().messages({
    'string.max': 'No puede tener más de 1000 caracteres',
  }),
})

const wrapperSchema = Joi.object({
  item: infrastructureSchema,
})

type UserProps = {
  infrastructureItem: InfrastructureItem
  onSubmit: (infrastructure: InfrastructureItem) => void
  onShow: boolean
  onClose: () => void
}

const InfrastructureForm = ({ infrastructureItem, onSubmit, onShow, onClose }: UserProps): ReactElement => {
  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(wrapperSchema),
  })

  const [storedMuros, setStoredMuros] = useState<Array<Select2Option>>([])
  const [muros, setMuros] = useState<Array<Select2Option>>([])
  const [searchMuro, setSearchMuro] = useState<string>('')

  const [storedTechumbre, setStoredTechumbre] = useState<Array<Select2Option>>([])
  const [techumbre, setTechumbre] = useState<Array<Select2Option>>([])
  const [searchTechumbre, setSearchTechumbre] = useState<string>('')

  const [storedCanceleria, setStoredCanceleria] = useState<Array<Select2Option>>([])
  const [canceleria, setCanceleria] = useState<Array<Select2Option>>([])
  const [searchCanceleria, setSearchCanceleria] = useState<string>('')

  const [storedEducationSpacesOptions, setStoredEducationSpacesOptions] = useState<Array<Select2Option>>([])
  const [educationSpacesOptions, setEducationSpacesOptions] = useState<Array<Select2Option>>([])
  const [searchEducationSpace, setSearchEducationSpace] = useState<string>('')

  const [educationSpaces, setEducationSpaces] = useState<Array<string>>([])

  React.useEffect(() => {
    getOptions()
  }, [])

  const getOptions = () => {
    OptionsService.muros().then(muros => {
      const murosSelect = muros.map(el => {
        return { value: el, label: el }
      })
      setMuros(murosSelect)
      setStoredMuros(murosSelect)
    })

    OptionsService.techumbre().then(techumbre => {
      const techumbreSelect = techumbre.map(el => {
        return { value: el, label: el }
      })
      setTechumbre(techumbreSelect)
      setStoredTechumbre(techumbreSelect)
    })

    OptionsService.canceleria().then(canceleria => {
      const canceleriaSelect = canceleria.map(el => {
        return { value: el, label: el }
      })
      setCanceleria(canceleriaSelect)
      setStoredCanceleria(canceleriaSelect)
    })

    OptionsService.educationSpaces().then(educationSpaces => {
      const educationSpacesSelect = educationSpaces.map(el => {
        return { value: el, label: el }
      })
      setEducationSpacesOptions(educationSpacesSelect)
      setStoredEducationSpacesOptions(educationSpacesSelect)
    })
  }

  React.useEffect(() => {
    reset({
      item: infrastructureItem,
    })
    setValue('item.muros', infrastructureItem.muros ? infrastructureItem.muros : '')
    setValue('item.techumbre', infrastructureItem.techumbre ? infrastructureItem.techumbre : '')
    setValue('item.canceleria', infrastructureItem.canceleria ? infrastructureItem.canceleria : '')

    if (infrastructureItem.muros) {
      const indexMuro = storedMuros.findIndex(
        option => option.label.toUpperCase() == infrastructureItem.muros.toUpperCase(),
      )
      if (indexMuro < 0) {
        setStoredMuros([...storedMuros, { label: infrastructureItem.muros, value: infrastructureItem.muros }])
        setMuros([...storedMuros, { label: infrastructureItem.muros, value: infrastructureItem.muros }])
      }
    }

    if (infrastructureItem.techumbre) {
      const indexTechumbre = storedTechumbre.findIndex(
        option => option.label.toUpperCase() == infrastructureItem.techumbre.toUpperCase(),
      )
      if (indexTechumbre < 0) {
        setStoredTechumbre([
          ...storedTechumbre,
          { label: infrastructureItem.techumbre, value: infrastructureItem.techumbre },
        ])
        setTechumbre([...storedTechumbre, { label: infrastructureItem.techumbre, value: infrastructureItem.techumbre }])
      }
    }

    if (infrastructureItem.canceleria) {
      const indexCanceleria = storedCanceleria.findIndex(
        option => option.label.toUpperCase() == infrastructureItem.canceleria.toUpperCase(),
      )
      if (indexCanceleria < 0) {
        setStoredCanceleria([
          ...storedCanceleria,
          { label: infrastructureItem.canceleria, value: infrastructureItem.canceleria },
        ])
        setCanceleria([
          ...storedCanceleria,
          { label: infrastructureItem.canceleria, value: infrastructureItem.canceleria },
        ])
      }
    }

    setEducationSpaces(
      infrastructureItem.education_spaces ? infrastructureItem.education_spaces.split(/[,|Y|y|\n]+/) : [],
    )

    infrastructureItem.spaces_number = infrastructureItem.spaces_number ? infrastructureItem.spaces_number : 0
    infrastructureItem.structure = infrastructureItem.structure ? infrastructureItem.structure : ''
    infrastructureItem.no_e_e = infrastructureItem.no_e_e ? infrastructureItem.no_e_e : 0
    infrastructureItem.createdBy = infrastructureItem.createdBy ? infrastructureItem.createdBy : ''
    infrastructureItem.building = infrastructureItem.building ? infrastructureItem.building : ''
  }, [infrastructureItem])

  React.useEffect(() => {
    if (educationSpaces) {
      let education_spaces = educationSpaces.join(' , ')
      const index = education_spaces.lastIndexOf(',')
      if (index > 0)
        education_spaces = education_spaces.substring(0, index) + 'Y' + education_spaces.substring(index + 1)
      setValue('item.education_spaces', education_spaces)
    }
  }, [educationSpaces])

  type FormValues = {
    item: InfrastructureItem
  }

  const onFormSubmit = (values: FormValues) => {
    onSubmit(values.item)
  }

  return (
    <Modal size='lg' show={onShow} onHide={onClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Edificio</Form.Label>
                <Form.Control defaultValue={infrastructureItem.building} {...register('item.building')} />
                <ErrorMessage
                  errors={errors}
                  name='item.building'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Núm. de espacios</Form.Label>
                <Form.Control
                  type='number'
                  defaultValue={infrastructureItem.spaces_number}
                  {...register('item.spaces_number')}
                />
                <ErrorMessage
                  errors={errors}
                  name='item.spaces_number'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className={errors.motherLastName ? 'has-error' : ''}>
                <Form.Label>Estructura</Form.Label>
                <Form.Control defaultValue={infrastructureItem.structure} {...register('item.structure')} />
                <ErrorMessage
                  errors={errors}
                  name='item.structure'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group className={errors.email ? 'has-error' : ''}>
                <Form.Label>No. E.E.</Form.Label>
                <Form.Control type='number' defaultValue={infrastructureItem.no_e_e} {...register('item.no_e_e')} />
                <ErrorMessage
                  errors={errors}
                  name='item.no_e_e'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Espacios Educativos</Form.Label>
                <div>
                  {educationSpaces.map((educationSpace, index) => {
                    return (
                      <Button
                        key={educationSpace}
                        variant='info'
                        className='btn m-1'
                        onClick={() => {
                          educationSpaces.splice(index, 1)
                          setEducationSpaces([...educationSpaces])
                        }}
                      >
                        {educationSpace}
                        <i className='ml-3'>
                          <FontAwesomeIcon icon={faTimes} />
                        </i>
                      </Button>
                    )
                  })}
                </div>
                <Select2
                  minCountForSearch={0}
                  search={text => {
                    const ops = storedEducationSpacesOptions.filter(option =>
                      option.label.toUpperCase().includes(text.toUpperCase()),
                    )

                    setEducationSpacesOptions(ops)
                    setSearchEducationSpace(text.trim())
                  }}
                  keydown={keyEvent => {
                    if (keyEvent.key.toLocaleLowerCase() === 'enter') {
                      const stored = educationSpaces.find(
                        educationSpace => educationSpace.trim() == searchEducationSpace.toString().trim(),
                      )
                      if (!stored) setEducationSpaces([...educationSpaces, searchEducationSpace])
                    }
                  }}
                  customSearchEnabled={true}
                  data={educationSpacesOptions}
                  value={[]}
                  update={val => {
                    const stored = educationSpaces.find(
                      educationSpaces => educationSpaces.trim() == val.toString().trim(),
                    )
                    if (!stored) setEducationSpaces([...educationSpaces, val.toString()])
                  }}
                ></Select2>
                <ErrorMessage
                  errors={errors}
                  name='item.education_spaces'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group className={errors.muros ? 'has-error' : ''}>
                <Form.Label>Muros</Form.Label>
                <Select2
                  minCountForSearch={0}
                  search={text => {
                    const ops = storedMuros.filter(option => option.label.toUpperCase().includes(text.toUpperCase()))
                    setMuros(ops)
                    setSearchMuro(text.trim())
                  }}
                  keydown={keyEvent => {
                    if (keyEvent.key.toLocaleLowerCase() === 'enter') {
                      const index = storedMuros.findIndex(
                        option => option.label.toUpperCase() == searchMuro.toUpperCase(),
                      )
                      if (index < 0) {
                        setStoredMuros([...storedMuros, { label: searchMuro, value: searchMuro }])
                        setMuros([...storedMuros, { label: searchMuro, value: searchMuro }])
                      }
                      setValue('item.muros', searchMuro.trim())
                    }
                  }}
                  customSearchEnabled={true}
                  data={muros}
                  value={getValues('item.muros')}
                  update={val => {
                    setValue('item.muros', val)
                  }}
                ></Select2>
                <ErrorMessage errors={errors} name='item.muros' render={({ message }) => <Error message={message} />} />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className={errors.techumbre ? 'has-error' : ''}>
                <Form.Label>Techumbre</Form.Label>
                <Select2
                  minCountForSearch={0}
                  search={text => {
                    const ops = storedTechumbre.filter(option =>
                      option.label.toUpperCase().includes(text.toUpperCase()),
                    )
                    setTechumbre(ops)
                    setSearchTechumbre(text.trim())
                  }}
                  keydown={keyEvent => {
                    if (keyEvent.key.toLocaleLowerCase() === 'enter') {
                      const index = storedTechumbre.findIndex(
                        option => option.label.toUpperCase() == searchTechumbre.toUpperCase(),
                      )
                      if (index < 0) {
                        setStoredTechumbre([...storedTechumbre, { label: searchTechumbre, value: searchTechumbre }])
                        setTechumbre([...storedTechumbre, { label: searchTechumbre, value: searchTechumbre }])
                      }
                      setValue('item.techumbre', searchTechumbre.trim())
                    }
                  }}
                  customSearchEnabled={true}
                  data={techumbre}
                  value={getValues('item.techumbre')}
                  update={val => {
                    setValue('item.techumbre', val)
                  }}
                ></Select2>
                <ErrorMessage
                  errors={errors}
                  name='item.techumbre'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className={errors.canceleria ? 'has-error' : ''}>
                <Form.Label>Cancelería</Form.Label>
                <Select2
                  minCountForSearch={0}
                  search={text => {
                    const ops = storedCanceleria.filter(option =>
                      option.label.toUpperCase().includes(text.toUpperCase()),
                    )
                    setCanceleria(ops)
                    setSearchCanceleria(text.trim())
                  }}
                  keydown={keyEvent => {
                    if (keyEvent.key.toLocaleLowerCase() === 'enter') {
                      const index = storedCanceleria.findIndex(
                        option => option.label.toUpperCase() == searchCanceleria.toUpperCase(),
                      )
                      if (index < 0) {
                        setStoredCanceleria([...storedCanceleria, { label: searchCanceleria, value: searchCanceleria }])
                        setCanceleria([...storedCanceleria, { label: searchCanceleria, value: searchCanceleria }])
                      }
                      setValue('item.canceleria', searchCanceleria.trim())
                    }
                  }}
                  customSearchEnabled={true}
                  data={canceleria}
                  value={getValues('item.canceleria')}
                  update={val => {
                    setValue('item.canceleria', val)
                  }}
                ></Select2>
                <ErrorMessage
                  errors={errors}
                  name='item.canceleria'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Form.Group className={errors.createdBy ? 'has-error' : ''}>
                <Form.Label>Construido por:</Form.Label>
                <Form.Control defaultValue={infrastructureItem.createdBy} {...register(`item.createdBy` as const)} />
                <ErrorMessage
                  errors={errors}
                  name='item.createdBy'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className={errors.createdYear ? 'has-error' : ''}>
                <Form.Label>Año de Construcción</Form.Label>
                <Form.Control
                  defaultValue={infrastructureItem.createdYear}
                  {...register(`item.createdYear` as const)}
                />
                <ErrorMessage
                  errors={errors}
                  name='item.createdYear'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group className={errors.observations ? 'has-error' : ''}>
                <Form.Label>Observaciones</Form.Label>
                <Form.Control
                  defaultValue={infrastructureItem.observations}
                  {...register(`item.observations` as const)}
                />
                <ErrorMessage
                  errors={errors}
                  name='`item.observations'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group className={errors.necesities ? 'has-error' : ''}>
                <Form.Label>Necesidades</Form.Label>
                <Form.Control
                  as='textarea'
                  defaultValue={infrastructureItem.necesities}
                  {...register(`item.necesities` as const)}
                />
                <ErrorMessage
                  errors={errors}
                  name='`item.necesities'
                  render={({ message }) => <Error message={message} />}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            Cerrar
          </Button>
          <Button variant='success' className='btn' type='submit'>
            <i className='mr-3'>
              <FontAwesomeIcon icon={faSave} />
            </i>
            Guardar cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

InfrastructureForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default InfrastructureForm
