import SchoolVisit from 'models/school_visit'
import { ISchoolVisitItem, ISchoolVisitsData } from 'interfaces/school_visit'
import Api from 'api'
const resource = '/school_visits'

export default class SchoolVisitService {
  static async getVisits(
    page: number,
    size: number,
    search: string,
    filter: number,
    userId?: number | null,
  ): Promise<ISchoolVisitsData> {
    let url = `${resource}?page=${page}&page_size=${size}`
    if (userId) url += `&user_id=${userId}`
    if (search.trim().length > 0) url += '&search=' + search
    if (filter > -1) url += `&filter=${filter}`
    const { data } = await Api.get(url)
    return data
  }

  static async getSchoolVisit(schoolVisitId: number): Promise<SchoolVisit> {
    const { data } = await Api.get(`${resource}/${schoolVisitId}`)
    return new SchoolVisit(data.schoolVisit)
  }

  static async getSchoolVisits(schoolId: number): Promise<Array<ISchoolVisitItem>> {
    const { data } = await Api.get(`${resource}/all/school/${schoolId}`)
    return data
  }

  static async saveShoolVisit(schoolVisit: SchoolVisit, userId: number): Promise<string> {
    const json = schoolVisit.asJSON()
    json.userId = userId
    const { data } = await Api.post(resource, json)
    return data.message
  }

  static async updateSchoolVisit(schoolVisit: SchoolVisit): Promise<string> {
    await Api.put(`${resource}/${schoolVisit.id}`, schoolVisit.asJSON())
    return 'Visita actualizada con éxito.'
  }

  static async confirm(schoolVisitId: number): Promise<string> {
    const { data } = await Api.post(`${resource}/confirm_school_visit`, { schoolVisitId })
    return data.message
  }

  static async deConfirm(schoolVisitId: number): Promise<string> {
    const { data } = await Api.post(`${resource}/de_confirm_school_visit`, { schoolVisitId })
    return data.message
  }

  static async reAsign(schoolVisitId: number, userId: number): Promise<string> {
    const { data } = await Api.post(`${resource}/re_asign`, { schoolVisitId, userId })
    return data.message
  }

  static async updateVisitedAt(schoolVisitId: number, visitedAt: string): Promise<string> {
    const { data } = await Api.post(`${resource}/visited_at`, { schoolVisitId, visitedAt })
    return data.message
  }

  static async deleteSchoolVisit(schoolVisitId: number): Promise<string> {
    const { data } = await Api.delete(`${resource}/${schoolVisitId}`)
    return data.message
  }
}
