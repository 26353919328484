import React, { useEffect, useState, ReactElement } from 'react'
//import { useParams } from 'react-router-dom'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'

type CustomMarker = {
  lat: string
  lng: string
  description?: string
}
type MapProps = {
  markers: Array<CustomMarker>
  isOpen: null | number
  handleInfoClose: () => void
  handleToggleOpen: (markerId: number) => void
}
const CustomSkinMap = withScriptjs(
  withGoogleMap(({ markers, isOpen, handleInfoClose, handleToggleOpen }: MapProps) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 17.059013, lng: -96.727092 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: 'water',
            stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#808080' }, { lightness: 54 }],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ece2d9' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ccdca1' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#767676' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#ffffff' }],
          },
          { featureType: 'poi', stylers: [{ visibility: 'off' }] },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry.fill',
            stylers: [{ visibility: 'on' }, { color: '#b8cb93' }],
          },
          { featureType: 'poi.park', stylers: [{ visibility: 'off' }] },
          {
            featureType: 'poi.sports_complex',
            stylers: [{ visibility: 'off' }],
          },
          { featureType: 'poi.medical', stylers: [{ visibility: 'off' }] },
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }],
          },
        ],
      }}
    >
      {markers.map((answer, index) => {
        return (
          <Marker
            key={index}
            position={{ lat: parseFloat(answer.lat), lng: parseFloat(answer.lng) }}
            title={answer.description}
            onClick={() => handleToggleOpen(index)}
          >
            {isOpen === index && (
              <InfoWindow onCloseClick={handleInfoClose}>
                <span>{answer.description}</span>
              </InfoWindow>
            )}
          </Marker>
        )
      })}
    </GoogleMap>
  )),
)

const Map = (): ReactElement => {
  const [markers, setMarkers] = useState(new Array<CustomMarker>(0))
  const [marker, setMarker] = useState<null | number>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getMarkers()
  }, [])
  const handleToggleOpen = (markerId: number) => setMarker(markerId)

  const handleInfoClose = () => setMarker(null)

  async function getMarkers() {
    setLoading(true)
    try {
      setMarkers([{ lat: '17.059013', lng: '-96.727092', description: 'Escuela Nueva' }])
      //const response = await AnswersService.getMarkers(surveyId)
      //const answersMarkers = response.data.map(answer => new AnswerLocation(answer))
    } catch (e) {
      console.log('Error in map: ', e)
    }
    setLoading(false)
  }

  return (
    <div>
      {loading && <p>cargando mapa...</p>}
      <CustomSkinMap
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBJF9aPOMecnwYihky14llV9a7FiNPQLx0'
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        markers={markers}
        handleToggleOpen={handleToggleOpen}
        handleInfoClose={handleInfoClose}
        isOpen={marker}
      />
    </div>
  )
}

export default Map
