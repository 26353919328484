import React, { useState, useEffect, ReactElement, Fragment, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Button, Row, Col } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircle,
  faEdit,
  faCalendarPlus,
  faTrash,
  faCheckSquare,
  faEye,
  faWindowClose,
  faUserEdit,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import SwAlert from 'components/alert/swal'
import School from 'models/school'
import SchoolVisit, { SchoolVisitHeaders, SchoolVisitStatus } from 'models/school_visit'
import { ISchoolVisitItem } from 'interfaces/school_visit'
import SchoolVisitService from 'services/school_visit_service'
import SchoolVisitForm from './form'
import SearchVisit from 'views/users/search'
import ReAsignForm from 'views/visits/re_asign_form'
import SimpleCard from 'components/cards/simple_card'
import CustomTable from 'components/table/custom_table'
import Pagination from 'components/pagination/pagination'
import UserService from 'services/user_service'
import { Select2Option } from 'select2-component/dist/index'
import { AuthContext, IAuthContext } from 'context/useAuth'

const SchoolVisits = (): ReactElement => {
  const history = useHistory()
  const [schools, setSchools] = useState(new Array<ISchoolVisitItem>(0))
  const [showModal, setShowModal] = useState(false)
  const [showModalReAsign, setShowModalReAsign] = useState(false)
  const [loading, setLoading] = useState(false)
  //pagination
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalSchools, setTotalSchools] = useState(0)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState(-1)
  const [schoolVisit, setSchoolVisit] = useState<ISchoolVisitItem | null>(null)

  const defaultUser = { value: 0, label: 'Todos' }

  const [userId, setUser] = useState(0)
  const [users, setUsers] = useState<Array<Select2Option>>([defaultUser])

  const selectStatus = [{ value: -1, label: 'Todos', color: 'white' }, ...SchoolVisitStatus]

  const { currentUser } = useContext(AuthContext) as IAuthContext

  useEffect(() => {
    UserService.getUsers(1, 2000, '').then(({ users }) => {
      const data: Array<Select2Option> = [{ value: 0, label: 'Todos' }]
      users.forEach(user => {
        if (user.id) {
          data.push({
            value: user.id,
            label: `${user.firstName} ${user.lastName} ${user.motherLastName} ${user.email}`,
          })
        }
      })

      setUsers(data)
    })
  }, [])

  useEffect(() => {
    getSchoolVisits()
  }, [page, perPage, search, filter, userId])

  const getSchoolVisits = async () => {
    setLoading(true)
    try {
      const { schoolVisits, pagination } = await SchoolVisitService.getVisits(
        page,
        perPage,
        search,
        filter,
        userId > 0 ? userId : null,
      )
      setSchools(schoolVisits)
      setPage(+pagination.current_page)
      setTotalSchools(+pagination.total_elements)
    } catch (e) {
      console.log('error: ', e)
    }
    setLoading(false)
  }

  const handlePageChange = (newPage: number) => setPage(newPage)

  const handlePerPage = (newPerPage: number) => setPerPage(newPerPage)

  const hanldeSaveVisit = async (schoolId: number, userId: number) => {
    try {
      const school = new School({ id: schoolId })
      const message = await SchoolVisitService.saveShoolVisit(new SchoolVisit({ school }), userId)
      setShowModal(false)
      SwAlert.fire('Operación exitosa!', message, 'success')
      getSchoolVisits()
    } catch (e) {
      console.log('error create visit: ', e)
    }
  }

  const handleReAsign = async (schoolVisitId: number, userId: number) => {
    try {
      const message = await SchoolVisitService.reAsign(schoolVisitId, userId)
      setShowModalReAsign(false)
      SwAlert.fire('Operación exitosa!', message, 'success')
      getSchoolVisits()
    } catch (e) {
      console.log('error reasign visit: ', e)
    }
  }

  const handleConfirmVisit = (schoolVisitId: number) => {
    SwAlert.fire({
      title: 'Confirmar visita',
      text: '¿Está seguro de confirmar la visita?',
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const message = await SchoolVisitService.confirm(schoolVisitId)
          SwAlert.fire('Operación exitosa!', message, 'success')
          //getUsers(page, perPage, search)
          getSchoolVisits()
        } catch (e) {
          if (e.error) SwAlert.fire('Ocurrio un error!', e.error, 'error')
        }
      },
      allowOutsideClick: false,
    })
  }

  const showModalReasign = (school: ISchoolVisitItem) => {
    setSchoolVisit(school)
    setShowModalReAsign(true)
  }

  const handleDeConfirmVisit = (schoolVisitId: number) => {
    SwAlert.fire({
      title: 'Desconfirmar visita',
      text: '¿Está seguro de desconfirmar la visita?',
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const message = await SchoolVisitService.deConfirm(schoolVisitId)
          SwAlert.fire('Operación exitosa!', message, 'success')
          //getUsers(page, perPage, search)
          getSchoolVisits()
        } catch (e) {
          if (e.error) SwAlert.fire('Ocurrio un error!', e.error, 'error')
        }
      },
      allowOutsideClick: false,
    })
  }

  const handleDeleteVisit = (schoolVisitId: number) => {
    SwAlert.fire({
      title: 'Eliminar Visita',
      text: '¿Está seguro de eliminar esta Visita?',
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const message = await SchoolVisitService.deleteSchoolVisit(schoolVisitId)
          SwAlert.fire('Operación exitosa!', message, 'success')
          //getUsers(page, perPage, search)
          getSchoolVisits()
        } catch (e) {
          if (e.error) SwAlert.fire('Ocurrio un error!', e.error, 'error')
        }
      },
      allowOutsideClick: false,
    })
  }

  const handleSearch = (tag: string) => setSearch(tag)

  const dataBody = () => {
    return (
      <Fragment>
        {schools.map((school, key) => {
          return (
            <tr key={key}>
              <td>{school.clave}</td>
              <td>{school.description}</td>
              <td>{school.locality}</td>
              <td>{school.municipality}</td>
              <td>{school.district}</td>
              <td>{school.region}</td>
              <td>{school.educationLevel}</td>
              <td>{`${school.userFirstName?.toUpperCase()} ${school.userLastName?.toUpperCase()} ${school.userMotherLastName?.toUpperCase()}`}</td>
              <td>
                {school.status !== undefined && (
                  <span className={`text-${SchoolVisitStatus[school.status].color} d-inline-flex`}>
                    <i className='mr-2'>
                      <FontAwesomeIcon icon={faCircle} size='sm' />
                    </i>
                    {SchoolVisitStatus[school.status].label}
                  </span>
                )}
              </td>
              <td>
                <div className='text-center'>
                  {school.status === 2 && (
                    <OverlayTrigger overlay={<Tooltip id='edit-visit'>Detalle</Tooltip>}>
                      <Button variant='outline-info' onClick={() => history.push(`/school_visits/${school.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {school.status === 2 && currentUser?.isAdmin() && (
                    <OverlayTrigger overlay={<Tooltip id='edit-visit'>Desconfirmar</Tooltip>}>
                      <Button variant='outline-warning' onClick={() => handleDeConfirmVisit(school.id)}>
                        <FontAwesomeIcon icon={faWindowClose} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {school.status !== 2 && (
                    <OverlayTrigger overlay={<Tooltip id='edit-visit'>Editar</Tooltip>}>
                      <Button variant='outline-info' onClick={() => history.push(`/school_visits/${school.id}`)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {(school.status === 0 || school.status === 1) && currentUser?.isAdmin() && (
                    <OverlayTrigger overlay={<Tooltip id='delete-visit'>Eliminar</Tooltip>}>
                      <Button variant='outline-danger' onClick={() => handleDeleteVisit(school.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {school.status === 1 && (
                    <OverlayTrigger overlay={<Tooltip id='delete-visit'>Confirmar</Tooltip>}>
                      <Button variant='outline-success' onClick={() => handleConfirmVisit(school.id)}>
                        <FontAwesomeIcon icon={faCheckSquare} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {school.status !== 2 && currentUser?.isAdmin() && (
                    <OverlayTrigger overlay={<Tooltip id='edit-visit'>Reasignar</Tooltip>}>
                      <Button variant='outline-secondary' onClick={() => showModalReasign(school)}>
                        <FontAwesomeIcon icon={faUser} />
                      </Button>
                    </OverlayTrigger>
                  )}
                </div>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  return (
    <Row>
      <Col lg={12}>
        <SimpleCard title='Lista de Visitas'>
          <Row>
            <Col sm={12} className='text-right'>
              <Button className='btn-icon' onClick={() => setShowModal(true)}>
                <span className='btn-icon-label'>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faCalendarPlus} />
                  </i>
                </span>
                Agregar Visita
              </Button>
            </Col>
            {currentUser?.isAdmin() && (
              <Col sm={4}>
                <small>Usuarios:</small>
                <Select2 data={users} value={userId} update={value => setUser(+value)}></Select2>
              </Col>
            )}
            <Col sm={4}>
              <small>Estatus:</small>
              <Select2 data={selectStatus} value={filter} update={value => setFilter(+value)} />
            </Col>
            <Col sm={4}>
              <small>Búsqueda:</small>
              <SearchVisit onSubmit={handleSearch} defaultSearch={search} />
            </Col>
            <Col sm={12} className='mt-4'>
              {loading && (
                <div className='text-center'>
                  {/*<Loader type='TailSpin' color='#00BFFF' />*/}
                  Loader
                </div>
              )}
              <CustomTable headers={SchoolVisitHeaders} dataBody={dataBody()} />
              <Pagination
                totalRecords={totalSchools}
                pageLimit={perPage}
                currentPage={page}
                changePage={handlePageChange}
                changePageLimit={handlePerPage}
              />
            </Col>
          </Row>
        </SimpleCard>
      </Col>
      <SchoolVisitForm
        onSubmit={hanldeSaveVisit}
        onShow={showModal}
        onClose={() => setShowModal(false)}
        userIdP={userId}
        usersP={users}
      />
      <ReAsignForm
        onSubmit={handleReAsign}
        onShow={showModalReAsign}
        onClose={() => setShowModalReAsign(false)}
        schoolVisit={schoolVisit}
      />
    </Row>
  )
}

export default SchoolVisits
