import { ISchoolService } from 'interfaces/school_services'
export default class SchoolService {
  id
  drainageNetwork
  telephoneLine
  drinkingWater
  electricPower
  internet
  others
  constructor(properties: ISchoolService = {}) {
    this.id = properties.id || 0
    this.drainageNetwork = properties.drainageNetwork || false
    this.telephoneLine = properties.telephoneLine || false
    this.drinkingWater = properties.drinkingWater || false
    this.electricPower = properties.electricPower || false
    this.internet = properties.internet || false
    this.others = properties.others || ''
  }
}
