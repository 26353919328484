import React, { useEffect, ReactElement } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Routes from 'config/routes'
import PrivateRoute from 'components/routes/private_route'
import SchoolProvider from 'context/school_context'

const Main = (): ReactElement => {
  useEffect(() => {
    document.body.classList.remove('bg-primary')
  }, [])
  return (
    <div className='main-content'>
      <div className='page-content'>
        <div className='container-fluid'>
          <SchoolProvider>
            <Switch>
              {Routes.map((route, key) => {
                if (route.isPrivate) {
                  return <PrivateRoute key={key} path={route.path} exact component={route.component} />
                }
                return null
              })}
              <Redirect to='/404' />
            </Switch>
          </SchoolProvider>
        </div>
      </div>
    </div>
  )
}

export default Main
