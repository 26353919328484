import React, { ReactElement } from 'react'

const Footer = (): ReactElement => {
  return (
    <footer className='footer'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            © {new Date().getFullYear()} IEEPO
            <span className='d-none d-sm-inline-block'>
              - Powered By
              <i className='mdi mdi-heart text-danger'></i> Karimnot Inc
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
