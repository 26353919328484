import { ISoilType } from 'interfaces/soil_type'
export default class SoilType {
  rockySoil
  sandySoil
  claySoil
  constructor(properties: ISoilType = {}) {
    this.rockySoil = properties.rockySoil || false
    this.sandySoil = properties.sandySoil || false
    this.claySoil = properties.claySoil || false
  }
}
