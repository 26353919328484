import Api from 'api'
import EducationLevel from 'models/education_level'
const resource = '/education_level'

export default class EducationLevelService {
  static async getAll(): Promise<Array<EducationLevel>> {
    const { data } = await Api.get(resource)
    return data.propertyTypes
  }
}
