import { ILocality } from 'interfaces/locality'

export default class Locality {
  id
  description
  constructor(properties: ILocality = {}) {
    this.id = properties.id || 0
    this.description = properties.description || ''
  }
}
