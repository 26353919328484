interface IImage {
  id?: number
  image_type?: number
  image_url?: string
  description?: string
  lat?: string
  lng?: string
  isMicrolocation?: boolean
  isMacrolocation?: boolean
}
export default class SchoolVisitImage {
  id
  image_type
  image_url
  description
  lat
  lng
  isMicrolocation
  isMacrolocation
  constructor(properties: IImage = {}) {
    this.id = properties.id || -1
    this.image_type = properties.image_type
    this.image_url = properties.image_url || ''
    this.description = properties.description || ''
    this.lat = properties.lat || ''
    this.lng = properties.lng || ''
    this.isMacrolocation = properties.isMacrolocation || false
    this.isMicrolocation = properties.isMicrolocation || false
  }
}
