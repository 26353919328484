import React, { ReactElement, Fragment } from 'react'
import error_image from 'assets/images/error.png'

const NotFound = (): ReactElement => {
  return (
    <Fragment>
      <div className='home-btn d-none d-sm-block'>
        <a href='/' className='text-white'>
          <i className='fas fa-home h2'></i> ini
        </a>
      </div>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8 col-lg-6 col-xl-5'>
              <div className='card shadow-none overflow-hidden'>
                <div className='card-body'>
                  <div className='text-center'>
                    <h1 className='display-2 font-weight-normal mt-0'>404!</h1>
                    <h4>Sorry, page not found</h4>
                    <p className='text-muted mb-5'>If several languages coalesce, the grammar peson</p>
                  </div>
                </div>
                <div>
                  <img src={error_image} alt='not_found_image' className='img-fluid mx-auto d-block' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default NotFound
