import Api from 'api'
const resource = '/property_types'

type PropertyType = {
  id: number
  name: string
}
type PropertyTypeSelect = {
  propertyTypes: Array<{ value: number; label: string }>
}
export default class PropertyTypeService {
  static async getAll(): Promise<PropertyTypeSelect> {
    const { data } = await Api.get(resource)
    const propertyTypes = data.propertyTypes.map((item: PropertyType) => {
      return { value: item.id, label: item.name }
    })
    return { propertyTypes }
  }
}
