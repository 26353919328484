import { IStringOption } from 'interfaces/string_option'
export default class StringOption {
  id
  option
  type
  constructor(properties: IStringOption = {}) {
    this.id = properties.id || 0
    this.option = properties.option || ''
    this.type = properties.type || ''
  }
}
