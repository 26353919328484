import Joi from 'joi'
const msg = { 'string.empty': 'Este campo es obligatorio.' }

const attributes_create = {
  firstName: Joi.string()
    .required()
    .messages({ ...msg }),
  lastName: Joi.string()
    .required()
    .messages({ ...msg }),
  major: Joi.string()
    .required()
    .messages({ ...msg }),
  motherLastName: Joi.string().empty('').default(''),
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required()
    .messages({
      ...msg,
      'string.email': 'Correo electrónico inválido',
    }),
  password: Joi.string()
    .min(6)
    .max(140)
    .messages({
      ...msg,
      'string.min': 'La contraseña debe contener mínimo 6 caracteres.',
      'string.max': 'La contraseña debe contener máximo 140 caracteres.',
    }),
  blocked: Joi.boolean(),
  role: Joi.number(),
}

const attributes_edit = {
  ...attributes_create,
  password: Joi.string().min(6).max(140).empty('').messages({
    'string.min': 'La contraseña debe contener mínimo 6 caracteres.',
    'string.max': 'La contraseña debe contener máximo 140 caracteres.',
  }),
}
export const schema_create = Joi.object(attributes_create)
export const schema_edit = Joi.object(attributes_edit)
