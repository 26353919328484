import Api from 'api'
import SchoolVisitImage from 'models/school_visit_image'

const prefix = '/images'
import env from 'react-dotenv'

export default class ImagesService {
  static async get(image: string) {
    return (await Api.get(`${prefix}?image=${image}`)).data
  }

  static async create(image: any) {
    return (await Api.post(`${prefix}`, image)).data.schoolVisitImage
  }

  static async update(id: number, image: any): Promise<SchoolVisitImage> {
    return (await Api.put(`${prefix}/${id}`, image)).data.schoolVisitImage
  }

  static async remove(id: number) {
    return await Api.delete(`${prefix}/${id}`)
  }

  static getUrl(image: string | undefined) {
    return `${env.API_IEEPO_URL}${prefix}?image=${image}`
  }
}
