import React, { ReactNode, ReactElement } from 'react'
type CustomTableProps = {
  dataBody: ReactNode
  headers: Array<string>
}

const CustomTable = ({ dataBody, headers }: CustomTableProps): ReactElement => {
  return (
    <div className='table-responsive mb-0'>
      <table className='table'>
        <thead className='thead-light'>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{dataBody}</tbody>
      </table>
    </div>
  )
}

export default CustomTable
