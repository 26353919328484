import React, { useEffect, ReactElement } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthProvider from 'context/useAuth'
import BaseLayout from 'views/layouts/baselayout'
import ReportProvider from 'context/report_context'

const App = (): ReactElement => {
  useEffect(() => {
    document.body.setAttribute('data-layout', 'horizontal')
  }, [])

  return (
    <Router>
      <AuthProvider>
        <ReportProvider>
          <BaseLayout />
        </ReportProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
