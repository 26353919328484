import React, { useState, useEffect, ReactElement, Fragment } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { useParams, useHistory } from 'react-router-dom'
import { Tab, Nav, Row, Col, Button, Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactDOM from 'react-dom'
import {
  faCubes,
  faImages,
  faChartBar,
  faMap,
  faCalendarCheck,
  faArrowLeft,
  faFile,
} from '@fortawesome/free-solid-svg-icons'
import SwAlert from 'components/alert/swal'
import SchoolVisit from 'models/school_visit'
import { ISchoolVisitProperties } from 'interfaces/school_visit'
import SchoolVisitService from 'services/school_visit_service'
import EstadisticControl from './estadistic_control'
import InfrastructureList from './infrastructure_list'
import MapImages from './map_images'
import PhotoImages from './photo_images'
import { DocumentVisit } from './document'

type DetailParams = {
  school_visit_id: string
}

const Detail = (): ReactElement => {
  const history = useHistory()
  const { school_visit_id } = useParams<DetailParams>()
  const [visitedAt, setVisitedAt] = useState('')
  const [schoolVisit, setSchoolVisit] = useState<null | SchoolVisit>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleGetSchoolVisit(+school_visit_id)
  }, [school_visit_id])

  const handleGetSchoolVisit = async (schoolVisitId: number) => {
    try {
      setLoading(true)
      const data = await SchoolVisitService.getSchoolVisit(schoolVisitId)
      data.infrastructures.sort((a, b) => {
        if (a.building < b.building) return -1
        if (a.building > b.building) return 1
        return 0
      })
      setVisitedAt(data.visitedAt)
      setSchoolVisit(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateSchoolVisit = async (schoolVisitData: ISchoolVisitProperties) => {
    try {
      const schoolUpdate = schoolVisit
      if (schoolUpdate) {
        if (schoolVisitData.terrainDescription) schoolUpdate.terrainDescription = schoolVisitData.terrainDescription
        if (schoolVisitData.schoolShifts) schoolUpdate.schoolShifts = schoolVisitData.schoolShifts
        if (schoolVisitData.exteriorWorks) schoolUpdate.exteriorWorks = schoolVisitData.exteriorWorks
        if (schoolVisitData.soilType) schoolUpdate.soilType = schoolVisitData.soilType
        if (schoolVisitData.schoolService) schoolUpdate.schoolService = schoolVisitData.schoolService
        if (schoolVisitData.infrastructures) schoolUpdate.infrastructures = schoolVisitData.infrastructures
        if (schoolVisitData.communicationChannels)
          schoolUpdate.communicationChannels = schoolVisitData.communicationChannels
        if (schoolVisitData.existingTechnologies)
          schoolUpdate.existingTechnologies = schoolVisitData.existingTechnologies

        schoolUpdate.schoolVisitImages = []
        const message = await SchoolVisitService.updateSchoolVisit(schoolUpdate)
        console.log('messages::: ', message)
        SwAlert.fire('Operación exitosa!', 'Visita actualizada con éxito', 'success')
      }
    } catch (e) {
      SwAlert.fire('No pudimos guardar los cambios!', 'Error en el servidor', 'error')
      console.log('Error: ', e)
    }
  }

  const handleConfirmSchoolVisit = async () => {
    try {
      if (schoolVisit) {
        const message = await SchoolVisitService.confirm(schoolVisit.id)
        SwAlert.fire('Operación exitosa!', message, 'success')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleVisitedAtChange = (e: React.FormEvent<HTMLInputElement>) => {
    const date = e.currentTarget.value
    setVisitedAt(date)

    if (schoolVisit) SchoolVisitService.updateVisitedAt(schoolVisit.id, date)
  }

  return (
    <div>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Spinner animation='border'></Spinner>
        </div>
      )}
      {schoolVisit && (
        <Fragment>
          <div className='card'>
            <div className='card-body'>
              <Row className='justify-content-between'>
                <Col sm={4}>
                  <Button variant='secondary' className='btn mb-3' onClick={() => history.goBack()}>
                    <i className='mr-3'>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </i>
                    Atras
                  </Button>
                  <div className='invoice-title'>
                    <label className='card-title'>Detalle de Visita</label>
                  </div>
                </Col>
                <Col sm={2}>
                  <Button
                    variant='primary'
                    className='btn mb-3'
                    onClick={() => history.push(`/school_visits/${schoolVisit.id}/document`)}
                  >
                    <i className='mr-3'>
                      <FontAwesomeIcon icon={faFile} />
                    </i>
                    Generar documento
                  </Button>
                  <div className='card-title-desc mb-0'>
                    {schoolVisit.status !== 2 ? 'Visita sin Confirmar' : 'Visita Confirmada'}
                  </div>
                </Col>
              </Row>
              <Row className='justify-content-between'>
                <Col sm={3}>
                  <Form.Group>
                    <label className='card-title-desc mb-0'>Fecha de la visita</label>
                    {schoolVisit.status == 0 ? (
                      <input
                        type='date'
                        className='form-control'
                        onChange={e => handleVisitedAtChange(e)}
                        value={visitedAt}
                      />
                    ) : (
                      <div className='alert alert-primary bg-white border border-primary text-primary text-center mb-0'>
                        {visitedAt}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <label className='card-title-desc mb-0'>Fecha de Creación</label>
                  <div className='alert alert-success bg-white border border-success text-success text-center mb-0'>
                    {format(new Date(schoolVisit.createdAt), 'PPP', { locale: es })}
                  </div>
                </Col>
                <Col sm={3}>
                  <label className='card-title-desc mb-0'>Última Actualización</label>
                  <div className='alert alert-primary bg-white border border-primary text-primary text-center mb-0'>
                    {format(new Date(schoolVisit.updatedAt), 'PPP', { locale: es })}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={2}>
                  <label className='card-title-desc mb-1'>Clave:</label>
                  <br />
                  <label>{schoolVisit.school.clave}</label>
                </Col>
                <Col sm={2}>
                  <label className='card-title-desc mb-1'>Escuela:</label>
                  <br />
                  <label>{schoolVisit.school.description}</label>
                </Col>
                <Col sm={2}>
                  <label className='card-title-desc mb-1'>Localidad:</label>
                  <br />
                  <label>{schoolVisit.school.locality.description}</label>
                </Col>
                <Col sm={2}>
                  <label className='card-title-desc mb-1'>Municipio:</label>
                  <br />
                  <label>{schoolVisit.school.municipality.description}</label>
                </Col>
                <Col sm={2}>
                  <label className='card-title-desc mb-1'>Distrito:</label>
                  <br />
                  <label>{schoolVisit.school.district.description}</label>
                </Col>
                <Col sm={2}>
                  <label className='card-title-desc mb-1'>Región:</label>
                  <br />
                  <label>{schoolVisit.school.region.description}</label>
                </Col>
              </Row>
            </div>
          </div>
          <Tab.Container id='left-tabs-example' defaultActiveKey='estadistic_control'>
            <Nav className='nav-tabs nav-tabs-custom nav-justified'>
              <Nav.Item>
                <Nav.Link eventKey='estadistic_control'>
                  <span className='tab-icon'>
                    <i className='h5'>
                      <FontAwesomeIcon icon={faChartBar} />
                    </i>
                  </span>
                  <span className='d-none d-sm-inline-block'>Control Estadistico</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='infrastructure'>
                  <span className='tab-icon'>
                    <i className='h5'>
                      <FontAwesomeIcon icon={faCubes} />
                    </i>
                  </span>
                  <span className='d-none d-sm-inline-block'>Infraestructura</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='map'>
                  <span className='tab-icon'>
                    <i className='h5'>
                      <FontAwesomeIcon icon={faMap} />
                    </i>
                  </span>
                  <span className='d-none d-sm-inline-block'>Planos</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='photographic_report'>
                  <span className='tab-icon'>
                    <i className='h5'>
                      <FontAwesomeIcon icon={faImages} />
                    </i>
                  </span>
                  <span className='d-none d-sm-inline-block'>Reporte Fotografico</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey='estadistic_control'>
                <EstadisticControl
                  schoolShifts={schoolVisit.schoolShifts}
                  terrainDescription={schoolVisit.terrainDescription}
                  exteriorWorks={schoolVisit.exteriorWorks}
                  schoolService={schoolVisit.schoolService}
                  soilType={schoolVisit.soilType}
                  communicationChannels={schoolVisit.communicationChannels}
                  existingTechnologies={schoolVisit.existingTechnologies}
                  edit={!(schoolVisit.status === 2)}
                  onUpdate={handleUpdateSchoolVisit}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='infrastructure'>
                <InfrastructureList
                  edit={!(schoolVisit.status === 2)}
                  infrastructures={schoolVisit.infrastructures}
                  onUpdate={handleUpdateSchoolVisit}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='map'>
                <MapImages
                  edit={!(schoolVisit.status === 2)}
                  schoolVisitImages={schoolVisit.schoolVisitImages}
                  key={`${schoolVisit.schoolVisitImages.length}MapImages`}
                  schoolVisitId={schoolVisit.id}
                  onUpdate={images => {
                    const visit = new SchoolVisit(schoolVisit)
                    visit.schoolVisitImages = images
                    setSchoolVisit(visit)
                  }}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='photographic_report'>
                <PhotoImages
                  edit={!(schoolVisit.status === 2)}
                  schoolVisitImages={schoolVisit.schoolVisitImages}
                  key={`${schoolVisit.schoolVisitImages.length}PhotoImages`}
                  schoolVisitId={schoolVisit.id}
                  onUpdate={images => {
                    const visit = new SchoolVisit(schoolVisit)
                    visit.schoolVisitImages = images
                    setSchoolVisit(visit)
                  }}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Fragment>
      )}
    </div>
  )
}

export default Detail
