import User from 'models/user'
import { IPagination } from 'interfaces/pagination'
import Api from 'api'
const resource = '/users'

type UsersData = {
  pagination: IPagination
  users: Array<User>
}

type UsersSelect = {
  users: Array<{ id: number; name: string }>
}
export default class UserService {
  static async getUsers(page: number, size: number, search: string): Promise<UsersData> {
    let url = `${resource}?page=${page}&page_size=${size}`
    if (search.trim().length > 0) url += '&search=' + search
    const { data } = await Api.get(url)
    return data
  }

  static async getUsersByName(): Promise<UsersSelect> {
    const { data } = await Api.get(`${resource}/list_by_name`)
    return { users: data.users }
  }

  static async getUser(id: number): Promise<User> {
    const { data } = await Api.get(`${resource}/${id}`)
    return new User(data.user)
  }

  static async saveUser(user: User): Promise<string> {
    let message = ''
    if (user.id) {
      const { data } = await Api.put(`${resource}/${user.id}`, user.asJSON())
      message = data.message
    } else {
      const { data } = await Api.post(`${resource}`, user.asJSON())
      message = data.message
    }
    return message
  }

  static async deleteUser(userId: number): Promise<string> {
    const { data } = await Api.delete(`${resource}/${userId}`)
    return data.message
  }
}
