import React, { useEffect, useState, ReactElement, Fragment, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import SimpleCard from 'components/cards/simple_card'
import Pagination from 'components/pagination/pagination'
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, InputGroup, Spinner } from 'react-bootstrap'
import { Select2, Select2Option } from 'select2-react-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faSearch, faEye, faList } from '@fortawesome/free-solid-svg-icons'
import CustomTable from 'components/table/custom_table'

import MunicipalityService from 'services/municipality_service'
import EducationLevelService from 'services/education_level_service'
import WorkService from 'services/work_service'
import SchoolService from 'services/school_service'

import Municipality, { Select2Municipality } from 'models/municipality'
import { SchoolVisitStatus } from 'models/school_visit'
import EducationLevel, { Select2EducationLevel } from 'models/education_level'
import SchoolVisits from 'models/school_visit'
import { ISchoolItem } from 'interfaces/school'
import SwAlert from 'components/alert/swal'
import { IReportContext, ReportContext } from 'context/report_context'

export interface Query {
  table: string
  tableEquivalency: string
  column: string
  columnEquivalency: string
  valString: string
  valNumber: number
  valBoolean: boolean
  type: string
  unit?: string
  equalOption?: string
  id?: number
  idCol?: string
}

interface Column {
  name: string
  equivalency: string
  type: string
  unit?: string
  options?: any
  id?: number
  idCol?: string
}

interface Table {
  name: string
  equivalency: string
  columns: Array<Column>
}

export interface CommonSelect {
  value: string | number
  label: string | number
}

const Reports = (): ReactElement => {
  const history = useHistory()

  const context = useContext(ReportContext) as IReportContext

  const [municipalitiesD, setMunicipalitiesD] = useState(new Array<Select2Option>())
  const [educationLevels, setEducationLevels] = useState(new Array<Select2Option>())
  const [initDate, setInitDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const [statuses, setStatuses] = useState<Array<CommonSelect>>(context.statuses)
  const [niveles, setNiveles] = useState<Array<CommonSelect>>(context.niveles)
  const [municipalities, setMunicipalities] = useState<Array<CommonSelect>>(context.municipalities)

  const [queries, setQueries] = useState(context.queries)
  const [table, setTable] = useState('soil_types')
  const [columns, setColumns] = useState(new Array<Column>(0))
  const [column, setColumn] = useState('Suelo rocoso')
  const [schools, setSchools] = useState(context.schools)
  const [tables, setTables] = useState<Array<Table>>([
    {
      name: 'soil_types',
      equivalency: 'Tipos de Suelo',
      columns: [
        {
          name: 'rockySoil',
          equivalency: 'Suelo rocoso',
          type: 'boolean',
        },
        {
          name: 'claySoil',
          equivalency: 'Suelo arcilloso',
          type: 'boolean',
        },
        {
          name: 'sandySoil',
          equivalency: 'Suelo arenoso',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'school_services',
      equivalency: 'Servicios',
      columns: [
        {
          name: 'drainageNetwork',
          equivalency: 'Red de drenaje a colector municipal',
          type: 'boolean',
        },
        {
          name: 'telephoneLine',
          equivalency: 'Línea Telefonica',
          type: 'boolean',
        },
        {
          name: 'drinkingWater',
          equivalency: 'Agua Potable',
          type: 'boolean',
        },
        {
          name: 'electricPower',
          equivalency: 'Energía Electrica',
          type: 'boolean',
        },
        {
          name: 'internet',
          equivalency: 'Internet',
          type: 'boolean',
        },
        {
          name: 'others',
          equivalency: 'Otros',
          type: 'string',
        },
      ],
    },
    {
      name: 'terrain_descriptions',
      equivalency: 'Descripción del terreno',
      columns: [
        {
          name: 'area',
          equivalency: 'Área',
          type: 'number',
          unit: 'M2',
        },
        {
          name: 'soil_mechanics',
          equivalency: 'Require mecánica de suelos',
          type: 'boolean',
        },
        {
          name: 'donation_certificate',
          equivalency: 'Acta de donación',
          type: 'boolean',
        },
        /* todo redo
        {
          name: 'procedencia',
          equivalency: 'Acta de donación',
          type: 'array',
          options: [],
        },*/
      ],
    },
    {
      name: 'infrastructures',
      equivalency: 'Infraestructuras',
      columns: [
        {
          name: 'spaces_number',
          equivalency: 'Número de espacios',
          type: 'number',
        },
        {
          name: 'structure',
          equivalency: 'Estructura',
          type: 'string',
        },
        {
          name: 'no_e_e',
          equivalency: 'No. E.E.',
          type: 'number',
        },
        {
          name: 'muros',
          equivalency: 'Muros',
          type: 'string',
        },
        {
          name: 'techumbre',
          equivalency: 'Techumbre',
          type: 'string',
        },
        {
          name: 'canceleria',
          equivalency: 'Cancelería',
          type: 'string',
        },
        {
          name: 'education_spaces',
          equivalency: 'Espacios educativos',
          type: 'string',
        },
        {
          name: 'createdBy',
          equivalency: 'Creado por',
          type: 'string',
        },
        {
          name: 'createdYear',
          equivalency: 'Año de construcción',
          type: 'number',
        },
        {
          name: 'necesities',
          equivalency: 'Necesidades',
          type: 'vector',
        },
      ],
    },
  ])
  const SchoolHeaders = ['Clave', 'Nombre', 'Localidad', 'Municipio', 'Distrito', 'Región', 'Nivel', 'Acciones']

  const [page, setPage] = useState(context.page)
  const [limit, setLimit] = useState(context.limit)
  const [total, setTotal] = useState(context.total)

  useEffect(() => {
    context.setStatuses(statuses)
  }, [statuses])

  useEffect(() => {
    context.setNiveles(niveles)
  }, [niveles])

  useEffect(() => {
    context.setMunicipalities(municipalities)
  }, [municipalities])

  useEffect(() => {
    context.setQueries(queries)
  }, [queries])

  useEffect(() => {
    context.setSchools(schools)
  }, [schools])

  useEffect(() => {
    context.setTotal(total)
    context.setLimit(limit)
    context.setPage(page)

    if (total > 0) handleSelectQuery()
    else if (total) setSchools([])
  }, [total, limit, page])

  useEffect(() => {
    const selectedTable = tables.find(val => val.name == table)
    if (selectedTable) setColumns(selectedTable.columns)
  }, [table])

  useEffect(() => {
    MunicipalityService.getAll().then((data: Array<Municipality>) => {
      setMunicipalitiesD(Select2Municipality(data))
    })

    EducationLevelService.getAll().then((data: Array<EducationLevel>) => {
      setEducationLevels(Select2EducationLevel(data))
    })

    //todo cargar procedencias de estructura

    WorkService.getAll().then(works => {
      const columns = []

      for (const work of works) {
        const name = `${work.name.charAt(0).toUpperCase()}${work.name.slice(1).toLowerCase()}`

        const column: Column = {
          name: 'quantity',
          equivalency: name,
          type: 'number',
          unit: work.base_unit,
          id: work.id,
          idCol: 'workId',
        }

        columns.push(column)
      }

      const table: Table = {
        name: 'exterior_works',
        equivalency: 'Obra exterior existente',
        columns: columns,
      }

      setTables([...tables, table])
    })
  }, [])

  const handleTable = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTable(e.target.value)
    const selectedTable = tables.find(val => val.name == e.target.value)
    if (selectedTable) setColumn(selectedTable.columns[0].equivalency)
  }

  const handleColumn = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setColumn(e.target.value)
  }

  const handleRemoveQuery = (index: number) => {
    const queriesT = [...queries]
    queriesT.splice(index, 1)
    setQueries(queriesT)
  }

  const handleQuery = async () => {
    const selectedTable = tables.find(val => val.name == table)

    if (selectedTable) {
      const selectedColumn = selectedTable.columns.find(val => val.equivalency == column)

      if (selectedColumn) {
        const query: Query = {
          table: selectedTable.name,
          tableEquivalency: selectedTable.equivalency,
          column: selectedColumn.name,
          columnEquivalency: selectedColumn.equivalency,
          valBoolean: false,
          valString: '',
          valNumber: 0,
          type: selectedColumn.type,
          unit: selectedColumn.unit,
          equalOption: '=',
          id: selectedColumn.id,
          idCol: selectedColumn.idCol,
        }

        setQueries([...queries, query])
      }
    }
  }

  const handleFilter = () => {
    handleSelectQuery()
    handleCountQuery()
  }

  const handleSelectQuery = async () => {
    const selectMasterQuery = `
      SELECT
        schools.id as id,
        max(schools.clave) as clave,
        max(schools.description) as description,
        max(localities.description) as locality,
        max(municipalities.description) as municipality,
        max(districts.description) as district,
        max(regions.description) as region,
        max(education_levels.description) as "educationLevel"
    `

    let fromMasterQuery = `
      FROM 
        schools inner join
        school_visits sv on sv."schoolId" = schools.id 
        AND sv.id = 
        (
           SELECT MAX(id) 
           FROM school_visits sv2
           WHERE sv2.id = sv.id
        )
        left join
        localities on localities.id = schools."localityId" left join
        municipalities on municipalities.id = schools."municipalityId" left join
        districts on districts.id = schools."districtId" left join
        regions on regions.id = schools."regionId" left join
        education_levels on education_levels.id = schools."educationLevelId"
    `

    const whereMunicipalities = []

    if (municipalities.length) {
      for (const municipality of municipalities) {
        whereMunicipalities.push(`
          municipalities.id = ${municipality.value}
        `)
      }
    }

    const whereNiveles = []

    if (niveles.length) {
      for (const nivel of niveles) {
        whereNiveles.push(`
          education_levels.id = ${nivel.value}
        `)
      }
    }

    const whereStatus = []

    if (statuses.length) {
      for (const status of statuses) {
        whereStatus.push(`
          sv.status = ${status.value}
        `)
      }
    }

    let whereInitQuery = null

    if (initDate) whereInitQuery = ` sv."visitedAt" >= '${initDate}'::date `

    let whereEndQuery = null

    if (endDate) whereEndQuery = ` sv."visitedAt" <= '${endDate}'::date `

    const soilTypes = queries.find(query => query.table == 'soil_types')
    const schoolServices = queries.find(query => query.table == 'school_services')
    const terrainDescriptions = queries.find(query => query.table == 'terrain_descriptions')
    const infrastructures = queries.filter(query => query.table == 'infrastructures')
    const exteriorWorks = queries.filter(query => query.table == 'exterior_works')

    if (soilTypes)
      fromMasterQuery += `
        INNER JOIN soil_types ON sv."soilTypeId" = soil_types.id
      `

    if (schoolServices)
      fromMasterQuery += `
        INNER JOIN school_services ON sv."schoolServiceId" = school_services.id
      `

    if (terrainDescriptions)
      fromMasterQuery += `
        INNER JOIN terrain_descriptions ON sv."terrainDescriptionId" = terrain_descriptions.id
      `

    if (infrastructures.length) {
      const statements = generateWhereQueries(infrastructures)

      fromMasterQuery += `
        left join infrastructures on infrastructures."schoolVisitId" = sv.id
      `
    }

    let whereExteriorWorks: Array<string> = []

    if (exteriorWorks.length) {
      whereExteriorWorks = generateWhereQueries(exteriorWorks)

      fromMasterQuery += `
        left join exterior_works on exterior_works."schoolVisitId" = sv.id
        `
    }

    const filteredQueries = queries.filter(query => query.table != 'exterior_works')
    const whereQueries = generateWhereQueries(filteredQueries)

    let whereMasterQuery = `
      ${whereMunicipalities.length ? ` (${whereMunicipalities.join(' OR ')}) AND ` : ''}
      ${whereNiveles.length ? ` (${whereNiveles.join(' OR ')}) AND ` : ''}
      ${whereStatus.length ? ` (${whereStatus.join(' OR ')}) AND ` : ''}
      ${whereQueries.length ? ` (${whereQueries.join(' AND ')}) ` : ''}
      ${whereExteriorWorks.length ? ` (${whereExteriorWorks.join(' OR ')}) ` : ''}
      ${whereInitQuery ? ` ${whereInitQuery} AND ` : ''}
      ${whereEndQuery ? ` ${whereEndQuery} AND ` : ''}
    `

    whereMasterQuery = whereMasterQuery.trim()
    whereMasterQuery = whereMasterQuery.endsWith('AND')
      ? whereMasterQuery.substring(0, whereMasterQuery.length - 3)
      : whereMasterQuery

    whereMasterQuery = whereMasterQuery.trim()

    const masterQuery = `
      ${selectMasterQuery.trim()}
      ${fromMasterQuery.trim()}
      ${whereMasterQuery.length > 0 ? `WHERE ${whereMasterQuery}` : ''}
      GROUP BY schools.id
      ${exteriorWorks.length || infrastructures.length ? 'order by ' : ''}
      ${exteriorWorks.length ? 'count(distinct "exterior_works"."workId") DESC' : ''}
      ${exteriorWorks.length && infrastructures.length ? ',' : ''}
      ${infrastructures.length ? 'count(distinct "infrastructures"."id") DESC' : ''}
      limit ${limit}
      offset ${(page - 1) * limit}
    `

    try {
      setLoading(true)
      const schoolsD = await SchoolService.query(masterQuery)
      setSchools([...schoolsD])
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleCountQuery = async () => {
    const selectMasterQuery = `
      SELECT
        count(distinct schools.id) as total
    `

    let fromMasterQuery = `
      FROM 
        schools inner join
        school_visits sv on sv."schoolId" = schools.id
        AND sv.id = 
        (
           SELECT MAX(id) 
           FROM school_visits sv2
           WHERE sv2.id = sv.id
        )
        left join
        localities on localities.id = schools."localityId" left join
        municipalities on municipalities.id = schools."municipalityId" left join
        districts on districts.id = schools."districtId" left join
        regions on regions.id = schools."regionId" left join
        education_levels on education_levels.id = schools."educationLevelId"
    `

    const whereMunicipalities = []

    if (municipalities.length) {
      for (const municipality of municipalities) {
        whereMunicipalities.push(`
          municipalities.id = ${municipality.value}
        `)
      }
    }

    const whereNiveles = []

    if (niveles.length) {
      for (const nivel of niveles) {
        whereNiveles.push(`
          education_levels.id = ${nivel.value}
        `)
      }
    }

    const whereStatus = []

    if (statuses.length) {
      for (const status of statuses) {
        whereStatus.push(`
          sv.status = ${status.value}
        `)
      }
    }

    let whereInitQuery = null

    if (initDate) whereInitQuery = ` sv."visitedAt" >= '${initDate}'::date `

    let whereEndQuery = null

    if (endDate) whereEndQuery = ` sv."visitedAt" <= '${endDate}'::date `

    const soilTypes = queries.find(query => query.table == 'soil_types')
    const schoolServices = queries.find(query => query.table == 'school_services')
    const terrainDescriptions = queries.find(query => query.table == 'terrain_descriptions')
    const infrastructures = queries.filter(query => query.table == 'infrastructures')
    const exteriorWorks = queries.filter(query => query.table == 'exterior_works')

    if (soilTypes)
      fromMasterQuery += `
        INNER JOIN soil_types ON sv."soilTypeId" = soil_types.id
      `

    if (schoolServices)
      fromMasterQuery += `
        INNER JOIN school_services ON sv."schoolServiceId" = school_services.id
      `

    if (terrainDescriptions)
      fromMasterQuery += `
        INNER JOIN terrain_descriptions ON sv."terrainDescriptionId" = terrain_descriptions.id
      `

    if (infrastructures.length) {
      const statements = generateWhereQueries(infrastructures)

      fromMasterQuery += `
        left join infrastructures on infrastructures."schoolVisitId" = sv.id
      `
    }

    let whereExteriorWorks: Array<string> = []

    if (exteriorWorks.length) {
      whereExteriorWorks = generateWhereQueries(exteriorWorks)

      fromMasterQuery += `
        left join exterior_works on exterior_works."schoolVisitId" = sv.id
        `
    }

    const filteredQueries = queries.filter(query => query.table != 'exterior_works')
    const whereQueries = generateWhereQueries(filteredQueries)

    let whereMasterQuery = `
      ${whereMunicipalities.length ? ` (${whereMunicipalities.join(' OR ')}) AND ` : ''}
      ${whereNiveles.length ? ` (${whereNiveles.join(' OR ')}) AND ` : ''}
      ${whereStatus.length ? ` (${whereStatus.join(' OR ')}) AND ` : ''}
      ${whereQueries.length ? ` (${whereQueries.join(' AND ')}) ` : ''}
      ${whereExteriorWorks.length ? ` (${whereExteriorWorks.join(' OR ')}) ` : ''}
      ${whereInitQuery ? ` ${whereInitQuery} AND ` : ''}
      ${whereEndQuery ? ` ${whereEndQuery} AND ` : ''}
    `

    whereMasterQuery = whereMasterQuery.trim()
    whereMasterQuery = whereMasterQuery.endsWith('AND')
      ? whereMasterQuery.substring(0, whereMasterQuery.length - 3)
      : whereMasterQuery

    whereMasterQuery = whereMasterQuery.trim()

    const masterQuery = `
      ${selectMasterQuery.trim()}
      ${fromMasterQuery.trim()} 
      ${whereMasterQuery.length > 0 ? `WHERE ${whereMasterQuery}` : ''}
    `

    const total = await SchoolService.queryCount(masterQuery)

    if (total == 0) SwAlert.fire('', 'No encontré visitas con estos parámetros de búsqueda', 'error')

    setTotal(total)
    setPage(1)
  }

  function generateWhereQueries(queries: Array<Query>) {
    const whereQueries = []

    for (const query of queries) {
      let whereQuery = ` "${query.table}"."${query.column}" `

      switch (query.type) {
        case 'boolean':
          whereQuery += ` = ${query.valBoolean} `
          break
        case 'number':
          whereQuery = ` ("${query.table}"."${query.column}" ${query.equalOption} ${query.valNumber} ${
            query.id ? ` AND "${query.table}"."${query.idCol}" = ${query.id}) ` : `)`
          }`
          break
        case 'string':
          whereQuery += ` ilike '${query.valString
            .split(' ')
            .map(val => `%${val}%`)
            .join(' ')}' `
          break
        case 'vector':
          whereQuery = ` to_tsvector("${query.table}"."${query.column}") @@ to_tsquery('${query.valString
            .split(' ')
            .join(' | ')}}') `
          break
      }

      whereQueries.push(whereQuery)
    }

    return whereQueries
  }

  const dataBody = () => {
    return (
      <Fragment>
        {schools.map((school, key) => {
          return (
            <tr key={`${key}school`} className={`table-${school.color ? school.color : 'light'}`}>
              <td>{school.clave}</td>
              <td>{school.description}</td>
              <td>{school.locality}</td>
              <td>{school.municipality}</td>
              <td>{school.district}</td>
              <td>{school.region}</td>
              <td>{school.educationLevel}</td>
              <td>
                <div className='action d-inline-flex'>
                  <OverlayTrigger overlay={<Tooltip id='edit-visit'>Ver detalle</Tooltip>}>
                    <span className='mr-3'>
                      <Button variant='outline-info' size='sm' onClick={() => history.push(`/schools/${school.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                  {school.lastVisitId && (
                    <OverlayTrigger overlay={<Tooltip id='show-visit'>Ver última visita</Tooltip>}>
                      <span>
                        <Button
                          variant='outline-info'
                          size='sm'
                          onClick={() => history.push(`/school_visits/${school.lastVisitId}`)}
                        >
                          <FontAwesomeIcon icon={faList} />
                        </Button>
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  return (
    <Row>
      <Col lg={12}>
        <SimpleCard title='Reportes'>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Fecha inicio</Form.Label>
                <input
                  type='date'
                  className='form-control'
                  value={initDate ? initDate : ''}
                  onChange={e => setInitDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Fecha fin</Form.Label>
                <input
                  type='date'
                  className='form-control'
                  value={endDate ? endDate : ''}
                  onChange={e => setEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Municipios</Form.Label>
                <Select2
                  data={municipalitiesD}
                  value={'default'}
                  update={val => {
                    const municipality = municipalitiesD.find(svStatus => svStatus.value == val)
                    const storedMunicipality = municipalities.find(svStatus => svStatus.value == val)
                    if (municipality && !storedMunicipality) {
                      const municipalityCommon: CommonSelect = {
                        label: municipality.label,
                        value: municipality.value ? parseInt(`${municipality.value}`) : 0,
                      }
                      setMunicipalities([...municipalities, municipalityCommon])
                    }
                  }}
                ></Select2>
              </Form.Group>
              {municipalities.length ? (
                municipalities.map((municipality, index) => {
                  return (
                    <Button
                      key={`${index}municipality`}
                      variant='secondary'
                      className='mx-1 my-1'
                      onClick={() => {
                        const municipalitiesCopy = [...municipalities]
                        municipalitiesCopy.splice(index, 1)
                        setMunicipalities(municipalitiesCopy)
                      }}
                    >
                      <span className='btn-icon-label'>
                        <i className='mr-1'>
                          <FontAwesomeIcon icon={faTimes} />
                        </i>
                      </span>
                      {municipality.label}
                    </Button>
                  )
                })
              ) : (
                <Button variant='secondary' className='ma-1'>
                  Todos los municipios
                </Button>
              )}
            </Col>
            <Col sm={12}>
              <hr />
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Estatus</Form.Label>
                <Select2
                  data={SchoolVisitStatus}
                  value={'default'}
                  update={val => {
                    const status = SchoolVisitStatus.find(svStatus => svStatus.value == val)
                    const storedStatus = statuses.find(svStatus => svStatus.value == val)
                    if (status && !storedStatus) {
                      setStatuses([...statuses, status])
                    }
                  }}
                ></Select2>
              </Form.Group>
              {statuses.length ? (
                statuses.map((status, index) => {
                  return (
                    <Button
                      key={`${index}statuses`}
                      variant='secondary'
                      className='mx-1 my-1'
                      onClick={() => {
                        const statusesCopy = [...statuses]
                        statusesCopy.splice(index, 1)
                        setStatuses(statusesCopy)
                      }}
                    >
                      <span className='btn-icon-label'>
                        <i className='mr-1'>
                          <FontAwesomeIcon icon={faTimes} />
                        </i>
                      </span>
                      {status.label}
                    </Button>
                  )
                })
              ) : (
                <Button variant='secondary' className='ma-1'>
                  Todos los estatus
                </Button>
              )}
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Nivel</Form.Label>
                <Select2
                  data={educationLevels}
                  value={'default'}
                  update={val => {
                    const nivel = educationLevels.find(svStatus => svStatus.value == val)
                    const storedNivel = niveles.find(svStatus => svStatus.value == val)
                    if (nivel && !storedNivel) {
                      const nivelCommon: CommonSelect = {
                        label: nivel.label,
                        value: nivel.value ? parseInt(`${nivel.value}`) : 0,
                      }
                      setNiveles([...niveles, nivelCommon])
                    }
                  }}
                ></Select2>
              </Form.Group>
              {niveles.length ? (
                niveles.map((nivel, index) => {
                  return (
                    <Button
                      key={`${index}nivel`}
                      variant='secondary'
                      className='mx-1 my-1'
                      onClick={() => {
                        const nivelesCopy = [...niveles]
                        nivelesCopy.splice(index, 1)
                        setNiveles(nivelesCopy)
                      }}
                    >
                      <span className='btn-icon-label'>
                        <i className='mr-1'>
                          <FontAwesomeIcon icon={faTimes} />
                        </i>
                      </span>
                      {nivel.label}
                    </Button>
                  )
                })
              ) : (
                <Button variant='secondary' className='ma-1'>
                  Todos los niveles
                </Button>
              )}
            </Col>
            <Col sm={12}>
              <hr />
            </Col>
            <Col xs={12}>
              <Row className='align-items-center'>
                <Col>
                  <Form.Label>Escoge una categoria</Form.Label>
                  <Form.Control as='select' onChange={handleTable} value={table}>
                    {tables.map(val => {
                      return (
                        <option key={`${val.equivalency}category`} value={val.name}>
                          {val.equivalency}
                        </option>
                      )
                    })}
                  </Form.Control>
                </Col>
                <Col>
                  <Form.Label>Escoge una sección</Form.Label>
                  <Form.Control as='select' onChange={handleColumn} value={column}>
                    {columns.map(val => {
                      return (
                        <option key={`${val.equivalency}section`} value={val.equivalency}>
                          {val.equivalency}
                        </option>
                      )
                    })}
                  </Form.Control>
                </Col>
                <Col xs={1}>
                  <OverlayTrigger overlay={<Tooltip id='add-query'>Agregar</Tooltip>}>
                    <Button variant='outline-info' className='mr-3' onClick={() => handleQuery()}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className='my-4'>
              {queries.map((query, index) => {
                let el
                switch (query.type) {
                  case 'boolean':
                    el = (
                      <FormCheck
                        column={query.columnEquivalency}
                        handleChange={(val: boolean) => {
                          query.valBoolean = val
                        }}
                        value={query.valBoolean}
                      />
                    )
                    break
                  case 'string':
                    el = (
                      <Form.Group>
                        <Form.Label>{query.columnEquivalency}</Form.Label>
                        <Form.Control
                          onChange={e => {
                            query.valString = e.target.value ? e.target.value : ''
                          }}
                          defaultValue={query.valString}
                        />
                      </Form.Group>
                    )
                    break
                  case 'vector':
                    el = (
                      <Form.Group>
                        <Form.Label>{query.columnEquivalency}</Form.Label>
                        <Form.Control
                          onChange={e => {
                            query.valString = e.target.value ? e.target.value : ''
                          }}
                          defaultValue={query.valString}
                        />
                      </Form.Group>
                    )
                    break
                  case 'number':
                    el = (
                      <Row className='my-2'>
                        <Col>
                          <Form.Label>{query.columnEquivalency}</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            as='select'
                            onChange={e => {
                              query.equalOption = e.target.value ? e.target.value : '='
                            }}
                            defaultValue={query.equalOption}
                          >
                            <option value={'='}>{'='}</option>
                            <option value={'!='}>{'!='}</option>
                            <option value={'>'}>{'>'}</option>
                            <option value={'>='}>{'>='}</option>
                            <option value={'<'}>{'<'}</option>
                            <option value={'<='}>{'<='}</option>
                          </Form.Control>
                        </Col>
                        <Col>
                          <InputGroup>
                            <Form.Control
                              type='number'
                              onChange={e => {
                                query.valNumber = e.target.value ? parseInt(e.target.value) : 0
                              }}
                              defaultValue={query.valNumber}
                            />
                            <InputGroup.Prepend>
                              <InputGroup.Text>{query.unit}</InputGroup.Text>
                            </InputGroup.Prepend>
                          </InputGroup>
                        </Col>
                      </Row>
                    )
                    break
                  default:
                    el = <div>No handler type defined</div>
                }

                return (
                  <Row key={`${index}Queries`} className='align-items-center'>
                    <Col>{el}</Col>
                    <Col xs={1}>
                      <OverlayTrigger overlay={<Tooltip id='remove-query'>Remover</Tooltip>}>
                        <Button variant='outline-danger' className='mr-3' onClick={() => handleRemoveQuery(index)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                )
              })}
            </Col>
            <Col xs={12} className='text-right'>
              <Button variant='info' className='btn-icon mr-1' onClick={handleFilter}>
                <span className='btn-icon-label'>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faSearch} />
                  </i>
                </span>
                Filtrar
              </Button>
            </Col>
            {/* 
              todo add after defining funcionality
              <Col sm={12} className='my-4'>
              <Button variant='success' className='btn-icon mr-1'>
                <span className='btn-icon-label'>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faFileExport} />
                  </i>
                </span>
                Exportar
              </Button>
              <Button variant='primary' className='btn-icon mx-1'>
                <span className='btn-icon-label'>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faGlobeAmericas} />
                  </i>
                </span>
                Ver Mapa
              </Button>
              <Button variant='info' className='btn-icon mx-1'>
                <span className='btn-icon-label'>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faBriefcase} />
                  </i>
                </span>
                Productividad
              </Button>
              <Button variant='warning' className='btn-icon ml-1'>
                <span className='btn-icon-label'>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faFileDownload} />
                  </i>
                </span>
                Imprimir Reportes
              </Button>
            </Col>
              */}
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <Spinner animation='border'></Spinner>
              </div>
            )}
            <Col md={12} className='mt-3'>
              <CustomTable headers={SchoolHeaders} dataBody={dataBody()} />
            </Col>
            <Col xs={12}>
              <Pagination
                totalRecords={total}
                pageLimit={limit}
                currentPage={page}
                changePage={page => {
                  setPage(page)
                }}
                changePageLimit={page => {
                  setLimit(page)
                }}
              />
            </Col>
          </Row>
        </SimpleCard>
      </Col>
    </Row>
  )
}

interface IFormProps {
  column: string
  handleChange: (val: boolean) => void
  value: boolean
}

const FormCheck = ({ column, handleChange, value }: IFormProps): ReactElement => {
  const [checked, setChecked] = useState(value)
  return (
    <Form.Group>
      <Form.Check
        type='checkbox'
        label={column}
        checked={checked}
        onChange={e => {
          handleChange(e.target.checked)
          setChecked(e.target.checked)
        }}
      />
    </Form.Group>
  )
}

export default Reports
