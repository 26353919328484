import { IEducationLevel } from 'interfaces/education_level'
import { Select2Option } from 'select2-react-component'

export default class EducationLevel {
  id
  description
  constructor(properties: IEducationLevel = {}) {
    this.id = properties.id || 0
    this.description = properties.description || ''
  }
}

export const Select2EducationLevel = (educationLevel: Array<EducationLevel>): Array<Select2Option> => {
  return educationLevel.map(el => {
    return { value: el.id, label: el.description }
  })
}
