import React, { useEffect, useState, ReactElement, Fragment } from 'react'
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
//import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faEdit } from '@fortawesome/free-solid-svg-icons'
import SwAlert from 'components/alert/swal'
import CustomTable from 'components/table/custom_table'
import OptionForm from './form'
import ServiceForm from './service_form'

import OptionsService from 'services/options_service'
import ServiceService from 'services/service_service'

import StringOption from 'models/string_option'
import Service from 'models/service'

const Options = (): ReactElement => {
  const [muros, setMuros] = useState(new Array<StringOption>())
  const [techumbre, setTechumbre] = useState(new Array<StringOption>())
  const [canceleria, setCanceleria] = useState(new Array<StringOption>())
  const [educationSpaces, setEducationSpaces] = useState(new Array<StringOption>())
  const [technologies, setTechnologies] = useState(new Array<StringOption>())
  const [services, setServices] = useState(new Array<Service>())

  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [showModal, setShowModal] = useState(false)

  const [showService, setShowService] = useState(false)
  const [service, setService] = useState(new Service())

  const OptionsHeaders = ['Descripción', 'Acciones']
  const ServicesHeaders = ['Descripción', 'Acciones']

  useEffect(() => {
    getOptions()
  }, [])

  const getOptions = async () => {
    ServiceService.getAll().then(services => {
      setServices(services)
    })

    const options = await OptionsService.getAll()

    const muros = options.filter(option => option.type == 'MUROS')
    setMuros(muros)
    const techumbre = options.filter(option => option.type == 'TECHUMBRE')
    setTechumbre(techumbre)
    const canceleria = options.filter(option => option.type == 'CANCELERIA')
    setCanceleria(canceleria)
    const educationSpaces = options.filter(option => option.type == 'EDUCATION_SPACES')
    setEducationSpaces(educationSpaces)
    const technologies = options.filter(option => option.type == 'TECHNOLOGIES')
    setTechnologies(technologies)
  }

  const handleDelete = async (id: number, index: number, type: string) => {
    SwAlert.fire({
      title: 'Eliminar',
      text: '¿Está seguro de eliminar esta opción?',
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const message = await OptionsService.delete(id)
          SwAlert.fire('Operación exitosa!', message, 'success')
          switch (type) {
            case 'MUROS':
              muros.splice(index, 1)
              setMuros([...muros])
              break
            case 'TECHUMBRE':
              techumbre.splice(index, 1)
              setTechumbre([...techumbre])
              break
            case 'CANCELERIA':
              canceleria.splice(index, 1)
              setCanceleria([...canceleria])
              break
            case 'EDUCATION_SPACES':
              educationSpaces.splice(index, 1)
              setEducationSpaces([...educationSpaces])
              break
            case 'TECHNOLOGIES':
              technologies.splice(index, 1)
              setTechnologies([...technologies])
              break
          }
        } catch (e) {
          if (e.error) SwAlert.fire('Ocurrio un error!', e.error, 'error')
        }
      },
      allowOutsideClick: false,
    })
  }

  const save = async (option: StringOption) => {
    try {
      const stringOption = await OptionsService.save(option)
      closeModal()
      SwAlert.fire('Operación exitosa!', 'guardado exitosamente', 'success')
      switch (type) {
        case 'MUROS':
          setMuros([...muros, stringOption])
          break
        case 'TECHUMBRE':
          setTechumbre([...techumbre, stringOption])
          break
        case 'CANCELERIA':
          setCanceleria([...canceleria, stringOption])
          break
        case 'EDUCATION_SPACES':
          setEducationSpaces([...educationSpaces, stringOption])
          break
        case 'TECHNOLOGIES':
          setTechnologies([...technologies, stringOption])
          break
      }
    } catch (e) {
      console.log('Error save user: ', e)
      if (e.error) SwAlert.fire('Ocurrio un error!', e.error, 'error')
    }
  }

  const submitService = async (service: Service) => {
    if (service.id) {
      const result = await ServiceService.edit(service)
      closeService()
      const stored = services.find(service => service.id == result.id)
      if (stored) {
        stored.name = result.name
        setServices([...services])
      }
    } else {
      const result = await ServiceService.save(service)
      closeService()
      services.push(result)
      setServices([...services])
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeService = () => {
    setShowService(false)
    setService(new Service())
  }

  const servicesBody = (services: Service[]) => {
    return (
      <Fragment>
        {services.map((service, index) => {
          return (
            <tr key={index}>
              <td>{service.name}</td>
              <td>
                <div className='action'>
                  <OverlayTrigger overlay={<Tooltip id='delete-user'>Editar</Tooltip>}>
                    <span className='d-inline-block'>
                      <Button
                        variant='outline-info'
                        size='sm'
                        onClick={() => {
                          setService(service)
                          setShowService(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id='delete-user'>Eliminar</Tooltip>}>
                    <span className='d-inline-block'>
                      <Button
                        variant='outline-danger'
                        size='sm'
                        onClick={() => {
                          //todo
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  const optionsBody = (options: StringOption[], type: string) => {
    return (
      <Fragment>
        {options.map((option, key) => {
          return (
            <tr key={key}>
              <td>{option.option}</td>
              <td>
                <div className='action'>
                  <OverlayTrigger overlay={<Tooltip id='delete-user'>Eliminar</Tooltip>}>
                    <span className='d-inline-block'>
                      <Button
                        variant='outline-danger'
                        size='sm'
                        onClick={() => {
                          handleDelete(option.id, key, type)
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  return (
    <div>
      <Row>
        <Col sm='6'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Servicios</h4>
              <Row>
                <Col sm={6} className='table-toolbar-left'>
                  <Button
                    variant='primary'
                    className='btn-icon'
                    onClick={() => {
                      setService(new Service())
                      setShowService(true)
                    }}
                  >
                    <span className='btn-icon-label'>
                      <i className='mr-2'>
                        <FontAwesomeIcon icon={faPlus} />
                      </i>
                    </span>
                    Agregar servicio
                  </Button>
                </Col>
                <Col sm={6} className='table-toolbar-right'></Col>
              </Row>
              <CustomTable headers={ServicesHeaders} dataBody={servicesBody(services)} />
            </div>
          </div>
        </Col>

        <Col sm='6'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Muros</h4>
              <Row>
                <Col sm={6} className='table-toolbar-left'>
                  <Button
                    variant='primary'
                    className='btn-icon'
                    onClick={() => {
                      setShowModal(true)
                      setTitle('Crear muro')
                      setType('MUROS')
                    }}
                  >
                    <span className='btn-icon-label'>
                      <i className='mr-2'>
                        <FontAwesomeIcon icon={faPlus} />
                      </i>
                    </span>
                    Agregar Muro
                  </Button>
                </Col>
                <Col sm={6} className='table-toolbar-right'></Col>
              </Row>
              <CustomTable headers={OptionsHeaders} dataBody={optionsBody(muros, 'MUROS')} />
            </div>
          </div>
        </Col>

        <Col sm='6'>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <Col sm={6} className='table-toolbar-left'>
                  <h4 className='card-title'>Techumbre</h4>
                  <Button
                    variant='primary'
                    className='btn-icon'
                    onClick={() => {
                      setShowModal(true)
                      setTitle('Crear techumbre')
                      setType('TECHUMBRE')
                    }}
                  >
                    <span className='btn-icon-label'>
                      <i className='mr-2'>
                        <FontAwesomeIcon icon={faPlus} />
                      </i>
                    </span>
                    Agregar Techumbre
                  </Button>
                </Col>
                <Col sm={6} className='table-toolbar-right'></Col>
              </Row>
              <CustomTable headers={OptionsHeaders} dataBody={optionsBody(techumbre, 'TECHUMBRE')} />
            </div>
          </div>
        </Col>

        <Col sm='6'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Cancelería</h4>
              <Row>
                <Col sm={6} className='table-toolbar-left'>
                  <Button
                    variant='primary'
                    className='btn-icon'
                    onClick={() => {
                      setShowModal(true)
                      setTitle('Crear cancelería')
                      setType('CANCELERIA')
                    }}
                  >
                    <span className='btn-icon-label'>
                      <i className='mr-2'>
                        <FontAwesomeIcon icon={faPlus} />
                      </i>
                    </span>
                    Agregar Cancelería
                  </Button>
                </Col>
                <Col sm={6} className='table-toolbar-right'></Col>
              </Row>
              <CustomTable headers={OptionsHeaders} dataBody={optionsBody(canceleria, 'CANCELERIA')} />
            </div>
          </div>
        </Col>

        <Col sm='6'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Espacios educativos</h4>
              <Row>
                <Col sm={6} className='table-toolbar-left'>
                  <Button
                    variant='primary'
                    className='btn-icon'
                    onClick={() => {
                      setShowModal(true)
                      setTitle('Crear espacio educativo')
                      setType('EDUCATION_SPACES')
                    }}
                  >
                    <span className='btn-icon-label'>
                      <i className='mr-2'>
                        <FontAwesomeIcon icon={faPlus} />
                      </i>
                    </span>
                    Agregar Educativos
                  </Button>
                </Col>
                <Col sm={6} className='table-toolbar-right'></Col>
              </Row>
              <CustomTable headers={OptionsHeaders} dataBody={optionsBody(educationSpaces, 'EDUCATION_SPACES')} />
            </div>
          </div>
        </Col>

        <Col sm='6'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Tecnologías</h4>
              <Row>
                <Col sm={6} className='table-toolbar-left'>
                  <Button
                    variant='primary'
                    className='btn-icon'
                    onClick={() => {
                      setShowModal(true)
                      setTitle('Crear tecnologías')
                      setType('TECHNOLOGIES')
                    }}
                  >
                    <span className='btn-icon-label'>
                      <i className='mr-2'>
                        <FontAwesomeIcon icon={faPlus} />
                      </i>
                    </span>
                    Agregar Tecnología
                  </Button>
                </Col>
                <Col sm={6} className='table-toolbar-right'></Col>
              </Row>
              <CustomTable headers={OptionsHeaders} dataBody={optionsBody(technologies, 'TECHNOLOGIES')} />
            </div>
          </div>
        </Col>
      </Row>
      <OptionForm type={type} title={title} onSubmit={save} onShow={showModal} onClose={closeModal} />
      <ServiceForm service={service} onSubmit={submitService} onShow={showService} onClose={closeService} />
    </div>
  )
}
export default Options
