import React, { useEffect, ReactElement } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Topbar from 'components/layout/topbar'
import Navbar from 'components/layout/navbar'
import Main from 'components/layout/main'
import Footer from 'components/layout/footer'
import Login from 'views/login'
const BaseLayout = (): ReactElement => {
  const isLogin = useRouteMatch({ path: '/login', strict: true })
  useEffect(() => {
    document.body.classList.remove('bg-primary')
  }, [])

  if (isLogin) return <Route path='/login' component={Login} />
  return (
    <div id='layout-wrapper'>
      <header id='page-topbar'>
        <Topbar />
        <Navbar />
      </header>
      <Main />
      <Footer />
    </div>
  )
}

export default BaseLayout
