import React, { useEffect, useState, ReactElement } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import 'select2-component/dist/select2.min.css'
import { schema_create, schema_edit } from 'validations/user_schema'
import User from 'models/user'
import Role, { DefaultRoles } from 'models/role'

type UserProps = {
  user: User
  onSubmit: (user: User) => void
  onShow: boolean
  onClose: () => void
}

const UserForm = ({ user, onSubmit, onShow, onClose }: UserProps): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: joiResolver(user.id ? schema_edit : schema_create),
  })

  useEffect(() => {
    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      motherLastName: user.motherLastName,
      email: user.email,
      blocked: user.blocked,
      password: '',
      role: user.role.id,
      major: user.major,
    })
  }, [user])

  const [blocked, setBlocked] = useState(user.blocked)

  const handleChangeBlocked = () => setBlocked(!blocked)

  type FormValues = {
    role: number
    email: string
    password?: string
    firstName: string
    lastName: string
    motherLastName: string
    blocked: boolean
    major: string
  }

  const onFormSubmit = (values: FormValues) => {
    const userRole = new Role({ id: values.role })
    user.role = userRole
    user.email = values.email
    if (values.password) user.password = values.password
    user.firstName = values.firstName
    user.lastName = values.lastName
    user.motherLastName = values.motherLastName
    user.blocked = values.blocked
    user.major = values.major
    onSubmit(user)
  }

  return (
    <Modal size='lg' show={onShow} onHide={onClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{user.id ? 'Editar Usuario' : 'Crear Usuario'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Nombre(s)</Form.Label>
                <Form.Control {...register('firstName')} isInvalid={!!errors.firstName} />
                <Form.Control.Feedback type='invalid'>{errors.firstName?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group className={errors.lastName ? 'has-error' : ''}>
                <Form.Label>Apellido Paterno</Form.Label>
                <Form.Control placeholder='Apellido Paterno' {...register('lastName')} />
                <Form.Text className='text-danger'>{errors.lastName?.message}</Form.Text>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className={errors.motherLastName ? 'has-error' : ''}>
                <Form.Label>Apellido Materno</Form.Label>
                <Form.Control placeholder='Apellido Materno' {...register('motherLastName')} />
                <Form.Text className='text-danger'>{errors.motherLastName?.message}</Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group className={errors.email ? 'has-error' : ''}>
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control type='email' placeholder='Correo Electrónico' {...register('email')} />
                <Form.Text className='text-danger'>{errors.email?.message}</Form.Text>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className={errors.password ? 'has-error' : ''}>
                <Form.Label>Contraseña</Form.Label>
                <Form.Control type='password' {...register('password')} autoComplete='none' />
                <Form.Text className='text-danger'>{errors.password?.message}</Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group className={errors.major ? 'has-error' : ''}>
                <Form.Label>Titutlo</Form.Label>
                <Form.Control placeholder='Titulo' {...register('major')} />
                <Form.Text className='text-danger'>{errors.major?.message}</Form.Text>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Rol de Usuario</Form.Label>
                <Select2 data={DefaultRoles} value={user.role.id} update={value => setValue('role', +value)}></Select2>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Bloquear Usuario</Form.Label>
                <div className='checkbox' onChange={handleChangeBlocked}>
                  <input className='magic-checkbox' type='checkbox' {...register('blocked')} defaultChecked={blocked} />
                  <label htmlFor='blocked_1'>{blocked ? 'Si' : 'No'}</label>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            Cerrar
          </Button>
          <Button as='input' type='submit' value='Guardar' />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

UserForm.propTypes = {
  user: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default UserForm
