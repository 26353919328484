import Api from 'api'
import Work from 'models/work'
const resource = '/works'

export default class WorkService {
  static async getAll(): Promise<Array<Work>> {
    const { data } = await Api.get(resource)
    return data.propertyTypes
  }
}
