import Api from 'api'
import Service from 'models/service'
const resource = '/services'

export default class ServiceService {
  static async getAll(): Promise<Array<Service>> {
    const { services } = (await Api.get(resource)).data
    return services
  }

  static async save(serviceS: Service): Promise<Service> {
    const { service } = (await Api.post(resource, serviceS)).data
    return service
  }

  static async edit(serviceS: Service): Promise<Service> {
    const { service } = (await Api.put(resource, serviceS)).data
    return service
  }

  static async remove(id: number): Promise<string> {
    const { message } = (await Api.get(`${resource}/${id}`)).data
    return message
  }
}
