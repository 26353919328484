import React, { ReactElement, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faSchool, faFileInvoice, faUsers, faListAlt, faList } from '@fortawesome/free-solid-svg-icons'
import { AuthContext, IAuthContext } from 'context/useAuth'

const Navbar = (): ReactElement => {
  const onMenuClick = () => {
    console.log('onMenuClick')
  }

  const { currentUser } = useContext(AuthContext) as IAuthContext

  return (
    <div className='topnav'>
      <div className='container-fluid'>
        <nav className='navbar navbar-light navbar-expand-lg topnav-menu'>
          <div id='topnav-menu-content' className='collapse navbar-collapse'>
            <ul className='navbar-nav'>
              <li className='nav-item dropdown'>
                <Link to='/school_visits' className='nav-link dropdown-toggle arrow-none'>
                  <i className='mr-2'>
                    <FontAwesomeIcon icon={faClipboardList} />
                  </i>
                  Mis Visitas
                </Link>
              </li>
              {currentUser?.isAdmin() && [
                <li className='nav-item' key='Escuelas'>
                  <Link className='nav-link side-nav-link-ref' to='/'>
                    <i className='mr-2'>
                      <FontAwesomeIcon icon={faSchool} />
                    </i>
                    Escuelas
                  </Link>
                </li>,
                <li className='nav-item dropdown' key='Reportes'>
                  <Link className='nav-link dropdown-toggle arrow-none' to='/reports'>
                    <i className='mr-2'>
                      <FontAwesomeIcon icon={faFileInvoice} />
                    </i>
                    Reportes
                  </Link>
                </li>,
                <li className='nav-item dropdown' key='Usuarios'>
                  <Link className='nav-link dropdown-toggle arrow-none' to='/users'>
                    <i className='mr-2'>
                      <FontAwesomeIcon icon={faUsers} />
                    </i>
                    Usuarios
                  </Link>
                </li>,
                <li className='nav-item dropdown' key='Opciones'>
                  <Link className='nav-link dropdown-toggle arrow-none' to='/options'>
                    <i className='mr-2'>
                      <FontAwesomeIcon icon={faListAlt} />
                    </i>
                    Catálogos
                  </Link>
                </li>,
              ]}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
