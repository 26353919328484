import React, { ReactElement } from 'react'

type Props = {
  title?: string
  children: JSX.Element
}
const SimpleCard = ({ children, title }: Props): ReactElement => {
  return (
    <div className='card'>
      {title && (
        <div className='card-header bg-white'>
          <h4 className='card-title'>{title}</h4>
        </div>
      )}
      <div className='card-body'>{children}</div>
    </div>
  )
}

export default SimpleCard
