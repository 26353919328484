import React, { ReactElement, useContext } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { AuthContext, IAuthContext } from 'context/useAuth'

const PrivateRoute = ({ component, ...rest }: RouteProps): ReactElement => {
  const { currentUser } = useContext(AuthContext) as IAuthContext
  return currentUser ? <Route {...rest} component={component} render={undefined} /> : <Redirect to='/login' />
}

export default PrivateRoute
