import React, { useRef, ReactElement } from 'react'
import 'assets/css/dropzone.css'
import SwAlert from 'components/alert/swal'

type DropzoneProps = {
  onLoad: (name: string, base64: string) => void
}
const Dropzone = ({ onLoad }: DropzoneProps): ReactElement => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  //const [selectedFiles, setSelectedFiles] = useState([])

  const validateFile = (file: File) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png']
    const mb = file.size / 10e5
    if (file.type.includes('image') && mb > 10) return false
    if (validTypes.findIndex(item => item === file.type) > -1) return true
    return false
  }

  const preventDefault = (e: React.MouseEvent) => {
    e.preventDefault()
    // e.stopPropagation();
  }

  const dragOver = (e: React.MouseEvent) => {
    preventDefault(e)
  }

  const dragEnter = (e: React.MouseEvent) => {
    preventDefault(e)
  }

  const dragLeave = (e: React.MouseEvent) => {
    preventDefault(e)
  }

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e)
    const files = e.dataTransfer.files
    if (files.length) {
      handleFiles(files)
    }
  }

  const toBase64 = (file: File) => {
    const reader = new FileReader()
    reader.onload = function () {
      if (reader && reader.result && typeof reader.result == 'string') {
        onLoad(file.name, reader.result)
      }
    }
    reader.readAsDataURL(file)
  }

  const handleFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        toBase64(files[i])
      } else {
        SwAlert.fire('Error!', 'archivo no permitido (no es una imagen o supera el limite de 10 mb)', 'error')
      }
    }
  }

  const filesSelected = () => {
    if (fileInputRef.current !== null) {
      const el = fileInputRef.current
      if (el.files && el.files.length) handleFiles(el.files)
    }
  }

  const fileInputClicked = () => {
    if (fileInputRef.current !== null) fileInputRef.current.click()
  }

  return (
    <div
      className='drop-box'
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      onClick={fileInputClicked}
    >
      <p>Arrastre y suelte archivos aquí o haga clic para seleccionar</p>
      <input
        ref={fileInputRef}
        className='file-input'
        type='file'
        multiple
        onChange={filesSelected}
        accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
      />
    </div>
  )
}
export default Dropzone
