import { ITerrainDescriptionProperties } from 'interfaces/terrain_description'
export default class TerrainDescription {
  id
  area
  lat
  lng
  soil_mechanics
  propertyType
  donation_certificate
  notes
  observations

  constructor(properties: ITerrainDescriptionProperties = {}) {
    this.id = properties.id || 0
    this.area = properties.area || 0
    this.lat = properties.lat || ''
    this.lng = properties.lng || ''
    this.soil_mechanics = properties.soil_mechanics || false
    this.propertyType = properties.propertyType || null
    this.donation_certificate = properties.donation_certificate || false
    this.notes = properties.notes || ''
    this.observations = properties.observations || ''
  }
}
