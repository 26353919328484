import React, { useState, useEffect, createContext, ReactElement, ReactNode } from 'react'
//import { signin } from 'services/Auth'
// cimport SecureLS from 'secure-ls'
import User from 'models/user'
import Api from 'api'

//const ls = new SecureLS({ encodingType: 'aes' })

export interface IAuthContext {
  currentUser: User | null
  signin: (email: string, password: string) => void
  signout: () => void
}

interface ProviderProps {
  children: ReactNode
}

export const AuthContext = createContext<IAuthContext | null>(null)

const AuthProvider = ({ children }: ProviderProps): ReactElement => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  useEffect(() => {
    checkUser()
  }, [currentUser])

  const signin = async (email: string, password: string) => {
    const { data } = await Api.post('/auth', { email, password })
    const user = new User(data.user)
    localStorage.setItem('token', JSON.stringify(data.token))
    localStorage.setItem('currentUser', JSON.stringify(user))
    //ls.set('token', JSON.stringify(data.token))
    //ls.set('currentUser', JSON.stringify(user))
    setCurrentUser(user)
  }

  const signout = () => {
    setCurrentUser(null)
    localStorage.removeItem('token')
    localStorage.removeItem('currentUser')
    //ls.removeAll()
  }

  const checkUser = () => {
    let user = null
    //const auth = ls.get('currentUser')
    const auth = localStorage.getItem('currentUser')
    //if (auth !== '') {
    if (auth) {
      user = new User(JSON.parse(auth))
      if (!currentUser) setCurrentUser(user)
      else if (user.id !== currentUser.id) setCurrentUser(user)
    }
  }

  return <AuthContext.Provider value={{ currentUser, signin, signout }}>{children}</AuthContext.Provider>
}

export default AuthProvider
