import React, { useState, ReactNode, ReactElement } from 'react'
import SchoolService from 'services/school_service'
import { ISchoolItem } from 'interfaces/school'

export interface ISchoolContext {
  search: string
  schools: Array<ISchoolItem>
  page: number
  perPage: number
  totalSchools: number
  updateSchools: (searchTag: string, page: number, perPage: number) => void
  clearSearch: () => void
}

type SchoolProps = {
  children: ReactNode
}

export const SchoolContext = React.createContext<ISchoolContext | null>(null)

const SchoolProvider = ({ children }: SchoolProps): ReactElement => {
  const [schools, setSchools] = useState(new Array<ISchoolItem>(0))
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalSchools, setTotalSchools] = useState(0)

  const updateSchools = async (searchTag: string, page: number, perPage: number) => {
    try {
      const { schools, pagination } = await SchoolService.getSchools(page, perPage, searchTag)
      setSchools(schools)
      setTotalSchools(+pagination.total_elements)
      setSearch(searchTag)
      //setSearchFilter(searchFilter)
      setPage(page)
      setPerPage(perPage)
    } catch (e) {
      console.log('error: ', e)
      //if (e.error) notify('error', e.error)
    }
  }

  const clearSearch = () => {
    setSearch('')
    setSchools(new Array<ISchoolItem>(0))
    setPage(1)
    setPerPage(10)
    setTotalSchools(0)
  }

  return (
    <SchoolContext.Provider
      value={{
        search,
        page,
        perPage,
        totalSchools,
        schools,
        updateSchools,
        clearSearch,
      }}
    >
      {children}
    </SchoolContext.Provider>
  )
}

export default SchoolProvider
