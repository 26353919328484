import React, { useState, ReactElement, Fragment } from 'react'
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faEdit, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import SwAlert from 'components/alert/swal'
import SimpleCard from 'components/cards/simple_card'
import InfrastructureItem from 'models/infrastructure'
import { ISchoolVisitProperties } from 'interfaces/school_visit'
import InfrastructureForm from './infrastructure_form'
import CustomTable from 'components/table/custom_table'
type InfraestructureProps = {
  infrastructures: Array<InfrastructureItem>
  edit: boolean
  onUpdate: (data: ISchoolVisitProperties) => void
}
const InfrastructureList = ({ infrastructures, edit, onUpdate }: InfraestructureProps): ReactElement => {
  const [showModal, setShowModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(new InfrastructureItem())
  const [editIndex, setEditIndex] = useState(-1)

  const addOrEdit = (infrastructure: InfrastructureItem) => {
    if (editIndex > -1) {
      infrastructures[editIndex] = infrastructure
      setEditIndex(-1)
    } else {
      infrastructures.push(infrastructure)
    }

    onUpdate({ infrastructures })

    setCurrentItem(new InfrastructureItem())
    setShowModal(false)
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()

  const remove = async (index: number) => {
    await SwAlert.fire({
      title: 'Eliminar item',
      text: '¿Está seguro de eliminar este item?',
      icon: 'warning',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true,
      preConfirm: async () => {
        infrastructures.splice(index, 1)
        setCurrentItem(new InfrastructureItem())
        onUpdate({ infrastructures })
      },
      allowOutsideClick: false,
    })
  }

  const editItem = (index: number) => {
    const current = infrastructures[index]
    if (current) {
      setCurrentItem(current)
      setEditIndex(index)
      setShowModal(true)
    }
  }

  const body = () => {
    return (
      <Fragment>
        {infrastructures.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.building}</td>
              <td>{item.spaces_number}</td>
              <td>{item.education_spaces}</td>
              <td>{item.structure}</td>
              <td>{item.no_e_e}</td>
              <td>{item.muros}</td>
              <td>{item.techumbre}</td>
              <td>{item.canceleria}</td>
              <td>{item.createdBy}</td>
              <td>{item.createdYear}</td>
              <td>{item.observations}</td>
              <td>{item.necesities}</td>
              <td>
                {edit && (
                  <div className='action d-inline-flex'>
                    <OverlayTrigger overlay={<Tooltip id='edit-visit'>Editar</Tooltip>}>
                      <Button variant='outline-success' onClick={() => editItem(index)} onMouseDown={handleMouseDown}>
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id='edit-visit'>Eliminar</Tooltip>}>
                      <Button variant='outline-danger' onClick={() => remove(index)} onMouseDown={handleMouseDown}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </td>
            </tr>
          )
        })}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className='card'>
        <div className='card-header bg-white'>
          <h4 className='card-title'>INFRAESTRUCTURA</h4>
        </div>
      </div>
      <SimpleCard>
        <Fragment>
          {edit && (
            <Row className='mb-3'>
              <Col sm={12}>
                <Button
                  className='btn-icon'
                  onClick={() => {
                    setEditIndex(-1)
                    setCurrentItem(new InfrastructureItem())
                    setShowModal(true)
                  }}
                >
                  <span className='btn-icon-label'>
                    <i className='mr-2'>
                      <FontAwesomeIcon icon={faPlus} />
                    </i>
                  </span>
                  Agregar
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12}>
              <CustomTable
                headers={[
                  'edificio',
                  'No. de Espacios',
                  'Espacios Educativos',
                  'Estructura',
                  'No. E.E',
                  'Muros',
                  'Techumbre',
                  'Cancelería',
                  'Construido por',
                  'Año de Construcción',
                  'Observaciones',
                  'Necesidades',
                  'Acción',
                ]}
                dataBody={body()}
              />
            </Col>
          </Row>
          <InfrastructureForm
            infrastructureItem={currentItem}
            onShow={showModal}
            onSubmit={addOrEdit}
            onClose={() => setShowModal(false)}
          />
        </Fragment>
      </SimpleCard>
    </Fragment>
  )
}

export default InfrastructureList
