import Users from 'views/users'
import Schools from 'views/schools'
import SchoolDetail from 'views/schools/details'
import SchoolVisits from 'views/visits'
import SchoolVisitDetail from 'views/visits/details'
import Reports from 'views/reports'
import NotFound from 'views/not_found'
import Options from 'views/options'
import { DocumentVisit } from 'views/visits/document'

const Routes = [
  {
    path: '/users',
    name: 'Usuarios',
    component: Users,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/schools/:school_id',
    name: 'Detalle de Escuela',
    component: SchoolDetail,
    isPrivate: true,
  },
  {
    path: '/school_visits/:school_visit_id',
    name: 'Detalle de Visita',
    component: SchoolVisitDetail,
    isPrivate: true,
  },
  {
    path: '/school_visits/:school_visit_id/document',
    name: 'Documento de Visita',
    component: DocumentVisit,
    isPrivate: true,
  },
  {
    path: '/school_visits',
    name: 'Mis Visitas',
    component: SchoolVisits,
    isPrivate: true,
  },
  {
    path: '/',
    name: 'Escuelas',
    component: Schools,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/options',
    name: 'Opciones',
    component: Options,
    isPrivate: true,
  },
  {
    path: '/reports',
    name: 'Reportes',
    component: Reports,
    isPrivate: true,
  },
  {
    path: '/404',
    component: NotFound,
    isPrivate: true,
  },
]

export default Routes
