import { ISchoolVisitProperties, ISchoolVisitExport } from 'interfaces/school_visit'
import { IShift } from 'interfaces/shift'
import { IExteriorWork } from 'interfaces/exterior_work'
import { IVisitService } from 'interfaces/visit_service'
import School from 'models/school'
import SchoolService from 'models/school_service'
import TerrainDescription from 'models/terrain_description'
import Infrastructure from 'models/infrastructure'
import SoilType from 'models/soil_type'
import SchoolVisitImage from 'models/school_visit_image'
import CommunicationChannels from './communication_channels'
import User from './user'

export default class SchoolVisit {
  id
  school
  terrainDescription
  schoolShifts
  exteriorWorks
  visitServices
  schoolService
  soilType
  infrastructures
  schoolVisitImages
  user
  status
  createdAt
  updatedAt
  visitedAt
  existingTechnologies
  communicationChannels
  constructor(properties: ISchoolVisitProperties = {}) {
    this.id = properties.id || 0
    this.school = properties.school || new School({})
    this.terrainDescription = properties.terrainDescription || new TerrainDescription({})
    this.schoolService = properties.schoolService || new SchoolService({})
    this.soilType = properties.soilType || new SoilType({})
    this.communicationChannels = properties.communicationChannels || new CommunicationChannels({})
    this.schoolShifts = properties.schoolShifts || new Array<IShift>(0)
    this.exteriorWorks = properties.exteriorWorks || new Array<IExteriorWork>(0)
    this.visitServices = properties.visitServices || new Array<IVisitService>(0)
    this.infrastructures = properties.infrastructures || new Array<Infrastructure>(0)
    this.schoolVisitImages = properties.schoolVisitImages || new Array<SchoolVisitImage>(0)
    this.user = properties.user || new User()
    this.status = properties.status || 0
    this.createdAt = properties.createdAt || new Date()
    this.updatedAt = properties.updatedAt || new Date()
    this.visitedAt = properties.visitedAt || ''
    this.existingTechnologies = properties.existingTechnologies || ''
  }

  asJSON(): ISchoolVisitExport {
    if (!this.id) {
      return { schoolId: this.school.id }
    }
    return {
      school: this.school,
      terrainDescription: this.terrainDescription,
      soilType: this.soilType,
      status: this.status,
      schoolShifts: this.schoolShifts,
      exteriorWorks: this.exteriorWorks,
      schoolService: this.schoolService,
      infrastructures: this.infrastructures,
      schoolVisitImages: this.schoolVisitImages,
      existingTechnologies: this.existingTechnologies,
      communicationChannels: this.communicationChannels,
    }
  }
}

export const SchoolVisitStatus = [
  { value: 0, label: 'Por visitar', color: 'info' },
  { value: 1, label: 'Pendiente', color: 'primary' },
  { value: 2, label: 'Confirmada', color: 'success' },
]

export const SchoolVisitHeaders = [
  'Clave',
  'Nombre',
  'Localidad',
  'Municipio',
  'Distrito',
  'Región',
  'Nivel',
  'Capturista',
  'status',
  'Acciones',
]
