import React, { useState, ReactElement, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import 'select2-component/dist/select2.min.css'
import UserService from 'services/user_service'
import { Select2Option } from 'select2-component/dist/index'
import { ISchoolVisitItem } from 'interfaces/school_visit'

type ReAsignFormProprs = {
  onSubmit: (schoolVisitId: number, userId: number) => void
  onShow: boolean
  onClose: () => void
  schoolVisit: ISchoolVisitItem | null
}

const ReAsignForm = ({ onSubmit, onShow, onClose, schoolVisit }: ReAsignFormProprs): ReactElement => {
  const [userId, setUser] = useState(0)
  const [error, setError] = useState(false)
  const [users, setUsers] = useState(new Array<Select2Option>())

  const handleSubmit = () => {
    if (!userId) return setError(true)
    if (schoolVisit) onSubmit(schoolVisit.id, userId)
  }

  const getUsers = async () => {
    try {
      const { users } = await UserService.getUsers(1, 10000, '')
      const data: Array<Select2Option> = []
      users.forEach(user => {
        if (user.id) {
          data.push({
            value: user.id,
            label: `${user.firstName} ${user.lastName} ${user.motherLastName} ${user.email}`,
          })
        }
      })
      setUsers(data)
    } catch (e) {
      console.log('error in search users', e)
    }
  }

  useEffect(() => {
    if (!onShow) {
      setUser(0)
      setError(false)
    }
  }, [onShow])

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <Modal size='lg' show={onShow} onHide={onClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Reasignar la visita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            {schoolVisit && <div className='mb-2'>{`${schoolVisit.description} (${schoolVisit.clave})`}</div>}
          </Col>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Buscar usuario por nombre</Form.Label>
              <Select2 data={users} value={userId} update={value => setUser(+value)}></Select2>
              <Form.Text className='text-danger'>{error ? 'Debe seleccionar un usuario.' : ''}</Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cerrar
        </Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </Modal.Footer>
    </Modal>
  )
}

ReAsignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ReAsignForm
