import React, { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'

type SearchProps = {
  defaultSearch: string
  onSubmit: (tag: string) => void
}

type FormValues = {
  tag: string
}

const Search = ({ onSubmit, defaultSearch }: SearchProps): ReactElement => {
  const { register, handleSubmit, setValue } = useForm()
  const onFormSubmit = (values: FormValues) => {
    if (values.tag.trim().length) {
      onSubmit(values.tag)
    }
  }

  const handleOnBlur = (tag: string) => {
    //if (tag.trim().length === 0) onSubmit(tag)
    //console.log(tag)
  }

  const handleClear = () => {
    setValue('tag', '')
    onSubmit('')
  }

  return (
    <Row>
      {defaultSearch !== '' && (
        <Col xs='auto'>
          <button onClick={handleClear} className='btn btn-outline-danger'>
            <i>
              <FontAwesomeIcon icon={faTimes} />
            </i>
          </button>
        </Col>
      )}
      <Col>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className='form-group'>
            <div className='input-group'>
              <input
                type='text'
                placeholder='Buscar...'
                className='form-control'
                autoComplete='off'
                {...register('tag')}
                defaultValue={defaultSearch}
                onBlur={e => handleOnBlur(e.target.value)}
              />
              <div className='input-group-append'>
                <button className='btn btn-outline-info'>
                  <i>
                    <FontAwesomeIcon icon={faSearch} />
                  </i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Col>
    </Row>
  )
}

export default Search
