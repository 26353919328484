import React, { useState, useEffect, ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'
const pageNeighbours = 2

interface PaginationProps {
  totalRecords: number
  pageLimit: number
  currentPage: number
  changePage: (page: number) => void
  changePageLimit: (limit: number) => void
}

const getRange = (from: number, to: number, step = 1) => {
  let i = from
  const range = []
  while (i <= to) {
    range.push(i)
    i += step
  }
  return range
}

const Pagination = ({
  totalRecords,
  pageLimit,
  currentPage,
  changePage,
  changePageLimit,
}: PaginationProps): ReactElement => {
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    const pages = Math.ceil(totalRecords / pageLimit)
    setTotalPages(pages)
  }, [totalRecords, pageLimit])

  const gotoPage = (page: number) => {
    const newCurrentPage = Math.max(0, Math.min(page, totalPages))
    changePage(newCurrentPage)
  }

  const handleClick = (page: number) => (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    gotoPage(page)
  }

  const handleMoveLeft = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    const chn = pageNeighbours * 2 - 1
    gotoPage(currentPage - chn)
  }

  const handleMoveRight = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    gotoPage(currentPage + pageNeighbours * 2 + 1)
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }

  const getAllPages = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
      let pages: Array<number | string> = getRange(startPage, endPage)
      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)
      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = getRange(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }
        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = getRange(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }
        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }
      return [1, ...pages, totalPages]
    }
    return getRange(1, totalPages)
  }

  if (!totalRecords || totalPages === 1) return <div></div>

  const pages = getAllPages()

  return (
    <div className='row'>
      <div className='col-sm-6 text-right'>
        <Dropdown>
          <span className='text-muted'>Registros por Pág.</span>
          <Dropdown.Toggle variant='default' className='btn-outline-primary' id='dropdown-basic'>
            {pageLimit}
          </Dropdown.Toggle>
          <Dropdown.Menu as='ul'>
            {[10, 25, 50, 100].map((limit, key) => {
              return (
                <li key={key}>
                  <Dropdown.Item href='#' onClick={() => changePageLimit(limit)}>
                    {limit}
                  </Dropdown.Item>
                </li>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='col-sm-6'>
        <ul className='pagination mar-no'>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className='page-item'>
                  <a
                    className='page-link'
                    href='#'
                    aria-label='Previous'
                    onClick={handleMoveLeft}
                    onMouseDown={handleMouseDown}
                  >
                    <span aria-hidden='true'>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                    <span className='sr-only'>Previous</span>
                  </a>
                </li>
              )

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className='page-item'>
                  <a
                    className='page-link'
                    href='#'
                    aria-label='Next'
                    onClick={handleMoveRight}
                    onMouseDown={handleMouseDown}
                  >
                    <span aria-hidden='true'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                    <span className='sr-only'>Next</span>
                  </a>
                </li>
              )

            return (
              <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                <a className='page-link' href='#' onClick={handleClick(+page)} onMouseDown={handleMouseDown}>
                  {page}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Pagination
