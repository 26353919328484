import { IRegion } from 'interfaces/region'

export default class Region {
  id
  description
  constructor(properties: IRegion = {}) {
    this.id = properties.id || 0
    this.description = properties.description || ''
  }
}
