import React, { useState, ReactElement, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import 'select2-component/dist/select2.min.css'
import SchoolService from 'services/school_service'
import { AuthContext, IAuthContext } from 'context/useAuth'
import { Select2Option } from 'select2-component/dist/index'

type SchoolVisitProps = {
  onSubmit: (schoolId: number, userId: number) => void
  onShow: boolean
  onClose: () => void
  userIdP: number
  usersP: Array<Select2Option>
}

type SelectData = {
  value: number
  label: string
}

const SchoolVisitForm = ({ onSubmit, onShow, onClose, userIdP, usersP }: SchoolVisitProps): ReactElement => {
  const [schoolId, setSchool] = useState(0)
  const [userId, setUser] = useState(userIdP)
  const [error, setError] = useState(false)
  const [schools, setSchools] = useState(new Array<SelectData>(0))
  const [users, setUsers] = useState(usersP)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const { currentUser } = useContext(AuthContext) as IAuthContext

  useEffect(() => {
    const usersPClone = [...usersP]
    usersPClone.shift()
    setUsers(usersPClone)
  }, [usersP])

  useEffect(() => {
    setUser(userIdP)
  }, [userIdP])

  const handleSubmit = () => {
    if (currentUser) {
      const userIdStatus = currentUser.isAdmin() ? !userId : false
      if (!schoolId || userIdStatus) return setError(true)
      const userIdD = currentUser.isAdmin() ? userId : currentUser.id
      onSubmit(schoolId, userIdD)
    }
  }

  const doSearch = async (text: string) => {
    try {
      if (timer) clearTimeout(timer)
      setTimer(
        setTimeout(async () => {
          const { schools } = await SchoolService.getSchools(1, 30, text)
          const data: Array<SelectData> = []
          schools.forEach(school => {
            if (school.id && school.description && school.clave) {
              data.push({
                value: school.id,
                label: `${school.description}(${school.clave})(${school.municipality})`,
              })
            }
          })
          setSchools(data)
        }, 450),
      )
    } catch (e) {
      console.log('error in search school')
    }
  }

  useEffect(() => {
    if (!onShow) {
      setSchool(0)
      setSchools(new Array<SelectData>(0))
      setError(false)
      if (!userIdP) setUser(0)
    }
  }, [onShow])

  return (
    <Modal size='lg' show={onShow} onHide={onClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Visita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Buscar Escuela por Nombre o clave</Form.Label>
              <Select2
                data={schools}
                customSearchEnabled={true}
                search={text => doSearch(text)}
                value={schoolId}
                update={value => setSchool(+value)}
              ></Select2>
              <Form.Text className='text-danger'>{error ? 'Debe seleccionar una escuela.' : ''}</Form.Text>
            </Form.Group>
          </Col>
        </Row>
        {currentUser?.isAdmin() && (
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Buscar usuario por nombre</Form.Label>
                <Select2 data={users} value={userId} update={value => setUser(+value)}></Select2>
                <Form.Text className='text-danger'>{error ? 'Debe seleccionar un usuario.' : ''}</Form.Text>
              </Form.Group>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cerrar
        </Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </Modal.Footer>
    </Modal>
  )
}

SchoolVisitForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SchoolVisitForm
