import Api from 'api'
import Municipality from 'models/municipality'
const resource = '/municipality'

export default class MunicipalityService {
  static async getAll(): Promise<Array<Municipality>> {
    const { data } = await Api.get(resource)
    return data.propertyTypes
  }
}
