import React, { useContext, useEffect, ReactElement, Fragment } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { AuthContext, IAuthContext } from 'context/useAuth'
import logo_dark from 'assets/images/logo-dark.png'
import SwAlert from 'components/alert/swal'

interface formValues {
  email: string
  password: string
}

const Login = (): ReactElement => {
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { currentUser, signin } = useContext(AuthContext) as IAuthContext

  useEffect(() => {
    document.body.classList.add('bg-primary')
  }, [])

  const onFormSubmit = async (values: formValues) => {
    try {
      await signin(values.email, values.password)
      history.push('/school_visits')
    } catch (e) {
      console.log('login: ', e)
      SwAlert.fire('Error!', e.error, 'error')
    }
  }

  if (currentUser) return <Redirect to='/school_visits' />

  return (
    <Fragment>
      <div className='home-btn d-none d-sm-block'>
        <a href='/' className='text-white'>
          <i className='fas fa-home h2'></i>
        </a>
      </div>
      <div className='account-pages my-5 pt-5'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8 col-lg-6 col-xl-5'>
              <div className='card bg-pattern shadow-none'>
                <div className='card-body'>
                  <div className='text-center mt-4'>
                    <div className='mb-3'>
                      <h1>IEEPO</h1>
                      <h5>Sistema de visitas</h5>
                    </div>
                  </div>
                  <div className='p-3'>
                    <h4 className='font-size-18 mt-2 text-center'>Bienvenido!</h4>
                    <p className='text-muted text-center mb-4'>Ingresa tus credenciales para continuar.</p>
                    <form className='form-horizontal' onSubmit={handleSubmit(onFormSubmit)}>
                      <div className='form-group'>
                        <label htmlFor='username'>Correo Electrónico</label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Ingrese su Correo Electrónico'
                          autoComplete='off'
                          {...register('email')}
                        />
                        {errors.email && <span>errors.email.message</span>}
                      </div>
                      <div className='form-group'>
                        <label htmlFor='userpassword'>Contraseña</label>
                        <input
                          type='password'
                          className='form-control'
                          placeholder='Ingrese su Contraseña'
                          autoComplete='off'
                          {...register('password')}
                        />
                        {errors.password && <span>errors.password.message</span>}
                      </div>

                      <div className='custom-control custom-checkbox'>
                        <input type='checkbox' className='custom-control-input' id='customControlInline' />
                        <label className='custom-control-label' htmlFor='customControlInline'>
                          Remember me
                        </label>
                      </div>

                      <div className='mt-3'>
                        <button className='btn btn-primary btn-block' type='submit'>
                          Ingresar
                        </button>
                      </div>

                      <div className='mt-4 text-center'>
                        <a href='/pages/recoverpwd-1'>
                          <i className='mdi mdi-lock'></i> Forgot your password?
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='mt-5 text-center text-white-50'>
                <p>
                  © {new Date().getFullYear()} IEEPO. Desarrollado
                  <i className='mdi mdi-heart text-danger'></i> por Karimnot Inc
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Login
