import { IDistrict } from 'interfaces/district'

export default class District {
  id
  description
  constructor(properties: IDistrict = {}) {
    this.id = properties.id || 0
    this.description = properties.description || ''
  }
}
