import { ICommunicationChannels } from 'interfaces/communication_channels'

export default class CommunicationChannels {
  noMonthsCommunication?: number
  pavement?: string
  breach?: string
  dirtRoad?: string
  bridlePath?: string
  constructor(properties: ICommunicationChannels = {}) {
    this.noMonthsCommunication = properties.noMonthsCommunication || 0
    this.pavement = properties.pavement || ''
    this.breach = properties.breach || ''
    this.dirtRoad = properties.dirtRoad || ''
    this.bridlePath = properties.bridlePath || ''
  }
}
