import Api from 'api'
import StringOption from '../models/string_option'
const resource = '/options'

export default class OptionsService {
  static async getAll(): Promise<Array<StringOption>> {
    const { propertyTypes } = (await Api.get(`${resource}/`)).data
    return propertyTypes
  }

  static async muros(): Promise<Array<string>> {
    const { data } = await Api.get(`${resource}/muros`)
    const options = new Array<string>()
    for (const option of data) options.push(option.option)
    return options
  }

  static async techumbre(): Promise<Array<string>> {
    const { data } = await Api.get(`${resource}/techumbre`)
    const options = new Array<string>()
    for (const option of data) options.push(option.option)
    return options
  }

  static async canceleria(): Promise<Array<string>> {
    const { data } = await Api.get(`${resource}/canceleria`)
    const options = new Array<string>()
    for (const option of data) options.push(option.option)
    return options
  }

  static async educationSpaces(): Promise<Array<string>> {
    const { data } = await Api.get(`${resource}/education_spaces`)
    const options = new Array<string>()
    for (const option of data) options.push(option.option)
    return options
  }

  static async technologies(): Promise<Array<string>> {
    const { data } = await Api.get(`${resource}/technologies`)
    const options = new Array<string>()
    for (const option of data) options.push(option.option)
    return options
  }

  static async save(option: StringOption): Promise<StringOption> {
    const { stringOption } = (await Api.post(`${resource}`, option)).data
    return stringOption
  }

  static async delete(optionId: number): Promise<string> {
    const { data } = await Api.delete(`${resource}/${optionId}`)
    return data.message
  }
}
