import React, { useContext, ReactElement } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import logo_sm from 'assets/images/logo-sm.png'
import logo_dark from 'assets/images/logo-dark.png'
import logo_light from 'assets/images/logo-light.png'
import avatar from 'assets/images/avatar-1.jpg'

import { AuthContext, IAuthContext } from 'context/useAuth'

const Topbar = (): ReactElement => {
  const { signout } = useContext(AuthContext) as IAuthContext
  const toggleMenu = () => {
    const element = document.getElementById('topnav-menu-content')
    if (element) element.classList.toggle('show')
  }

  const logout = () => {
    signout()
  }
  return (
    <div className='navbar-header'>
      <div className='d-flex'>
        <button type='button' className='btn btn-sm mr-2 font-size-24 d-lg-none header-item' onClick={toggleMenu}>
          <i>
            <FontAwesomeIcon icon={faBars} />
          </i>
        </button>
        <div className='navbar-brand-box'>
          <a href='/' className='logo logo-dark'>
            <span className='logo-sm'>
              <h3 className='text-light'>IEEPO</h3>
            </span>
            <span className='logo-lg'>
              <h3 className='text-light'>IEEPO</h3>
            </span>
          </a>

          <a href='/' className='logo logo-light'>
            <span className='logo-sm'>
              <h3 className='text-light'>IEEPO</h3>
            </span>
            <span className='logo-lg'>
              <h3 className='text-light'>IEEPO</h3>
            </span>
          </a>
        </div>
      </div>
      <div className='d-flex'>
        <Dropdown className='d-inline-block ml-2'>
          <Dropdown.Toggle variant='white' className='header-item'>
            <img className='rounded-circle header-profile-user' src={avatar} alt='Header Avatar' />
          </Dropdown.Toggle>
          <Dropdown.Menu align='right'>
            <Dropdown.Divider />
            <Dropdown.Item className='text-danger' onClick={logout}>
              <i>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </i>
              Cerrar Sesión
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default Topbar
