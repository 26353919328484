import React, { useState, useEffect, ReactElement, useCallback } from 'react'
import { Row, Col, Card, Button, OverlayTrigger, Tooltip, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faTrash, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'components/dropzone/dropzone'
import SimpleCard from 'components/cards/simple_card'
import SchoolVisitImage from 'models/school_visit_image'
import { ISchoolVisitProperties } from 'interfaces/school_visit'
import { Form } from 'react-bootstrap'
import ImagesService from 'services/image_service'

import { render } from 'react-dom'
import ImageViewer from 'react-simple-image-viewer'
import SwAlert from 'components/alert/swal'

interface MapImagesProps {
  schoolVisitImages: Array<SchoolVisitImage>
  edit: boolean
  onUpdate: (data: Array<SchoolVisitImage>) => void
  schoolVisitId: number
}
const MapImages = ({ schoolVisitImages, edit, onUpdate, schoolVisitId }: MapImagesProps): ReactElement => {
  const [images, setImages] = useState(new Array<SchoolVisitImage>(0))
  const [indexDescription, setIndexDescription] = useState(-1)
  const [macrolocation, setMacrolocation] = useState(false)
  const [microlocation, setMicrolocation] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  useEffect(() => {
    const planeImages = schoolVisitImages.filter((image: SchoolVisitImage) => image.image_type == 0)
    planeImages.sort((a, b) => a.id - b.id)
    setImages(planeImages)
  }, [schoolVisitImages])

  const handleLoad = async (fileName: string, base64: string) => {
    const image = new SchoolVisitImage({
      image_type: 0,
      image_url: base64,
      description: '',
      isMacrolocation: macrolocation,
      isMicrolocation: microlocation,
    })

    try {
      const imageNew = await ImagesService.create({
        ...image,
        lat: '',
        lng: '',
        index: schoolVisitImages.length,
        schoolVisitId,
      })

      setImages([...images, imageNew])
      onUpdate([...schoolVisitImages, imageNew])
      SwAlert.fire('Operación exitosa!', 'Imagen agregada', 'success')
    } catch (e) {
      console.log(e)
    }
  }

  const handleDelete = async (index: number) => {
    const image = images[index]

    await ImagesService.remove(image.id)

    SwAlert.fire('Operación exitosa!', 'Imagen eliminada', 'success')
    const tmp = [...images]
    tmp.splice(index, 1)

    setImages(tmp)

    const indexImages = schoolVisitImages.findIndex(el => el.id == image.id)
    const tmpSchoolVisitImages = [...schoolVisitImages]
    tmpSchoolVisitImages.splice(indexImages, 1)

    onUpdate(tmpSchoolVisitImages)
  }

  const handleOnBlur = async (index: number, tag: string) => {
    images[index].description = tag

    await ImagesService.update(images[index].id, {
      description: images[index].description,
    })

    SwAlert.fire('Operación exitosa!', 'Imagen actualizada', 'success')

    setIndexDescription(-1)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const openImageViewer = useCallback(index => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  return (
    <>
      <div className='card'>
        <div className='card-header bg-white'>
          <h4 className='card-title'>Planos</h4>
        </div>
      </div>
      <Row>
        {edit && (
          <Col sm={12}>
            <SimpleCard>
              <div>
                <Dropzone onLoad={handleLoad} />
                <Form.Group>
                  <Container className='mt-3'>
                    <Row className='text-center'>
                      <Col sm='6'>
                        <Form.Check
                          type='switch'
                          id='macrolocation'
                          label='Macrolocalizacion'
                          checked={macrolocation}
                          defaultChecked={macrolocation}
                          onChange={() => {
                            if (microlocation && !macrolocation) setMicrolocation(false)
                            setMacrolocation(!macrolocation)
                          }}
                        />
                      </Col>
                      <Col sm='6'>
                        <Form.Check
                          type='switch'
                          id='microlocation'
                          label='Microlocalizacion'
                          checked={microlocation}
                          defaultChecked={microlocation}
                          onChange={() => {
                            if (macrolocation && !microlocation) setMacrolocation(false)
                            setMicrolocation(!microlocation)
                          }}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Form.Group>
              </div>
            </SimpleCard>
          </Col>
        )}
        <Col sm={12}>
          <SimpleCard title='Imágenes'>
            <Row>
              {images.map((image, key) => {
                return (
                  <Col key={key} sm={3}>
                    <Card body={false}>
                      <div className='tab-wid'>
                        <Card.Img
                          variant='top'
                          className='img-fluid'
                          src={ImagesService.getUrl(image.image_url)}
                          style={{ cursor: 'pointer' }}
                          onClick={() => openImageViewer(key)}
                        />
                      </div>
                      <Card.Footer>
                        <h4 className='card-title'>
                          Descripción
                          {edit && (
                            <div className='float-right'>
                              {indexDescription === -1 && (
                                <>
                                  <Button variant='outline-info' size='sm' onClick={() => setIndexDescription(key)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                  <Button
                                    variant='danger'
                                    size='sm'
                                    onClick={() => handleDelete(key)}
                                    className={'ml-2'}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        </h4>
                        {indexDescription === key ? (
                          <div className='input-group'>
                            <input
                              type='text'
                              className='form-control'
                              defaultValue={image.description}
                              onBlur={e => handleOnBlur(key, e.target.value)}
                            />
                            <div className='input-group-append'>
                              <button className='btn btn-outline-success'>
                                <i>
                                  <FontAwesomeIcon icon={faCheck} />
                                </i>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <p className='card-text'>{image.description}</p>
                            {image.isMicrolocation ? <p className='card-text'>Microlocalizacion</p> : <p></p>}
                            {image.isMacrolocation ? <p className='card-text'>Macrolocalizacion</p> : <p></p>}
                          </div>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </SimpleCard>
        </Col>
      </Row>
      {isViewerOpen && (
        <ImageViewer
          src={images.map(img => ImagesService.getUrl(img.image_url))}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  )
}

export default MapImages
